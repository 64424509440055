import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Image from "../../assets/img/defaultAvatar.png";
import Banner from "../../assets/img/Banner.png";
import { IoMdArrowBack } from "react-icons/io";
import DoctorNavbar from "./DoctorNavbar";
import DoctorSide from "./DoctorSide";
import { fetchCompany, fetchProducts } from "./DoctorFirebase";

const CompanyViewProfile = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("about");
  const [company, setCompany] = useState(null);
  const [products, setProducts] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation(); 

  const companyId = location.state?.companyId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyData = await fetchCompany(companyId);
        setCompany(companyData);

        const productsList = await fetchProducts(companyId);
        setProducts(productsList);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, [companyId]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="flex flex-col h-screen">
      <DoctorNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <DoctorSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-all duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          {company && (
            <div className="container px-4 mx-auto my-10">
              <div className="flex items-center justify-between">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
                >
                  <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
                </button>
              </div>
              <div className="overflow-hidden">
                <div
                  id="comprofile"
                  className="grid grid-cols-1 md:grid-cols-3 gap-6 px-4"
                >
                  <div className="col-span-1 md:col-span-2 mt-5">
                    <div
                      className="overflow-hidden p-5"
                      style={{
                        backgroundImage: `url(${Banner})`,
                        // height: "250px",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                        <div className="flex">
                          <div>
                            <div className="flex-shrink w-32 h-32 rounded">
                              {company.image ? (
                                <img
                                  src={company.image}
                                  alt={`Profile of ${company.name}`}
                                  className="w-full h-full"
                                />
                              ) : (
                                <div className="flex-shrink w-16 h-16 rounded">
                                  {/* Placeholder image */}
                                  <img
                                    src={Image}
                                    className="w-full h-full "
                                    alt="User Profile"
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="px-4 md:flex-grow my-auto">
                            <h1 className="text-xl font-semibold text-gray-800 ">
                              {company.companyName}
                            </h1>
                            <p className="text-md text-gray-500">
                              {company.location}
                            </p>
                          </div>
                        </div>

                      {/* <div className="flex items-center">
                        <div className="p-6">
                          <div className="mb-4">
                            {company.image ? (
                              <img
                                src={company.image}
                                alt={`Profile of ${company.name}`}
                                className=""
                                style={{ width: "50%" }}
                              />
                            ) : (
                              <img
                                src={Image}
                                alt="Placeholder"
                                className=""
                                style={{ width: "20%" }}
                              />
                            )}
                          </div>
                          <div>
                            <div className="flex space-x-4">
                              <h1 className="text-xl text-gray-800 font-bold">
                                {company.companyName}
                              </h1>
                            </div>
                            <p className="text-lg text-gray-600">
                              {company.location}
                            </p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-span-1 md:col-span-1">
                    <div className="bg-white border rounded-lg overflow-hidden shadow-lg">
                      <div className="">
                        <div className="mt-5">
                          <div
                            id="companydetails"
                            className="p-6 md:p-2 md:h-auto"
                          >
                            <div className="flex items-center justify-between mb-5">
                              <p className="text-sm ">Categories:</p>
                              <p className="text-sm font-semibold">
                                {company.category}
                              </p>
                            </div>
                            <hr className="mb-3 border-gray-300"></hr>
                            <div className="flex items-center justify-between mb-5">
                              <span className="text-sm">Email:</span>
                              <span className="text-sm font-semibold">
                                {company.email}
                              </span>
                            </div>
                            <hr className="mb-3 border-gray-300"></hr>
                            <div className="flex items-center justify-between mb-5">
                              <p className="text-sm">Phone: </p>
                              <p className="text-sm font-semibold capitalize">
                                {company.phone}
                              </p>
                            </div>
                            <hr className="mb-3 border-gray-300"></hr>
                            <div className="flex items-center justify-between mb-5">
                              <p className="text-sm">Location: </p>
                              <p className="text-sm font-semibold capitalize">
                                {company.headquarter}
                              </p>
                            </div>
                            <hr className="mb-3 border-gray-300"></hr>
                            {/* <hr className="mb-3 border-gray-300"></hr> */}
                            {/* <div className="flex items-center justify-center">
                              <button className="flex gap-1.5 justify-center items-center px-6 py-2 mt-5 text-base font-bold text-center text-white uppercase bg-indigo-800 tracking-[2px] max-md:mt-5">
                                Contact
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <div className="flex" id="combutton">
                    <div className="">
                      <h1
                        className={`text-xl px-1 font-semibold cursor-pointer ${
                          activeTab === "about"
                            ? "bg-[#8697C4] text-white"
                            : "bg-gray-200 text-black"
                        }`}
                        onClick={() => setActiveTab("about")}
                      >
                        About Company
                      </h1>
                      <div className="absolute right-[-10px] top-0 h-full border-r-2 border-gray"></div>
                    </div>

                    <div className=" ml-5">
                      <h1
                        className={`text-xl px-1 font-semibold cursor-pointer ${
                          activeTab === "product"
                            ? "bg-[#8697C4] text-white"
                            : "bg-gray-200 text-black"
                        }`}
                        onClick={() => setActiveTab("product")}
                      >
                        Product
                      </h1>
                    </div>
                  </div>

                  <div className="mt-5">
                    {activeTab === "about" && (
                      <div className="flex flex-col md:flex-row justify-between px-4 bg-white shadow-lg border p-3">
                        <p className="p-4 mb-5">{company.about}</p>
                      </div>
                    )}
                    {activeTab === "product" && (
                      <div className="overflow-auto shadow-md sm:rounded-lg mt-3 table-container">
                        <table className="divide-y border divide-gray-300 w-full text-left rtl:text-right">
                          <thead className="text-sm text-gray-700 uppercase ">
                            <tr>
                              <th
                                scope="col"
                                className="px-2 py-3 tracking-wider bg-gray-50"
                              >
                                S.N.
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 tracking-wider"
                              >
                                Product Name
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 tracking-wider bg-gray-50"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.length === 0 ? (
                              <tr className="bg-white border-b dark:border-gray-200">
                                <td colSpan="3" className="text-center py-4">
                                  <p className="text-lg">No Products.</p>
                                </td>
                              </tr>
                            ) : (
                              products.map((product, index) => (
                                <tr
                                  key={index}
                                  className="bg-white border-b dark:border-gray-200"
                                >
                                  <td
                                    scope="row"
                                    className="px-2 py-3 bg-gray-50 text-center font-medium"
                                  >
                                    {index + 1}.
                                  </td>
                                  <td className="px-4 py-3 font-medium">
                                    {product.productName}
                                  </td>
                                  <td className="px-4 py-3 bg-gray-50">
                                    <button
                                      type="button"
                                      className="text-white bg-[#0D47A1] rounded-lg px-3 py-2 text-center me-2 mb-2"
                                    >
                                      About Product
                                    </button>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyViewProfile;
