import React, { useState, useEffect } from "react";
import MrSide from "./MrSide";
import MrNavbar from "./MrNavbar";
import defaultAvatar from "../../assets/img/defaultAvatar.png";
import Banner from "../../assets/img/Banner.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Calendar from "react-calendar";
import Swal from "sweetalert2";
import "../style/Calendar.css";
import "../style/Company.css";
import { IoMdArrowBack } from "react-icons/io";
import { FaClock, FaCalendarAlt } from "react-icons/fa";
import {
  fetchMrDoctorProfile,
  bookMrMeeting,
  fetchUserCompanyId,
  sendMrMessages,
  checkIfMeetingExists,
  checkConflictMeetings,
} from "./MrFirebase";

const MrViewDoctor = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [doctor, setDoctor] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [showMessaging, setShowMessaging] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [meetingType, setMeetingType] = useState("virtual");
  const [companyId, setCompanyId] = useState(null);
  const [calendarTab, setCalendarTab] = useState("date");
  const [conflictDates, setConflictDates] = useState({});

  const times = [
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
  ];

  useEffect(() => {
    const fetchDoctorProfile = async () => {
      try {
        const DoctorData = await fetchMrDoctorProfile(location.state.doctorId);
        setDoctor(DoctorData);
      } catch (error) {
        console.error("Error fetching doctor profile: ", error.message);
      }
    };

    const fetchCompany = async () => {
      const companyId = await fetchUserCompanyId(id);
      setCompanyId(companyId);
    };

    if (doctor) {
      const fetchConflicts = async () => {
        const conflicts = await checkConflictMeetings(doctor.id, companyId);
        setConflictDates(conflicts);
      };
      fetchConflicts();
    }

    fetchDoctorProfile();
    fetchCompany();
  }, [location.state.doctorId, id, doctor, companyId]);

  const toggleCalendar = async (doctor) => {
    const meeting = await checkIfMeetingExists(doctor.id, companyId, id);

    if (meeting && meeting.length > 0) {
      const existingMeeting = meeting[0];
      Swal.fire({
        title: "Meeting Exists",
        text: `A meeting is already scheduled for ${doctor.name}. Do you want to Reschedule a meeting?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          setSelectedDate(new Date(existingMeeting.date));
          setSelectedTime(existingMeeting.time);
          setMeetingType(existingMeeting.meetingType);
          setCalendarTab("date");
          setShowCalendar(!showCalendar);
        }
      });
    } else {
      setSelectedDate(new Date());
      setSelectedTime("");
      setMeetingType("virtual");
      setCalendarTab("date");
      setShowCalendar(!showCalendar);
    }
  };

  const toggleMessaging = () => {
    setShowMessaging(!showMessaging);
  };

  const handleBookSchedule = async () => {
    const adjustedDate = new Date(selectedDate);
    const ISTOffset = 330;
    adjustedDate.setMinutes(adjustedDate.getMinutes() + ISTOffset);
    const formattedDate = adjustedDate.toISOString().split("T")[0];
    const selectedDateTime = new Date(`${formattedDate} ${selectedTime}`);

    if (selectedDateTime < new Date()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You cannot schedule a meeting for a past date and time!",
      });
      return;
    }

    try {
       await bookMrMeeting({
        doctorId: location.state.doctorId,
        companyId: companyId,
        selectedTime: selectedTime,
        formattedDate: formattedDate,
        id: id,
        doctorName: doctor ? doctor.name : "",
        meetingType: meetingType,
      });

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your scheduled meeting sent to the doctor successfully!",
        showConfirmButton: false,
        timer: 2000,
      });

      navigate(`/mr/meetingsinfo/${id}`);
    } catch (error) {
      console.error("Error scheduling meeting:", error);
      alert("Failed to schedule meeting. Please try again.");
    }
  };

  const handleSendMessage = async () => {
    if (!doctor) {
      return;
    }

    try {
      await sendMrMessages({
        doctorId: location.state.doctorId,
        companyId: companyId,
        message: message,
        id: id,
      });

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Message sent successfully!",
        showConfirmButton: false,
        timer: 2000,
      });
      navigate(`/mr/message/${id}`);
    } catch (error) {
      console.error("Error sending messages:", error);
      alert("Failed to send message. Please try again.");
    }
  };

  const onChange = (date) => {
    setSelectedDate(date);
    if (calendarTab === "date") {
      setCalendarTab("time");
    }
  };

  const formatDateToISO = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const isTimeConflicting = (time) => {
    const dateStr = formatDateToISO(selectedDate);
    return conflictDates[dateStr] && conflictDates[dateStr].includes(time);
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      // Normalize the date to avoid time issues
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const dateStr = formatDateToISO(date);

      if (date < today) {
        console.log(date);
        return "react-calendar__tile--passed";
      }

      if (conflictDates[dateStr]) {
        return "react-calendar__tile--conflict";
      }
    }
    return null;
  };

  return (
    <div className="flex flex-col h-screen">
      <MrNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <MrSide
          open={sidebarOpen}
          toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
        />
        <div
          className={`overflow-y-auto flex-1 transition-all duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          <div className="container px-4 mx-auto overflow-auto my-5">
            <div className="flex items-center justify-between">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
              >
                <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
              </button>
              <h2 className="flex-grow text-2xl font-bold text-center">
                Doctor Profile
              </h2>
            </div>

            <div
              id="doctorprofile"
              className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5 px-4"
            >
              <div className="col-span-1 md:col-span-2 mt-5">
                <div
                  id="docprofile"
                  className="overflow-hidden"
                  style={{
                    backgroundImage: `url(${Banner})`,
                    // height: "250px",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="flex flex-col md:flex-row md:p-6 items-center">
                    <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-6 rounded-full">
                      <div
                        id="docimage"
                        className="w-32 h-32 md:w-32 md:h-32 bg-gray-200 rounded-full flex items-center justify-center"
                      >
                        {doctor && doctor.image ? (
                          <img
                            src={doctor.image}
                            alt={`Profile of ${doctor.name}`}
                            className="w-32 h-32 md:w-32 md:h-32 rounded-full"
                          />
                        ) : (
                          <div className="w-32 h-32 md:w-32 md:h-32 bg-gray-200 rounded-full flex items-center justify-center">
                            <img src={defaultAvatar} alt="Placeholder" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="docdetails" className="px-6 md:flex-grow">
                      <h1
                        id="docname"
                        className="text-xl font-semibold text-gray-800 mb-2 underline underline-offset-4"
                      >
                        {doctor && doctor.name}
                      </h1>
                      <p id="doctext" className="text-lg text-gray-600 mb-4">
                        Currently Working At : {doctor && doctor.hospitalName}
                      </p>
                      <p id="doctext" className="text-lg text-gray-600 mb-4">
                        {doctor && doctor.currentPosition}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-1 md:col-span-1">
                <div className="bg-white border rounded-lg overflow-hidden shadow-lg">
                  <div className="">
                    <div className="mt-5">
                      <div className="p-6 md:p-2 md:h-auto">
                        <div className="flex items-center justify-between mb-5">
                          <p className="text-lg ">Location:</p>
                          <p className="text-lg font-semibold">
                            {doctor && doctor.location}
                          </p>
                        </div>
                        <hr className="mb-3 border-gray-300"></hr>
                        <div className="flex items-center justify-between mb-5">
                          <span className="text-lg">Specialist:</span>
                          <span className="text-lg font-semibold">
                            {doctor && doctor.specialist}
                          </span>
                        </div>
                        <hr className="mb-3 border-gray-300"></hr>
                        <div className="flex items-center justify-between mb-5">
                          <p className="text-lg">Gender: </p>
                          <p className="text-lg font-semibold capitalize">
                            {doctor && doctor.gender}
                          </p>
                        </div>
                        <hr className="mb-3 border-gray-300"></hr>
                        <div
                          id="docbutton"
                          className="flex items-center justify-center"
                        >
                          <button
                            onClick={() => toggleCalendar(doctor)}
                            className="flex gap-1.5 justify-center items-center px-6 py-2 mt-5 text-base font-bold text-center text-white uppercase bg-indigo-800 tracking-[2px] max-md:mt-5"
                          >
                            {showCalendar
                              ? "Hide Calendar"
                              : "Schedule Meeting"}
                          </button>
                          <button
                            onClick={toggleMessaging}
                            className="flex gap-1.5 justify-center items-center px-6 py-2 mt-5 ml-3 text-base font-bold text-center text-white uppercase bg-green-600 tracking-[2px] max-md:mt-5"
                          >
                            {showMessaging ? "Hide Message" : "Send Message"}
                          </button>
                        </div>
                        {showCalendar && (
                          <div className="overlay">
                            <div className="overlay-content">
                              <div>
                                <div className="tabs flex border-b-2">
                                  <button
                                    className={`tab w-1/2 text-center px-4 py-2 text-lg font-bold focus:outline-none ${
                                      calendarTab === "date"
                                        ? "active border-b-2 border-indigo-500 text-indigo-500"
                                        : "text-gray-600"
                                    }`}
                                    onClick={() => setCalendarTab("date")}
                                  >
                                    <FaCalendarAlt className="m-auto" />
                                  </button>
                                  <button
                                    className={`tab w-1/2 text-center px-4 py-2 text-lg font-bold focus:outline-none ${
                                      calendarTab === "time"
                                        ? "active border-b-2 border-indigo-500 text-indigo-500"
                                        : "text-gray-600"
                                    }`}
                                    onClick={() => setCalendarTab("time")}
                                  >
                                    <FaClock className="m-auto" />
                                  </button>
                                </div>

                                {calendarTab === "date" && (
                                  <div className="date-picker">
                                    <Calendar
                                      onChange={onChange}
                                      value={selectedDate}
                                      minDate={new Date()}
                                      className="custom-calendar"
                                      tileClassName={tileClassName}
                                    />
                                  </div>
                                )}
                                {calendarTab === "time" && (
                                  <div className="time-picker mt-2 p-2 shadow-lg">
                                    <div className="grid grid-cols-5 gap-2">
                                      {times.map((time) => (
                                        <button
                                          key={time}
                                          onClick={() => setSelectedTime(time)}
                                          className={`py-3 px-1 rounded-md ${
                                            selectedTime === time
                                              ? "bg-[#11c911] text-white"
                                              : isTimeConflicting(time)
                                              ? "bg-[#fa313191] text-white cursor-not-allowed"
                                              : "bg-gray-200"
                                          }`}
                                          disabled={isTimeConflicting(time)}
                                        >
                                          {time}
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="flex justify-between mt-5">
                                <div className="flex">
                                  <label className="inline-flex items-center mr-4">
                                    <input
                                      type="checkbox"
                                      name="meetingType"
                                      value="virtual"
                                      checked={meetingType === "virtual"}
                                      onChange={(e) =>
                                        setMeetingType(e.target.value)
                                      }
                                      className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span className="ml-2 font-bold text-gray-700">
                                      Virtual
                                    </span>
                                  </label>
                                  <label className="inline-flex items-center">
                                    <input
                                      type="checkbox"
                                      name="meetingType"
                                      value="offline"
                                      checked={meetingType === "offline"}
                                      onChange={(e) =>
                                        setMeetingType(e.target.value)
                                      }
                                      className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span className="ml-2 font-bold text-gray-700">
                                      Offline
                                    </span>
                                  </label>
                                </div>
                                <div className="flex justify-end">
                                  <button
                                    onClick={() => setShowCalendar(false)}
                                    className="px-2 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                                  >
                                    Close
                                  </button>
                                  <button
                                    onClick={handleBookSchedule}
                                    disabled={!selectedTime}
                                    className={`px-3 py-2 text-base font-bold text-center text-white uppercase ${
                                      selectedTime
                                        ? "bg-blue-800 hover:bg-blue-600 cursor-pointer"
                                        : "bg-gray-400 cursor-not-allowed"
                                    }`}
                                  >
                                    Schedule Meeting
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {showMessaging && (
                          <div className="overlay">
                            <div className="overlay-content">
                              <div className="bg-gray-200 p-4 rounded-md">
                                <p className="text-md font-semibold mb-2">
                                  Send a Message
                                </p>
                                <textarea
                                  placeholder="Type your message here..."
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                  className="w-full h-32 p-2 border border-gray-300 rounded-md resize-none focus:outline-none"
                                ></textarea>
                                <div className="flex justify-end mt-3">
                                  <button
                                    onClick={() => setShowMessaging(false)}
                                    className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                                  >
                                    Close
                                  </button>
                                  <button
                                    onClick={handleSendMessage}
                                    className="px-4 py-2 bg-indigo-800 text-white rounded-md font-semibold"
                                  >
                                    Send
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="bg-white border-b-2 border-gray-300 mt-6">
                <h3 className="font-semibold text-xl mb-3">About Doctor</h3>
                <p className="text-md mb-4">{doctor && doctor.about}</p>
              </div>
              <div className="grid gap-4 md:grid-cols-2 mt-4">
                <div
                  id="docexperience"
                  className="bg-white border shadow rounded p-4 mt-[2rem]"
                >
                  <div className="flex-grow mb-[2rem] mt-4 ">
                    <h1 className="text-xl font-semibold">Experience</h1>

                    {doctor && doctor.experience_1 && (
                      <div className="mt-5 flex items-center">
                        <button className="bg-white shadow px-3 border text-[#8697C4] font-semibold rounded-full h-8 w-8 flex items-center justify-center">
                          A
                        </button>
                        <div className="px-5">
                          <p className="text-md ">{doctor.experience_1}</p>
                        </div>
                      </div>
                    )}

                    {doctor && doctor.experience_2 && (
                      <div className="mt-5 flex items-center">
                        <button className="bg-white shadow px-3 border text-[#8697C4] font-semibold rounded-full h-8 w-8 flex items-center justify-center">
                          B
                        </button>
                        <div className="px-5">
                          <p className="text-md ">{doctor.experience_2}</p>
                        </div>
                      </div>
                    )}

                    {doctor && doctor.experience_3 && (
                      <div className="mt-5 flex items-center">
                        <button className="bg-white shadow px-3 border text-[#8697C4] font-semibold rounded-full h-8 w-8 flex items-center justify-center">
                          C
                        </button>
                        <div className="px-5">
                          <p className="text-md ">{doctor.experience_3}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  id="doceducation"
                  className="bg-white border shadow rounded p-4 mt-[2rem] "
                >
                  <div className="flex-grow mb-[2rem] mt-4 ">
                    <h1 className="text-xl font-semibold">Education</h1>

                    {doctor && doctor.education_1 && (
                      <div className="mt-5 flex items-center">
                        <button className="bg-white shadow px-3 border text-[#8697C4] font-semibold rounded-full h-8 w-8 flex items-center justify-center">
                          A
                        </button>
                        <div className="px-5">
                          <p className="text-md ">{doctor.education_1}</p>
                        </div>
                      </div>
                    )}

                    {doctor && doctor.education_2 && (
                      <div className="mt-5 flex items-center">
                        <button className="bg-white shadow px-3 border text-[#8697C4] font-semibold rounded-full h-8 w-8 flex items-center justify-center">
                          B
                        </button>
                        <div className="px-5">
                          <p className="text-md ">{doctor.education_2}</p>
                        </div>
                      </div>
                    )}

                    {doctor && doctor.education_3 && (
                      <div className="mt-5 flex items-center">
                        <button className="bg-white shadow px-3 border text-[#8697C4] font-semibold rounded-full h-8 w-8 flex items-center justify-center">
                          C
                        </button>
                        <div className="px-5">
                          <p className="text-md ">{doctor.education_3}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MrViewDoctor;
