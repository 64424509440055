import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import { IoMdArrowBack } from "react-icons/io";
import { useAuth } from "../AuthContext";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { RiDashboard3Fill } from "react-icons/ri";
import { fetchDoctorByHospitalId, fetchHospitalDataByID, deleteDoctor } from "./AdminFirebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewHsopitalDoctors = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { id } = useParams();
  const [doctors, setDoctors] = useState([]);
  const [hospitalName, setHospitalName] = useState([]);
  const navigate = useNavigate();
  const { isAdminLoggedIn } = useAuth();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      const companyData = await fetchHospitalDataByID(id);
      setHospitalName(companyData.companyName);

      const doctorsData = await fetchDoctorByHospitalId(id);
      setDoctors(doctorsData);
    };
    fetchData();
  }, [id]);

  const handleDeleteProfile = async (doctorId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this profile?"
    );

    // If the user confirms the action
    if (confirmed) {
      try {
        await deleteDoctor(doctorId);
        setDoctors((prevDoctors) =>
          prevDoctors.filter((doctor) => doctor.id !== doctorId)
        );
      } catch (error) {
        console.error("Error deleting doctor profile:", error);
      }
    }
  };

  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  const navigateToDoctorDashboard = async(doctorId) => {
    toast.success("Going to Doctor Dashboard!", {
      autoClose: 2000,
    });
    setTimeout(() => {
      navigate(`/doctorDashboard/${doctorId}`, {
        state: { from: "admin" },
      });
    }, 2000);
  }

  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          <div className="container mx-auto px-5 md:px-3 h-full">
            <div className="overflow-hidden px-10 md:px-5" id="companyedits">
              <div className="flex items-center justify-between mb-4">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
                >
                  <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
                </button>
                <h2 className="flex-grow text-xl mt-2 font-bold text-center uppercase">
                  {hospitalName} Doctors
                </h2>
              </div>

              <div className="overflow-auto shadow-md sm:rounded-lg mt-3 border table-container">
                <table className="divide-y divide-gray-300 w-full text-left rtl:text-right">
                  <thead className="text-sm text-gray-700 uppercase">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 tracking-wider bg-gray-50"
                      >
                        S.N.
                      </th>
                      <th scope="col" className="px-6 py-3 tracking-wider">
                        Doctors Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 tracking-wider bg-gray-50"
                      >
                        Specialist
                      </th>
                      <th scope="col" className="px-6 py-3 tracking-wider">
                        Location
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 tracking-wider bg-gray-50"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {doctors.length === 0 ? (
                      <tr className="bg-white border-b">
                        <td colSpan="5" className="text-center py-4">
                          <p className="text-lg">No Docotrs Found.</p>
                        </td>
                      </tr>
                    ) : (
                      doctors.map((doctor, index) => (
                        <tr key={doctor.id} className="bg-white border-b">
                          <td
                            scope="row"
                            className="px-2 py-3 bg-gray-50 font-medium"
                          >
                            {index + 1}.
                          </td>
                          <td className="px-2 py-3 font-medium">
                            {doctor.name}
                          </td>
                          <td className="px-2 py-3 bg-gray-50">{doctor.specialist}</td>
                          <td className="px-2 py-3 font-medium">
                            {doctor.location}
                          </td>
                          <td className="px-2 py-3 bg-gray-50">
                            <button
                              type="button"
                              className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2"
                            >
                              <FaRegEdit />
                            </button>
                            <button
                              type="button"
                              onClick={() => handleDeleteProfile(doctor.id)}
                              className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2"
                            >
                              <MdDelete />
                            </button>
                            <button
                              type="button"
                              onClick={() => navigateToDoctorDashboard(doctor.id)}
                              className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2"
                            >
                              <RiDashboard3Fill />
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ViewHsopitalDoctors;
