import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import "firebase/storage";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import { useAuth } from "../AuthContext";
import { IoMdArrowBack } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchUserById, updateUserData } from "./AdminFirebase";

const EditCompanyEmployee = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const { isAdminLoggedIn } = useAuth();
    const location = useLocation();

    const { companyId } = location.state || {};

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const usersData = await fetchUserById(id);
                setUser(usersData);
            } catch (error) {
                console.error("Error fetching hospital:", error);
            }
        };
        fetchUserData();
        if (companyId) {
            sessionStorage.setItem("companyId", companyId);
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const newData = { ...user };

            await updateUserData(id, newData);
            toast.success("Data updated successfully!", {
                autoClose: 2000,
            });
            if (companyId) {
                console.log(companyId);
                navigate(`/admin/companies/${companyId}/users`);
            } else {
                navigate("/admin/companies");
            }
        } catch (error) {
            console.error("Error updating document:", error);
        }
    };

    if (!isAdminLoggedIn) {
        return <Navigate to="/admin" />;
    }

    return (
        <div className="flex flex-col h-screen">
            <AdminNavbar />
            <div className="flex flex-1 mt-[4.2rem]">
                <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
                <div
                    className={`overflow-y-auto flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
                        }`}
                >
                    <div className="container max-w-full text-black mx-auto my-5 px-10">
                        <div className="w-full">
                            <form
                                onSubmit={handleSubmit}
                                className="bg-white shadow rounded px-8 pt-6 pb-8 mb-4"
                            >
                                <div className="flex items-center justify-between mb-4">
                                    <button
                                        type="button"
                                        onClick={() => navigate(-1)}
                                        className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
                                    >
                                        <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
                                    </button>
                                    <h2 className="flex-grow text-2xl mb-5 font-bold text-center uppercase">
                                        Edit user
                                    </h2>
                                </div>
                                <div className="grid md:grid-cols-2 md:gap-6">
                                    <div className="relative z-0 w-full mb-6 group">
                                        <input
                                            type="text"
                                            name="firstName"
                                            className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                            value={user?.firstName}
                                            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                                            placeholder=" "
                                            required
                                        />
                                        <label
                                            htmlFor="firstName"
                                            className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            First Name
                                        </label>
                                    </div>
                                    <div className="relative z-0 w-full mb-6 group">
                                        <input
                                            type="text"
                                            name="lastName"
                                            className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                            value={user?.lastName}
                                            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                                            placeholder=" "
                                            required
                                        />
                                        <label
                                            htmlFor="lastName"
                                            className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            Last Name
                                        </label>
                                    </div>
                                    <div className="relative z-0 w-full mb-6 group">
                                        <input
                                            type="email"
                                            name="email"
                                            className="block py-2.5 px-0 w-full disabled:bg-gray-200 text-sm text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                            value={user?.email}
                                            onChange={(e) => setUser({ ...user, email: e.target.value })}
                                            placeholder=" "
                                            disabled
                                        />
                                        <label
                                            htmlFor="email"
                                            className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            Email
                                        </label>
                                    </div>
                                    <div className="relative z-0 w-full mb-6 group">
                                        <input
                                            type="password"
                                            name="password"
                                            className="block py-2.5 px-0 w-full disabled:bg-gray-200 text-sm text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                            value={user?.password}
                                            onChange={(e) => setUser({ ...user, password: e.target.value })}
                                            placeholder=" "
                                            disabled
                                        />
                                        <label
                                            htmlFor="password"
                                            className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            Password
                                        </label>
                                    </div>
                                    <div className="relative z-0 w-full mb-6 group">
                                        <select
                                            name="location"
                                            value={user?.location}
                                            onChange={(e) => setUser({ ...user, location: e.target.value })}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                        >
                                            <option value="">Select Location</option>
                                            <option value="Bangalore">Bangalore</option>
                                            <option value="Delhi">Delhi</option>
                                            <option value="Mumbai">Mumbai</option>
                                            <option value="Kolkata">Kolkata</option>
                                            <option value="Hyderabad">Hyderabad</option>
                                            <option value="Chennai">Chennai</option>
                                        </select>
                                        <label
                                            htmlFor="location"
                                            className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            Location
                                        </label>
                                    </div>
                                    <div className="relative z-0 w-full mb-6 group">
                                        <select
                                            name="role"
                                            value={user?.role}
                                            onChange={(e) => setUser({ ...user, role: e.target.value })}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                        >
                                            <option value="">Select Role</option>
                                            <option value="Sales Head">Sales Head</option>
                                            <option value="Medical Representative">Medical Representative</option>
                                        </select>
                                        <label
                                            htmlFor="role"
                                            className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            Role
                                        </label>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                                >
                                    Update
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right" />
        </div>
    );
};

export default EditCompanyEmployee;
