import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CompanyNavbar from "./CompanyNavbar";
import CompanySide from "./CompanySide";
import { AiFillMessage } from "react-icons/ai";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { FaChartLine } from "react-icons/fa";
import { PiChartPieSliceFill } from "react-icons/pi";
import { useParams } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { fetchMessages, fetchMeetings, fetchScheduledChart, fetchAssignedUserData } from "./CompanyFirebase";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const CompanyDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const [scheduleMeetings, setScheduleMeetings] = useState([]);
  const [chartData, setChartData] = useState({});
  const [userData, setUserData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();


  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const loadMessages = async () => {
      const { sortedMessages } = await fetchMessages(id);
      const sorted = sortedMessages.slice(0, 4);
      setMessages(sorted);
    };

    const fetchScheduleMeetings = async () => {
      const { upcomingMeetings } = await fetchMeetings(id);
      const revolved = upcomingMeetings.slice(0, 4);
      setScheduleMeetings(revolved);
    };

    const fetchMeetingsChart = async () => {
      const { meetingsByMonth, missedMeetingsByMonth } = await fetchScheduledChart(id);

      const currentDate = new Date();
      const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      const desiredMonths = [];
      for (let i = -1; i < 5; i++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear();
        desiredMonths.push(`${month} ${year}`);
      }

      const totalMeetingCounts = desiredMonths.map(
        (label) => meetingsByMonth[label] || 0
      );

      const missedMeetingCounts = desiredMonths.map(
        (label) => missedMeetingsByMonth[label] || 0
      );

      const chartData = {
        labels: desiredMonths,
        datasets: [
          {
            label: "Total Meetings",
            data: totalMeetingCounts,
            fill: false,
            borderColor: "rgba(119,150,230,1)",
            borderWidth: 2,
            tension: 0.1,
            pointStyle: "rectRounded",
            pointBorderWidth: 2,
          },
          {
            label: "Missed Meetings",
            data: missedMeetingCounts,
            fill: false,
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 2,
            tension: 0.1,
            pointStyle: "rectRounded",
            pointBorderWidth: 2,
          },
        ],
      };

      setChartData(chartData);
    };

    const fetchAssignedUser = async () => {
      const assignedUsersData = await fetchAssignedUserData(id);

      const userData = {
        labels: assignedUsersData.map((data) => data.assignedName),
        datasets: [
          {
            label: "Scheduled Meetings",
            data: assignedUsersData.map((data) => data.count),
            backgroundColor: ["rgba(119,150,230,1)", "rgba(61,82,161,1)"],
            borderColor: ["rgba(119,150,230,1)", "rgba(61,82,161,1)"],
            borderWidth: 1,
          },
        ],
      };

      setUserData(userData);
    };

    loadMessages();
    fetchScheduleMeetings();
    fetchMeetingsChart();
    fetchAssignedUser();

    return () => { };
  }, [id]);

  const handleMessageClick = async (doctorId) => {
    navigate(`/company/message/${id}`, { state: { doctorId } });
  };

  const handlemeetings = () => {
    navigate(`/company/meetingsinfo/${id}`);
  }

  return (
    <div className="flex flex-col h-screen">
      <CompanyNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <CompanySide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container px-4 mx-auto my-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="bg-white p-2 rounded shadow-md">
              <div className="bg-[#0D47A1] text-white p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <AiFillMessage className="mr-2" /> Recent Messages
                </h2>
              </div>
              {messages.length === 0 ? (
                <div
                  className="px-4 border-b my-2 shadow-lg cursor-pointer hover:bg-gray-100"
                  style={{ background: "white" }}
                >
                  No messages found !
                </div>
              ) : (
                messages.map((message, index) => (
                  <div
                    key={index}
                    onClick={() => handleMessageClick(message.doctorID)}
                  >
                    <div
                      className="px-4 border-b my-2 shadow-lg cursor-pointer hover:bg-gray-100"
                      style={{ background: "white" }}
                    >
                      <div className="flex justify-between items-center">
                        <div>
                          <div className="font-bold text-gray-700">
                            {message.doctorName}
                          </div>
                          <div className="text-sm font-bold text-gray-600 mt-1">
                            {message.recentMessage.text}
                          </div>
                        </div>
                        <div className="text-xs text-gray-400 text-right">
                          <div>{message.recentMessage.date}</div>
                          <div>{message.recentMessage.time}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="bg-white p-2 rounded shadow-md">
              <div className="bg-[#0D47A1] text-white p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <RiCalendarScheduleLine className="mr-2" /> Upcoming Schedule
                </h2>
              </div>
              <div className="overflow-auto shadow-md sm:rounded-lg mt-3 table-container">
                <table className="divide-y border divide-gray-300 w-full text-left rtl:text-right">
                  <thead className="text-sm text-gray-700 uppercase ">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-2 tracking-wider bg-gray-50"
                      >
                        S.N.
                      </th>
                      <th scope="col" className="px-3 py-2 tracking-wider">
                        Doctor Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 tracking-wider bg-gray-50"
                      >
                        Company Name
                      </th>
                      <th scope="col" className="px-3 py-2 tracking-wider">
                        Assigned
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scheduleMeetings.length === 0 ? (
                      <tr className="bg-white border-b dark:border-gray-200">
                        <td colSpan="3" className="text-center py-2">
                          <p className="text-lg">No Schedule meetings.</p>
                        </td>
                      </tr>
                    ) : (
                      scheduleMeetings.map((meeting, index) => (
                        <tr
                          key={index}
                          onClick={handlemeetings}
                          className="bg-white border-b dark:border-gray-200 cursor-pointer"
                        >
                          <td
                            scope="row"
                            className="px-2 py-2 bg-gray-50 text-center"
                          >
                            {index + 1}.
                          </td>
                          <td className="px-2 py-2 font-medium">
                            {meeting.doctorName}
                          </td>
                          <td className="px-2 py-2 bg-gray-50">
                            {meeting.companyName}
                          </td>
                          <td className="px-2 py-2">{meeting.assignedName}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="bg-white p-2 rounded shadow-md">
              <div className="bg-[#0D47A1] text-white p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <FaChartLine className="mr-2" /> Meetings
                </h2>
              </div>
              {/* <Line data={chartData} /> */}
              {Object.keys(chartData).length > 0 && <Line data={chartData} />}
            </div>

            <div className="bg-white p-2 rounded shadow-md">
              <div className="bg-[#0D47A1] text-white p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <PiChartPieSliceFill className="mr-2" /> Doctors
                </h2>
              </div>
              {Object.keys(userData).length > 0 && (
                <Bar
                  data={userData}
                  options={{
                    indexAxis: "y",
                    elements: {
                      bar: {
                        barPercentage: 0.8,
                        categoryPercentage: 1.0,
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDashboard;
