import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/Home';
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import { AuthProvider } from './components/AuthContext';
import DoctorList from './components/DoctorList';
import CompanyList from './components/CompanyList';
import DoctorProfile from './components/DoctorProfile';
import CompanyProfile from './components/CompanyProfile';
import WarningPage from './pages/WarningPage';
import Navbar from './components/Navbar';
import Sidebar from "./components/Side";
import Pricing from './components/Pricing';
import Footer from './components/Footer';
import LearnMore from './components/LearnMore';

import HospitalDashboard from './components/HospitalUtils/HospitalDashboard';
import HospitalDoctor from './components/HospitalUtils/HospitalDoctors';
import HospitalMeetingInfo from './components/HospitalUtils/HospitalMeetingInfo';
import HospitalAddDoctor from './components/HospitalUtils/HospitalAddDoctor';
import HospitalEditDoctor from './components/HospitalUtils/HospitalEditDoctor';
import HospitalMessages from './components/HospitalUtils/HospitalMessages';
import HospitalSettings from './components/HospitalUtils/HospitalSettings'

import DoctorProfileComplete from './components/Doctorutils/DoctorProfileComplete';
import DoctorDashboard from './components/Doctorutils/DoctorDashboard';
// import DocdashProfile from './components/Doctorutils/DocdashProfile';
import EditDoctorAbout from './components/Doctorutils/EditDoctorAbout';
import EditDoctorExperience from './components/Doctorutils/EditDoctorExperience';
import EditDoctorEducation from './components/Doctorutils/EditDoctorEducation';
import EditDoctorCurrentposition from './components/Doctorutils/EditDoctorCurrentposition';
import EditDoctorImage from './components/Doctorutils/EditDoctorImage';
import DoctorSchedule from './components/Doctorutils/DoctorSchedule';
import DoctorMessage from './components/Doctorutils/DoctorMessage';
import DoctorEarning from './components/Doctorutils/DoctorEarning';
import DoctorSetting from './components/Doctorutils/DoctorSetting';
import DoctorAnalytics from './components/Doctorutils/DoctorAnalytics';
import DoctorMeetingsInfo from './components/Doctorutils/DoctorMeetingsInfo';
import ProfileDoctor from './components/Doctorutils/ProfileDoctor';
import CompanyViewProfile from './components/Doctorutils/CompanyViewProfile';

import AdminLogin from './components/AdminUtils/AdminLogin';
import AdminDashboard from './components/AdminUtils/AdminDashboard';
import Doctor from './components/AdminUtils/Doctor';
import AddDoctor from './components/AdminUtils/AddDoctor';
import EditDoctor from './components/AdminUtils/EditDoctor';
import ScheduleList from './components/AdminUtils/ScheduleList';
import AddManager from './components/AdminUtils/AddManager';
import Manager from './components/AdminUtils/Manager';
import EditManager from './components/AdminUtils/EditManager';
import AdminMessage from './components/AdminUtils/AdminMessage';
import Company from './components/AdminUtils/Company';
import AddCompany from './components/AdminUtils/AddCompany';
import EditCompany from './components/AdminUtils/EditCompany';
import AdminMissedMeeting from './components/AdminUtils/AdminMissedMeeting';
import ViewCompanyDetails from './components/AdminUtils/ViewCompanyDetails';
import ViewCompanyUsers from './components/AdminUtils/ViewCompanyUsers';
import ViewCompanyMeetings from './components/AdminUtils/ViewCompanyMeetings';
import ViewCompanyCredits from './components/AdminUtils/ViewCompanyCredits';
import AdminCompletedMeeting from './components/AdminUtils/AdminCompletedMeeting';
import Hospital from './components/AdminUtils/Hospital';
import AddHospital from './components/AdminUtils/AddHospital';
import ViewHospitalDetails from './components/AdminUtils/ViewHospitalDetails';
import ViewHospitalDoctors from './components/AdminUtils/ViewHospitalDoctors';
import ViewHospitalmeetings from './components/AdminUtils/ViewHospitalmeetings';
import ViewHospitalEarnings from './components/AdminUtils/ViewHospitalEarnings';
import ViewDoctorsDetails from './components/AdminUtils/ViewDoctorsDetails';
import ViewDoctorsMeetings from './components/AdminUtils/ViewDoctorsMeetings';
import ViewDoctorsEarning from './components/AdminUtils/ViewDoctorsEarning';
import EditHospital from './components/AdminUtils/EditHospital';
import EditCompanyEmployee from './components/AdminUtils/EditCompanyEmployee';

import ManagerDashboard from './components/ManagerUtils/ManagerDashboard';
import ManagerSchedule from './components/ManagerUtils/ManagerSchedule';
import ManageDoctors from './components/ManagerUtils/ManageDoctors';
import ManageCompanies from './components/ManagerUtils/ManageCompanies';
import ManagerMessages from './components/ManagerUtils/ManagerMessages';

import ProfileComplete from './components/CompanyUtils/ProfileComplete';
import CompanyDashboard from './components/CompanyUtils/CompanyDashboard';
import CompanySchedule from './components/CompanyUtils/CompanySchedule';
import CompanyMessage from './components/CompanyUtils/CompanyMessage';
// import ComdashProfile from './components/CompanyUtils/ComdashProfile';
import Products from './components/CompanyUtils/Products';
import Users from './components/CompanyUtils/Users';
import AddProduct from './components/CompanyUtils/AddProduct';
import AddUsers from './components/CompanyUtils/AddUsers';
import CompanyCredits from './components/CompanyUtils/CompanyCredits';
// import EditCompanyName from './components/CompanyUtils/EditCompanyName';
// import EditCompanyAbout from './components/CompanyUtils/EditCompanyAbout';
// import EditCompanyDetails from './components/CompanyUtils/EditCompanyDetails';
import EditCompanyUser from './components/CompanyUtils/EditCompanyUser';
import DoctorViewProfile from './components/CompanyUtils/DoctorViewProfile';
import CompanySetting  from './components/CompanyUtils/CompanySetting ';
import CompanyAnalytics from './components/CompanyUtils/CompanyAnalytics';
import MyDoctors from './components/CompanyUtils/MyDoctors';
import AllDoctors from './components/CompanyUtils/AllDoctors';
import AllHospitals from './components/CompanyUtils/AllHospitals';
import Timeline from './components/CompanyUtils/Timeline';
import ProfileCompany from './components/CompanyUtils/ProfileCompany';
import CompanyMeetingsInfo from './components/CompanyUtils/CompanyMeetingsInfo';

import SalesDashboard from './components/SalesUtils/SalesDashboard';
import SalesHeadUsers from './components/SalesUtils/SalesHeadUsers';
import AddSalesHeadUser from './components/SalesUtils/AddSalesHaedUser';
import SalesHeadMessage from './components/SalesUtils/SalesHeadMessage';
import SalesHeadProduct from './components/SalesUtils/SalesHeadProduct';
import SalesAddProduct from './components/SalesUtils/SalesAddProduct';
import SalesAllDoctors from './components/SalesUtils/SalesAllDoctors';
import SalesViewDoctor from './components/SalesUtils/SalesViewDoctor';
import SalesHeadSchedule from './components/SalesUtils/SalesHeadSchedule';
import EditSalesHeadUser from './components/SalesUtils/EditSalesHeadUser';
import SalesHeadSetting  from './components/SalesUtils/SalesHeadSetting';
import SalesMyDoctors from './components/SalesUtils/SalesMyDoctors';
import SalesTimeline from './components/SalesUtils/SalesTimeline';
import SalesAnalytics from './components/SalesUtils/SalesAnalytics';
import SalesMeetingsInfo from './components/SalesUtils/SalesMeetingsInfo';
import SalesAllHospitals from './components/SalesUtils/SalesAllHospitals';

import MrDashboard from './components/MrUtils/MrDashboard';
import MrSchedule from './components/MrUtils/MrSchedule';
import MrMessage from './components/MrUtils/MrMessage';
import MrProduct from './components/MrUtils/MrProduct';
import MrAddProduct from './components/MrUtils/MrAddProduct';
import MrViewDoctor from './components/MrUtils/MrViewDoctor';
import MrSetting  from './components/MrUtils/MrSetting';
import MrAllDoctors from './components/MrUtils/MrAllDoctors';
import MrMyDoctors from './components/MrUtils/MrMyDoctors';
import MrTimeline from './components/MrUtils/MrTimeline';
import MrAnalytics from './components/MrUtils/MrAnalytics';
import MrMeetingsInfo from './components/MrUtils/MrMeetingsInfo';
import MrAllHospitals from './components/MrUtils/MrAllHospitals';

import TermsAndConditions from './pages/TermsAndConditions';
import './App.css'
import DocProtfolio from './components/DocProtfolio';
import PrivacyPolicy from './pages/PrivacyPolicy';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const isAuthorized = true; 
  
  
  return (
    <BrowserRouter>
    <AuthProvider>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/company" element={<CompanyList />} />
          <Route path="/doctorlist" element={<DoctorList />} />
          <Route path="/doctor/:id" element={<DoctorProfile />} />
          <Route path="/company/:id" element={<CompanyProfile />} /> 
          <Route path="/warning" element={<WarningPage />} />
          <Route path='/price' element={<Pricing/>}/>
          <Route path='/footer' element={<Footer/>}/>
          <Route path='/terms' element = {<TermsAndConditions/>} />
          <Route path='/privacy' element = {<PrivacyPolicy/>} />


          <Route path='/hospitalDashboard/:id' element = {<HospitalDashboard/>} />
          <Route path='/hospital/doctors/:id' element = {<HospitalDoctor />} />
          <Route path='/hospital/meetingsinfo/:id' element = {<HospitalMeetingInfo />} />
          <Route path='/hospital/add-doctor/:id' element = {<HospitalAddDoctor />} />
          <Route path='/hospital/edit-doctor/:id' element = {<HospitalEditDoctor />} />
          <Route path='/hospital/message/:id' element = {<HospitalMessages />} />
          <Route path='/hospital/setting/:id' element = {<HospitalSettings />} />


          <Route path='/doctorprofilecomplete/:id' element={<DoctorProfileComplete/>}/>
          <Route path='/doctorDashboard/:id' element={<DoctorDashboard/>}/>
          {/* <Route path='/doctor/profile/:id' element={<DocdashProfile/>}/>           */}
          <Route path='/doctor/profile/edit-about/:id' element={<EditDoctorAbout/>}/>          
          <Route path='/doctor/profile/edit-experience/:id' element={<EditDoctorExperience/>}/>          
          <Route path='/doctor/profile/edit-education/:id' element={<EditDoctorEducation/>}/>          
          <Route path='/doctor/profile/edit-currentposition/:id' element={<EditDoctorCurrentposition/>}/>          
          <Route path='/doctor/profile/edit-image/:id' element={<EditDoctorImage/>}/>          
          <Route path='/doctor/schedule/:id' element={<DoctorSchedule/>}/>          
          <Route path='/doctor/message/:id' element={<DoctorMessage/>}/>
          <Route path='/doctor/setting/:id' element={<DoctorSetting/>}/>
          <Route path='/doctor/analytics/:id' element={<DoctorAnalytics/>}/>
          <Route path='/doctor/earning/:id' element={<DoctorEarning/>}/>
          <Route path='/doctor/meetingsinfo/:id' element={<DoctorMeetingsInfo/>}/>
          <Route path='/doctor/profileDoctor/:id' element={<ProfileDoctor/>}/>
          <Route path='/doctor/companyProfile/:id' element={<CompanyViewProfile/>}/>
          <Route path='/docprotfolio' element={<DocProtfolio/>} />

          <Route path='/admin' element={<AdminLogin/>}/>
          <Route path='/admin/dash' element={<AdminDashboard/>}/>
          <Route path='/admin/doctors' element={<Doctor/>}/>
          <Route path='/admin/add-doctor' element={<AddDoctor/>}/>
          <Route path='/admin/edit-doctor/:id' element={<EditDoctor/>}/>
          <Route path='/admin/Schedule' element={<ScheduleList />}/>    
          <Route path='/admin/manager' element={<Manager />}/>    
          <Route path='/add-manager' element={<AddManager/>}/> 
          <Route path='/edit-manager/:id' element={<EditManager/>}/>     
          <Route path='/admin/messages' element={<AdminMessage />} />  
          {/* <Route path='/admin/doctorMessage' element={<AdminDoctorMessage />} />  */}
          <Route path='/admin/companies' element={<Company />} /> 
          <Route path='/admin/add-company' element={<AddCompany />} /> 
          <Route path='/admin/edit-company/:id' element={<EditCompany />} /> 
          <Route path='/admin/missedMeeting' element={<AdminMissedMeeting />} /> 
          <Route path='/admin/viewcompany/:id' element={<ViewCompanyDetails />} /> 
          <Route path='/admin/companies/:id/users' element={<ViewCompanyUsers />} /> 
          <Route path='/admin/companies/:id/meetings' element={<ViewCompanyMeetings />} /> 
          <Route path='/admin/companies/:id/credits' element={<ViewCompanyCredits />} /> 
          <Route path='/admin/completedMeeting' element={<AdminCompletedMeeting />} /> 
          <Route path='/admin/hospital' element={<Hospital />} /> 
          <Route path='/admin/add-hospital' element={<AddHospital />} /> 
          <Route path='/admin/viewhospital/:id' element={<ViewHospitalDetails />} /> 
          <Route path='/admin/hospitals/:id/doctors' element={<ViewHospitalDoctors />} /> 
          <Route path='/admin/hospitals/:id/meetings' element={<ViewHospitalmeetings />} /> 
          <Route path='/admin/hospitals/:id/earnings' element={<ViewHospitalEarnings />} /> 
          <Route path='/admin/viewdoctor/:id' element={<ViewDoctorsDetails/>} />
          <Route path='/admin/doctors/:id/meetings' element={<ViewDoctorsMeetings/>} />
          <Route path='/admin/doctors/:id/earnings' element={<ViewDoctorsEarning/>} />
          <Route path='/admin/edit-hospital/:id' element={<EditHospital/>} />
          <Route path='/admin/edit-companyUser/:id' element={<EditCompanyEmployee/>} />

          {/* growth manager  */}
          <Route path='/manager/dash/:id' element={<ManagerDashboard />} />  
          <Route path='/manager/doctorSchedule' element={<ManagerSchedule />} />  
          {/* <Route path='/manager/companyMessage' element={<ManagerCompany />} />  
          <Route path='/manager/doctorMessage' element={<ManagerDoctor />} />   */}
          <Route path='/manager/doctors' element={<ManageDoctors />} />  
          <Route path='/manager/companies' element={<ManageCompanies />} /> 
          <Route path='/manager/messages' element={<ManagerMessages />} /> 

          {/* company  */}
          <Route path='/profilecomplete/:id' element={<ProfileComplete/>}/>
          <Route path='/companyDashboard/:id' element={<CompanyDashboard/>}/>
          <Route path='/company/schedule/:id' element={<CompanySchedule/>}/>
          <Route path='/company/message/:id' element={<CompanyMessage/>}/>
          {/* <Route path='/company/profile/:id' element={<ComdashProfile/>}/> */}
          <Route path='/company/products/:id' element={<Products/>}/>
          <Route path='/company/users/:id' element={<Users/>}/>
          <Route path='/company/add-product/:id' element={<AddProduct/>}/>
          <Route path='/company/add-user/:id' element={<AddUsers/>}/>
          <Route path='/company/credits/:id' element={<CompanyCredits/>}/>
          {/* <Route path='/company/profile/edit-Name/:id' element={<EditCompanyName/>}/>
          <Route path='/company/profile/edit-about/:id' element={<EditCompanyAbout/>}/>
          <Route path='/company/profile/edit-details/:id' element={<EditCompanyDetails/>}/> */}
          <Route path='/company/edit-user/:id' element={<EditCompanyUser/>}/>
          <Route path='/company/viewProfile/:id' element={<DoctorViewProfile/>}/>
          <Route path='/company/setting/:id' element={<CompanySetting/>}/>
          <Route path='/company/analytics/:id' element={<CompanyAnalytics/>}/>
          <Route path='/company/doctors/:id' element={<MyDoctors/>}/>
          <Route path='/company/allDoctors/:id' element={<AllDoctors/>}/>
          <Route path='/company/allHospitals/:id' element={<AllHospitals/>}/>
          <Route path='/company/timeline/:id' element={<Timeline/>}/>
          <Route path='/company/companyprofile/:id' element={<ProfileCompany/>}/>
          <Route path='/company/meetingsinfo/:id' element={<CompanyMeetingsInfo/>}/>

          <Route path='/learnMore' element={<LearnMore />}/>

          <Route path='/salesDashboard/:id' element={<SalesDashboard />} />
          <Route path='/sales/schedule/:id' element={<SalesHeadSchedule />} />
          <Route path='/sales/users/:id' element={<SalesHeadUsers />} />
          <Route path='/sales/add-user/:id' element={<AddSalesHeadUser />} />
          <Route path='/sales/edit-user/:id' element={<EditSalesHeadUser />} />
          <Route path='/sales/message/:id' element={<SalesHeadMessage />} />
          <Route path='/sales/products/:id' element={<SalesHeadProduct />} />
          <Route path='/sales/add-product/:id' element={<SalesAddProduct />} />
          <Route path='/sales/doctors/:id' element={<SalesMyDoctors />} />
          <Route path='/sales/alldoctors/:id' element={<SalesAllDoctors />} />
          <Route path='/sales/allHospitals/:id' element={<SalesAllHospitals />} />
          <Route path='/sales/viewProfile/:id' element={<SalesViewDoctor />} />
          <Route path='/sales/setting/:id' element={<SalesHeadSetting/>}/>
          <Route path='/sales/timeline/:id' element={<SalesTimeline/>}/>
          <Route path='/sales/analytics/:id' element={<SalesAnalytics/>}/>
          <Route path='/sales/meetingsinfo/:id' element={<SalesMeetingsInfo/>}/>
          
          <Route path='/mrDashboard/:id' element={<MrDashboard />} />
          <Route path='/mr/schedule/:id' element={<MrSchedule />} />
          <Route path='/mr/message/:id' element={<MrMessage />} />
          <Route path='/mr/products/:id' element={<MrProduct />} />
          <Route path='/mr/add-product/:id' element={<MrAddProduct />} />
          <Route path='/mr/viewProfile/:id' element={<MrViewDoctor />} />
          <Route path='/mr/setting/:id' element={<MrSetting/>}/>
          <Route path='/mr/doctors/:id' element={<MrMyDoctors />} />
          <Route path='/mr/alldoctors/:id' element={<MrAllDoctors />} />
          <Route path='/mr/allHospitals/:id' element={<MrAllHospitals />} />
          <Route path='/mr/timeline/:id' element={<MrTimeline />} />
          <Route path='/mr/analytics/:id' element={<MrAnalytics />} />
          <Route path='/mr/meetingsinfo/:id' element={<MrMeetingsInfo />} />


          {/* Route for the home page */}
          <Route path="*" element={isAuthorized ? <Home /> : <WarningPage />} />
          <Route path='/nav' element={<Navbar />}/>
          <Route path='/side' element={<Sidebar />}/>
        </Routes>
      </AuthProvider>
      </BrowserRouter>
  );
};

export default App;