import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import {
  getDownloadURL,
  ref,
  getStorage,
  deleteObject,
  uploadBytes,
} from "firebase/storage";
import { useAuth } from "../AuthContext";
import { getAuth, signOut } from "firebase/auth";
import Side1 from "../../assets/img/Side1.jpg";
import Side2 from "../../assets/img/Side2.jpg";
import Side3 from "../../assets/img/Side3.jpg";
import "../style/profilecomplete.css";

const ProfileComplete = () => {
  const { id } = useParams();
  const [company, setCompany] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const storage = getStorage();
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const auth = getAuth();

  const navigate = useNavigate();

  const fetchCompanyProfile = async () => {
    try {
      const db = getFirestore();
      const companyRef = doc(db, "companies", id);
      const docSnap = await getDoc(companyRef);
      if (docSnap.exists()) {
        setCompany({ id: docSnap.id, ...docSnap.data() });
        if (docSnap.data().image) {
          const url = await getDownloadURL(ref(storage, docSnap.data().image));
          setImageUrl(url);
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching company:", error);
    }
  };

  useEffect(() => {
    fetchCompanyProfile();
  }, [id, storage]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
    // setImageRemoved(false);
  };

  const removeImage = () => {
    setImageUrl(null);
    setImageFile(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const db = getFirestore();
      const companyRef = doc(db, "companies", id);
      const newData = {
        ...company,
        profileComplete: true,
      };

      if (imageFile) {
        const imageRef = ref(storage, `images/${imageFile.name}`);
        await uploadBytes(imageRef, imageFile);
        const downloadURL = await getDownloadURL(imageRef);
        newData.image = downloadURL;
      } else if (!imageUrl) {
        newData.image = "";
      }

      await updateDoc(companyRef, newData);

      console.log("Document successfully updated!");
      alert("Data successfully updated!");

      signOut(auth)
        .then(() => {
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error signing out:", error);
        });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const nextStep = () => {
    // setCurrentStep((prevStep) => prevStep + 1);
    if (currentStep < 3) setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    // setCurrentStep((prevStep) => prevStep - 1);
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  if (!company) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex items-center justify-center min-h-screen px-4 bg-gray-100">
      <div className="w-full max-w-6xl shadow rounded-xl border border-gray-100 p-2 bg-white">
        <div className="formcard">
          <form
            onSubmit={handleSubmit}
            className="bg-white"
          >
            {currentStep === 1 && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="col-span-1 md:col-span-1">
                  <img src={Side1} className="w-full h-full object-cover" alt="Profile" />
                </div>
                <div className="col-span-1 md:col-span-2 mt-5 p-4">
                  <h2 className="text-center font-[K2D] text-2xl mb-3 font-bold">
                    Update Company Profile
                  </h2>

                  <div className="relative">
                    <div className="relative flex justify-between text-xs text-gray-600 my-7">
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep >= 1 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          1
                        </span>
                        <span className={`ml-2 ${currentStep >= 2 ? 'text-[#7191e6]' : 'text-gray-500'}`}>Basic Information</span>
                      </div>
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep >= 2 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          2
                        </span>
                        <span className={`ml-2 ${currentStep >= 3 ? 'text-[#7191e6]' : 'text-gray-500'}`}>Additional Information</span>
                      </div>
                    </div>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="companyName"
                      id="companyName"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={company.companyName || ""}
                      onChange={(e) =>
                        setCompany({ ...company, companyName: e.target.value })
                      }
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="companyName"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Company Name
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={company.name || ""}
                      onChange={(e) =>
                        setCompany({ ...company, name: e.target.value })
                      }
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="name"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Representative Name
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={company.email || ""}
                      onChange={(e) =>
                        setCompany({ ...company, email: e.target.value })
                      }
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="email"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Email
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={company.phone || ""}
                      onChange={(e) =>
                        setCompany({ ...company, phone: e.target.value })
                      }
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="phone"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Phone
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <select
                      name="location"
                      value={company.location || ""}
                      onChange={(e) =>
                        setCompany({ ...company, location: e.target.value })
                      }
                      required
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                    >
                      <option value="">Select Location</option>
                      <option value="Bangalore">Bangalore</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Kolkata">Kolkata</option>
                      <option value="Hyderabad">Hyderabad</option>
                      <option value="Chennai">Chennai</option>
                    </select>
                    <label
                      htmlFor="location"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Location
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      name="headquarter"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={company.headquarter || ""}
                      onChange={(e) =>
                        setCompany({ ...company, headquarter: e.target.value })
                      }
                      placeholder=" "
                    />
                    <label
                      htmlFor="headquarter"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Headquarter
                    </label>
                  </div>

                  <div style={{ textAlign: "end" }}>
                    <button
                      type="button"
                      onClick={nextStep}
                      className="bg-[#7191e6] text-white px-6 py-1 font-bold border rounded-full"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="col-span-1 md:col-span-1">
                  <img src={Side3} className="w-full h-full object-cover" alt="Profile" />
                </div>
                <div className="col-span-1 md:col-span-2 mt-5 p-4">
                  <h2 className="text-center font-[K2D] text-2xl mb-3 font-bold">
                    Update Company Profile
                  </h2>

                  <div className="relative">
                    <div className="relative flex justify-between text-xs text-gray-600 my-7">
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep >= 1 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          1
                        </span>
                        <span className={`ml-2 ${currentStep >= 2 ? 'text-[#7191e6]' : 'text-gray-500'}`}>Basic Information</span>
                      </div>
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep >= 2 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          2
                        </span>
                        <span className={`ml-2 ${currentStep >= 3 ? 'text-[#7191e6]' : 'text-gray-500'}`}>Additional Information</span>
                      </div>
                    </div>
                  </div>


                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      name="about"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={company.about || ""}
                      onChange={(e) =>
                        setCompany({ ...company, about: e.target.value })
                      }
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="about"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      About Company
                    </label>
                  </div>

                  <div className="my-5">
                    <label
                      htmlFor="image"
                      className="block mb-2 px-2 text-lg font-bold text-gray-900 dark:text-white"
                    >
                      Image
                    </label>
                    {imageUrl ? (
                      <div className="relative">
                        <img
                          src={imageUrl}
                          alt="Profile"
                          className="w-40 h-40 rounded-full mx-auto my-4"
                        />
                        <button
                          type="button"
                          className="absolute top-2 right-2 bg-red-600 text-white rounded-full p-1"
                          onClick={removeImage}
                        >
                          Remove
                        </button>
                      </div>
                    ) : (
                      <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                                onChange={handleImageUpload}
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG, JPG
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      name="category"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={company.category || ""}
                      onChange={(e) =>
                        setCompany({ ...company, category: e.target.value })
                      }
                      placeholder=" "

                    />
                    <label
                      htmlFor="category"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Category
                    </label>
                  </div>

                  <div className="flex justify-between mt-4">
                    <button
                      type="button"
                      onClick={prevStep}
                      className="bg-gray-400 text-white font-bold py-1 px-4 rounded-full mr-2"
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      className="bg-[#7191e6] text-white px-6 py-1 font-bold border rounded-full"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
            
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileComplete;
