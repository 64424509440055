import { getFirestore, collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword, } from "firebase/auth";
import { format } from "date-fns";
import { db, storage } from "../firebase";
import { v4 as uuid } from "uuid";

//fetch Users
export const fetchUserCompanyId = async (id) => {
  try {
    const userDocRef = doc(db, "users", id);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      return userData.companyId;
    } else {
      console.log("User document not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user document:", error);
    return null;
  }
};

// Upload Images
export const uploadImage = async (file) => {
  try {
    const storageRef = ref(storage, `images/products/${file.name}`);
    const uploadTaskSnapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(uploadTaskSnapshot.ref);
    return url;
  } catch (error) {
    console.error("Error uploading image:", error);
    return null;
  }
};

//add Product
export const addMrProduct = async (productData) => {
  try {
    const customId = `${productData.productName}_${productData.companyId}`;
    const customDocRef = doc(db, "products", customId);
    await setDoc(customDocRef, {
      ...productData,
    });
    return true;
  } catch (error) {
    console.error("Error adding document :", error);
    throw error;
  }
}

//fetch MrProduct
export const fetchMrProducts = async (companyId) => {
  try {
    const q = query(collection(db, "products"), where("companyId", "==", companyId))
    const querySnapshot = await getDocs(q);
    const productsList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return productsList;
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

//Fetch Meetings
export const fetchMeetings = async (id, searchDate, searchLocation) => {
  try {
    const meetingsRef = collection(db, "scheduleMeeting");
    const q = query(meetingsRef, where("assigned", "==", id));
    const querySnapshot = await getDocs(q);
    const now = new Date();

    const fetchDoctorData = async (doctorId) => {
      const doctorDocRef = doc(db, "doctors", doctorId);
      const doctorDocSnapshot = await getDoc(doctorDocRef);
      return doctorDocSnapshot.exists() ? doctorDocSnapshot.data() : null;
    };

    const fetchCompanyData = async (companyId) => {
      const companyDocRef = doc(db, "companies", companyId);
      const companyDocSnapshot = await getDoc(companyDocRef);
      return companyDocSnapshot.exists() ? companyDocSnapshot.data() : null;
    };

    const fetchAssignedData = async (userId) => {
      const userCollections = ["companies", "users"];
      for (const collectionName of userCollections) {
        const userDocRef = doc(db, collectionName, userId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          if (collectionName === "users") {
            const userData = userDocSnapshot.data();
            return `${userData.firstName} ${userData.lastName}`;
          }
          return userDocSnapshot.data().name;
        }
      }
      return "Unknown Assigned";
    };

    const meetingsData = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const meetingData = docSnapshot.data();
        const doctorData = await fetchDoctorData(meetingData.doctorID);
        const companyData = await fetchCompanyData(meetingData.companyID);
        const assignedData = await fetchAssignedData(meetingData.assigned);

        // Update meeting status if necessary
        const meetingDateTime = new Date(`${meetingData.date} ${meetingData.time}`);
        const timeDiff = now - meetingDateTime;
        const hoursDiff = timeDiff / (1000 * 60 * 60);
        
        if (hoursDiff > 24 && meetingData.status !== "completed") {
          meetingData.status = "missed";
          await updateDoc(doc(db, "scheduleMeeting", docSnapshot.id), {
            status: "missed",
          });
        }

        return {
          id: docSnapshot.id,
          ...meetingData,
          doctorName: doctorData ? doctorData.name : "Unknown Doctor",
          location: doctorData ? doctorData.location : "Unknown location",
          companyName: companyData ? companyData.companyName : "Unknown Company",
          assignedName: assignedData,
        };
      })
    );

    const filteredMeetings = meetingsData.filter((meeting) => {
      const matchesDate = !searchDate || format(new Date(meeting.date), "yyyy-MM-dd") === searchDate;
      const matchesLocation = !searchLocation || meeting.location.toLowerCase().includes(searchLocation.toLowerCase());

      return matchesDate && matchesLocation;
    });

    const filteredUpcomingMeetings = filteredMeetings.filter((meeting) => {
      return meeting.status !== "missed" && meeting.status !== "completed";
    });

    const filteredMissedMeetings = filteredMeetings.filter((meeting) => {
      return meeting.status === "missed";
    });

    const filteredCompletedMeetings = filteredMeetings.filter((meeting) => {
      return meeting.status === "completed";
    });

    const sortMeetings = (meetings) =>
      meetings.sort((a, b) => new Date(`${a.date} ${a.time}`) - new Date(`${b.date} ${b.time}`));

    return {
      upcoming: sortMeetings(filteredUpcomingMeetings),
      missed: sortMeetings(filteredMissedMeetings),
      completed: sortMeetings(filteredCompletedMeetings),
    };
  } catch (error) {
    console.error("Error fetching meetings:", error);
    throw error;
  }
};

export const updateMeeting = async (meetingId, selectedDate, selectedTime, meetingType,) => {
  try {
    const meetingDocRef = doc(db, "scheduleMeeting", meetingId);
    const adjustedDate = new Date(selectedDate);
    const ISTOffset = 330;
    adjustedDate.setMinutes(adjustedDate.getMinutes() + ISTOffset);
    const formattedDate = adjustedDate.toISOString().split("T")[0];

    await updateDoc(meetingDocRef, {
      date: formattedDate,
      time: selectedTime,
      status: "Rescheduled",
      meetingType: meetingType,
    });
  } catch (error) {
    console.error("Error updating meeting:", error);
    throw error;
  }
};

export const acceptMeeting = async (meetingId) => {
  try {
    const meetingDocRef = doc(db, "scheduleMeeting", meetingId);
    await updateDoc(meetingDocRef, { status: "Accepted" });
  } catch (error) {
    console.error("Error accepting meeting:", error);
    throw error;
  }
};

//Fetch Massages
export const fetchMessagesFromFirestore = async (id, doctorIdFromState) => {
  try {
    const userDocRef = doc(db, "users", id);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      const companyId = userData.companyId;
      const messageRef = collection(db, "messages");
      const q = query(messageRef, where("companyID", "==", companyId));
      const querySnapshot = await getDocs(q);

      const fetchDoctorData = async (doctorId) => {
        const doctorDocRef = doc(db, "doctors", doctorId);
        const doctorDocSnapshot = await getDoc(doctorDocRef);
        return doctorDocSnapshot.exists() ? doctorDocSnapshot.data() : null;
      };

      const fetchAssignedMessage = async (messageId) => {
        let assignedName;
        try {
          const companyDocRef = doc(db, "companies", messageId);
          const companyDocSnapshot = await getDoc(companyDocRef);
          assignedName = companyDocSnapshot.exists()
            ? companyDocSnapshot.data().name
            : null;

          if (!assignedName) {
            const userDocRef = doc(db, "users", messageId);
            const userDocSnapshot = await getDoc(userDocRef);
            assignedName = userDocSnapshot.exists()
              ? `${userDocSnapshot.data().firstName} ${userDocSnapshot.data().lastName}`
              : null;
          }
        } catch (error) {
          console.error("Error fetching assigned data:", error);
        }
        return assignedName;
      };

      const groupedMessages = {};
      const promises = querySnapshot.docs.map(async (doc) => {
        const messageData = doc.data();
        const assignedName = await fetchAssignedMessage(messageData.messageId);
        const doctorData = await fetchDoctorData(messageData.doctorID);
        const doctorName = doctorData ? doctorData.name : "Unknown Doctor";

        const key = `${messageData.doctorID}_${messageData.companyID}`;
        if (!groupedMessages[key]) {
          groupedMessages[key] = {
            doctorName,
            assignedName,
            doctorID: messageData.doctorID,
            companyID: messageData.companyID,
            messages: [],
            recentMessage: {
              text: "",
              isCompany: false,
              date: "",
              time: "",
            },
          };
        }

        const timestamp = messageData.timestamp?.toDate();
        const date = timestamp ? format(timestamp, "dd/MM/yyyy") : "N/A";
        const time = timestamp ? format(timestamp, "hh:mm:ss a") : "N/A";

        groupedMessages[key].messages.push({
          messageId: messageData.messageId,
          sentId: messageData.sentId,
          id: doc.id,
          message: messageData.messages,
          sentBy: messageData.sentBy,
          date,
          time,
        });

        if (
          !groupedMessages[key].recentMessage.timestamp ||
          timestamp > groupedMessages[key].recentMessage.timestamp
        ) {
          groupedMessages[key].recentMessage = {
            text: messageData.messages,
            isCompany: messageData.sentBy === "company",
            date,
            time,
            timestamp,
          };
        }
      });

      await Promise.all(promises);

      const messagesArray = Object.keys(groupedMessages).map(
        (key) => groupedMessages[key]
      );
      // Sort messages by recent message timestamp
      const sortedMessages = sortMessagesByTimestamp(messagesArray);

      if (doctorIdFromState) {
        const conversation = sortedMessages.find(
          (conv) => conv.doctorID === doctorIdFromState
        );
        if (conversation) {
          return { sortedMessages, conversation };
        }
      }
      return { sortedMessages };
    }
  } catch (error) {
    console.error("Error fetching schedule messages:", error);
    throw error;
  }
};

const sortMessagesByTimestamp = (messagesArray) => {
  return messagesArray.sort((a, b) => {
    const timestampA = a.recentMessage.timestamp;
    const timestampB = b.recentMessage.timestamp;
    if (timestampA && timestampB) {
      return timestampB - timestampA;
    }
    return 0;
  });
};

export const sendReplyToFirestore = async (replyMessage, currentConversation, id) => {
  if (!replyMessage.trim()) {
    throw new Error("Message cannot be empty!");
  }

  try {
    if (!currentConversation || !currentConversation.doctorID) {
      throw new Error("Conversation or doctor ID not found.");
    }

    const senderId = id;
    const replyData = {
      companyID: currentConversation.companyID,
      doctorID: currentConversation.doctorID,
      messageId: currentConversation.messages[0].messageId,
      messages: replyMessage,
      sentBy: "company",
      sentId: senderId,
      timestamp: new Date(),
    };

    const customId = `${id}_${currentConversation.doctorID}_${Date.now()}`;
    const customDocRef = doc(db, "messages", customId);
    await setDoc(customDocRef, replyData);

    const timestamp = replyData.timestamp;
    const date = format(timestamp, "dd/MM/yyyy");
    const time = format(timestamp, "hh:mm:ss a");

    return {
      messageId: replyData.messageId,
      sentId: replyData.sentId,
      id: customId,
      message: replyData.messages,
      sentBy: replyData.sentBy,
      date,
      time,
    };
  } catch (error) {
    console.error("Error sending reply:", error);
    throw error;
  }
};

//my doctors
export const fetchMrMyDoctors = async (id) => {
  try {
    const companyId = await fetchUserCompanyId(id);
    // Fetch selectedDoctor data
    const selectedDoctorsCollection = collection(db, "selectedDoctors");
    const q = query(selectedDoctorsCollection, where("selectedId", "==", id));
    const selectedSnapshot = await getDocs(q);
    const selectedDoctorIds = selectedSnapshot.docs.map((doc) => doc.data().doctorId);

    // Fetch meetings data for the current user
    const meetingsQuery = query(collection(db, "scheduleMeeting"), where("assigned", "==", id));
    const meetingsSnapshot = await getDocs(meetingsQuery);
    const meetingsDoctorIds = meetingsSnapshot.docs.map((doc) => doc.data().doctorID);

    // Fetch message data
    const messagesQuery = query(collection(db, "messages"), where("companyID", "==", companyId));
    const messagesSnapshot = await getDocs(messagesQuery);
    const messagesDoctorIds = messagesSnapshot.docs.map((doc) => doc.data().doctorID);

    // Combine doctor IDs from selectedDoctors, meetings, userMeetings, and messages, ensuring uniqueness
    const allRelevantDoctorIds = Array.from(new Set([
      ...selectedDoctorIds,
      ...meetingsDoctorIds,
      ...messagesDoctorIds
    ]));

    // Fetch doctor details
    const doctorsData = await Promise.all(
      allRelevantDoctorIds.map(async (doctorId) => {
        const doctorRef = doc(db, "doctors", doctorId);
        const doctorSnapshot = await getDoc(doctorRef);
        if (doctorSnapshot.exists()) {
          const data = doctorSnapshot.data();
          // Count meetings and messages for each doctor
          const meetingCount = meetingsDoctorIds.filter(id => id === doctorId).length;
          const messageCount = messagesDoctorIds.filter(id => id === doctorId).length;
          return {
            id: doctorSnapshot.id,
            ...data,
            name: data.name || "",
            specialist: data.specialist || "",
            location: data.location || "",
            meetingCount,
            messageCount,
          };
        }
        return null;
      })
    );

    // Filter out null values if any doctor data is missing
    return doctorsData.filter((doctor) => doctor !== null);
  } catch (error) {
    throw new Error("Error fetching doctors: " + error.message);
  }
}

//messages of my doctors
export const messageOfMrMyDoctors = async (id, doctorId) => {
  const companyId = await fetchUserCompanyId(id);
  const messagesQuery = query(collection(db, "messages"), where("companyID", "==", companyId), where("doctorID", "==", doctorId));
  const messagesSnapshot = await getDocs(messagesQuery);
  return messagesSnapshot;
}

//check if meeting is exists with another company
export const checkConflictMeetings = async(doctorId, companyId) => {
  if (!doctorId || !companyId) {
    console.error('Invalid parameters: doctorId or companyId is undefined');
    return {};
  }
  const meetingsRef = collection(db, "scheduleMeeting");
  const q = query(meetingsRef, where("doctorID", "==", doctorId));
  const querySnapshot = await getDocs(q);
  const conflictDates = {};

  querySnapshot.forEach((doc) => {
    const meetingData = doc.data();
    const meetingDate = meetingData.date; 
    const meetingTime = meetingData.time; 
    const meetingCompanyId = meetingData.companyID;

    if(meetingCompanyId !== companyId){
      if (!conflictDates[meetingDate]) {
        conflictDates[meetingDate] = [];
      }
      conflictDates[meetingDate].push(meetingTime);
    }

  });
  return conflictDates;
}

//schedule meeting for my doctors
export const meetingOfMrMyDoctor = async (selectedDoctor, selectedTime, selectedDate, id, meetingType) => {
  try {
    const companyId = await fetchUserCompanyId(id);
    const adjustedDate = new Date(selectedDate);
    const ISTOffset = 330;
    adjustedDate.setMinutes(adjustedDate.getMinutes() + ISTOffset);
    const formattedDate = adjustedDate.toISOString().split("T")[0];
    const selectedDateTime = new Date(`${formattedDate} ${selectedTime}`);
    if (selectedDateTime < new Date()) {
      throw new Error("You cannot schedule a meeting for a past date and time!");
    }

    // Generate a unique meeting ID
    let meetingId = uuid().slice(0, 4);
    const meetingsRef = collection(db, "scheduleMeeting");
    let idExists = true;

    while (idExists) {
      const q = query(meetingsRef, where("meetingId", "==", meetingId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        idExists = false;
      } else {
        meetingId = uuid().slice(0, 4); // Generate a new ID if the current one is taken
      }
    }

    const meetingLink = `https://meet.jit.si/${selectedDoctor.name.replace(/\s+/g, "")}-${id}-${Date.now()}`;
    
    const customId = `${selectedDoctor.id}_${id}`;
    const customDocRef = doc(db, "scheduleMeeting", customId);

    // Check if a meeting already exists
    const existingMeetingDoc = await getDoc(customDocRef);

    const scheduleData = {
      companyID: companyId || id,
      doctorID: selectedDoctor.id,
      date: formattedDate,
      time: selectedTime,
      meetingLink: meetingLink,
      meetingType: meetingType,
      status: "scheduled",
      assigned: id,
      meetingId: meetingId,
    };

    if (existingMeetingDoc.exists()) {
      const existingMeetingData = existingMeetingDoc.data();

      if (existingMeetingData.status === "completed") {
        // Create a new document for the completed meeting with a new ID
        const newCompletedDocRef = doc(db, "scheduleMeeting", `${customId}-${meetingId}`);
        await setDoc(newCompletedDocRef, existingMeetingData);

        await setDoc(customDocRef, scheduleData);
      } else {
        await setDoc(customDocRef, {
          ...existingMeetingData,
          date: formattedDate,
          time: selectedTime,
          meetingLink: meetingLink,
          meetingType: meetingType,
          status: "scheduled",
        });
      }
    } else {
      await setDoc(customDocRef, scheduleData);
    }

    return true;
  } catch (error) {
    throw new Error("Error scheduling meeting: " + error.message);
  }
}

//delete my doctors
export const deleteMrMyDoctors = async (id, doctorId) => {
  try {
    const companyId = await fetchUserCompanyId(id);
    const selectedDoctorsRef = collection(db, "selectedDoctors");
    const doctorQuery = query(selectedDoctorsRef, where("doctorId", "==", doctorId), where("companyId", "==", companyId));
    const querySnapshot = await getDocs(doctorQuery);

    if (querySnapshot.empty) {
      throw new Error("Doctor not found in selectedDoctors collection");
    }

    const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
    return true;

  } catch (error) {
    throw new Error("Error deleting doctor: " + error.message);
  }
}

// Timeline
export const convertTo24HourFormat = (timeStr) => {
  const [time, modifier] = timeStr.split(' ');
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

export const fetchTimelineMeetingsData = async (doctorId, id) => {
  try {
    const db = getFirestore();
    const meetingsQuery = query(collection(db, "scheduleMeeting"), where("assigned", "==", id), where("doctorID", "==", doctorId));
    const meetingsSnapshot = await getDocs(meetingsQuery);

    const fetchAssignedData = async (assigned) => {
      let assignedName;

      try {
        const companyDocRef = doc(db, "companies", assigned);
        const companyDocSnapshot = await getDoc(companyDocRef);

        if (companyDocSnapshot.exists()) {
          assignedName = companyDocSnapshot.data().name;
        } else {
          const userDocRef = doc(db, "users", assigned);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            assignedName = `${userData.firstName} ${userData.lastName}`;
          } else {
            console.error(`No document found with ID ${assigned}`);
          }
        }
      } catch (error) {
        console.error("Error fetching assigned data:", error);
      }

      return assignedName;
    };

    // Fetch doctor names and add details to meetings
    const fetchDoctorName = async (doctorID) => {
      const doctorDocRef = doc(db, "doctors", doctorID);
      const doctorDocSnapshot = await getDoc(doctorDocRef);
      return doctorDocSnapshot.exists()
        ? `${doctorDocSnapshot.data().name}`
        : "Unknown";
    };

    const meetingsData = await Promise.all(meetingsSnapshot.docs.map(async (doc) => {
      const data = doc.data();
      const assignedName = await fetchAssignedData(data.assigned);
      const doctorName = await fetchDoctorName(data.doctorID);

      // Convert time to 24-hour format
      const time24 = convertTo24HourFormat(data.time);

      // Combine date and time into a single Date object
      const meetingDateTime = new Date(`${data.date}T${time24}:00`);

      // Validate the meeting date
      if (isNaN(meetingDateTime)) {
        console.error("Invalid meeting date:", meetingDateTime);
        return null;
      }

      return {
        ...data,
        assignedName,
        doctorName,
        meetingDate: meetingDateTime, // Keep the Date object for sorting
        formattedDate: format(meetingDateTime, "dd/MM/yyyy"),
        formattedTime: format(meetingDateTime, "hh:mm a"),
        isPast: meetingDateTime < new Date()
      };
    }));

    // Filter out invalid meetings
    const validMeetingsData = meetingsData.filter(meeting => meeting !== null);

    // Sort meetings by the Date object
    validMeetingsData.sort((a, b) => a.meetingDate - b.meetingDate);

    return validMeetingsData;

  } catch (error) {
    throw new Error("Error fetching meetings: " + error.message);
  }
};

//function to fetch hospital
export const fetchHospitals = async () => {
  const hospitalsCollection = collection(db, "hospitals");
  const hospitalsSnapshot = await getDocs(hospitalsCollection);
  const hospitalsData = hospitalsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return hospitalsData
}

//function to fetch Doctors if not have hospital
export const fetchDoctorsWithoutHospitalId = async () => {
  try {
    const q = query(collection(db, "doctors"));
    const querySnapshot = await getDocs(q);
    const doctors = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(doctor => doctor.hospitalId === "N/A" || doctor.hospitalId === null || !("hospitalId" in doctor));
    
    return doctors;
  } catch (error) {
      throw new Error("Error fetching doctors: " + error.message);
  }
};

//function to fetch Doctors by hospitalId
export const fetchDoctorsByHospitalId = async (hospitalId) => {
  try {
    const q = query(collection(db, "doctors"), where("hospitalId", "==", hospitalId));
    const querySnapshot = await getDocs(q);
    const doctors = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return doctors;
  } catch (error) {
    throw new Error("Error fetching doctors: " + error.message);
  }
}

//fetch all doctors
export const fetchMrAllDoctors = async () => {
  // Fetch doctors
  const doctorsCollection = collection(db, "doctors");
  const doctorsSnapshot = await getDocs(doctorsCollection);
  const doctorsData = doctorsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // Fetch hospitals
  const hospitalsCollection = collection(db, "hospitals");
  const hospitalsSnapshot = await getDocs(hospitalsCollection);
  const hospitalsMap = hospitalsSnapshot.docs.reduce((acc, doc) => {
    const data = doc.data();
    acc[doc.id] = data.hospitalName;
    return acc;
  }, {});

  // Add hospital names to doctors data
  return doctorsData.map((doctor) => ({
    ...doctor,
    hospitalName: hospitalsMap[doctor.hospitalId] || "N/A",
  }));
}

//fetch selected Doctors
export const fetchSelectedMrDoctors = async (id) => {
  try {
    const selectedDoctorsCollection = collection(db, "selectedDoctors");
    const q = query(selectedDoctorsCollection, where("selectedId", "==", id));
    const selectedSnapshot = await getDocs(q);
    return selectedSnapshot.docs.map((doc) => doc.data().doctorId);
  } catch (error) {
    throw new Error("Error fetching selected doctors: " + error.message);
  }
};

//selection of doctors
export const toggleMrDoctorSelection = async (doctorId, id, companyId, selectedDoctors) => {
  try {
    const selectedDoctorsCollection = collection(db, "selectedDoctors");
    const docId = `${doctorId}_${id}`;
    const docRef = doc(selectedDoctorsCollection, docId);

    if (selectedDoctors.includes(doctorId)) {
      await deleteDoc(docRef);
      return { action: "removed", doctorId };
    } else {
      await setDoc(docRef, { doctorId, companyId, selectedId: id });
      return { action: "added", doctorId };
    }
  } catch (error) {
    throw new Error("Error updating selected doctors: " + error.message);
  }
};

//fetch Doctors Profile
export const fetchMrDoctorProfile = async (doctorId) => {
  try {
    const doctorRef = doc(db, "doctors", doctorId);
    const doctorDoc = await getDoc(doctorRef);
    if (doctorDoc.exists()) {
      const doctorData = doctorDoc.data();

      let hospitalName = "N/A";
      if (doctorData.hospitalId && doctorData.hospitalId !== "N/A") {
        const hospitalRef = doc(db, "hospitals", doctorData.hospitalId);
        const hospitalSnapshot = await getDoc(hospitalRef);
  
        if (hospitalSnapshot.exists()) {
          hospitalName = hospitalSnapshot.data().hospitalName;
        }
      }
      return {
        ...doctorData,
        hospitalName,
      };
    } else {
      console.log("Doctor not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching doctor profile: ", error.message);
    return null;
  }
}

//Book Schedule Meetings
export const bookMrMeeting = async (scheduleData) => {
  try {
    const { doctorId, companyId, selectedTime, formattedDate, id, doctorName, meetingType } = scheduleData;

    // Generate a unique meeting ID
    let meetingId = uuid().slice(0, 4);
    const meetingsRef = collection(db, "scheduleMeeting");
    let idExists = true;

    while (idExists) {
      const q = query(meetingsRef, where("meetingId", "==", meetingId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        idExists = false;
      } else {
        meetingId = uuid().slice(0, 4); // Generate a new ID if the current one is taken
      }
    }

    const meetingLink = `https://meet.jit.si/${doctorName.replace(/\s+/g, "")}-${id}-${Date.now()}`;

    const schedule = {
      companyID: companyId || id,
      doctorID: doctorId,
      date: formattedDate,
      time: selectedTime,
      meetingLink: meetingLink,
      status: "scheduled",
      assigned: id,
      meetingType: meetingType,
      meetingId: meetingId,
    };

    const customId = `${doctorId}_${id}`;
    const customDocRef = doc(db, "scheduleMeeting", customId);

    const docSnapshot = await getDoc(customDocRef);

    if (docSnapshot.exists()) {
      const existingMeeting = docSnapshot.data();

      if (existingMeeting.status === "completed") {
        // Create a new document for the completed meeting with a new ID
        const newCompletedDocRef = doc(db, "scheduleMeeting", `${customId}-${meetingId}`);
        await setDoc(newCompletedDocRef, existingMeeting);

        await setDoc(customDocRef, schedule);
      } else {
        await setDoc(customDocRef, {
          ...existingMeeting,
          date: formattedDate,
          time: selectedTime,
          meetingLink: meetingLink,
          meetingType: meetingType,
          status: "scheduled",
        });
      }
    } else {
      await setDoc(customDocRef, schedule);
    }

    return meetingLink;
  } catch (error) {
    console.error("Error scheduling meeting:", error);
    throw error;
  }
}

//send Messages
export const sendMrMessages = async (messageData) => {
  try {
    const { doctorId, companyId, message, id } = messageData;

    const messagePayload = {
      companyID: companyId || id,
      doctorID: doctorId,
      messages: message,
      sentBy: "company",
      timestamp: new Date(),
      messageId: id,
      sentId: id,
    };

    const customId = `${doctorId}_${id}_${Date.now()}`;
    const customDocRef = doc(db, "messages", customId);
    await setDoc(customDocRef, messagePayload);
  } catch (error) {
    console.error("Error sending messages:", error);
    throw error;
  }
}

//fetch meetings chart
export const fetchMeetingsChartData = async (id) => {
  try {
    const userDocRef = doc(db, "users", id);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const users = userDocSnapshot.data();

      const companyId = users.companyId;

      const scheduleMeetingsRef = collection(db, "scheduleMeeting");
      const q = query(scheduleMeetingsRef, where("companyID", "==", companyId));
      const querySnapshot = await getDocs(q);

      const meetingsByMonth = {};
      const missedMeetingsByMonth = {};

      querySnapshot.forEach((doc) => {
        const meetingData = doc.data();
        const meetingDate = new Date(meetingData.date);
        const month = meetingDate.toLocaleString("default", { month: "short" });
        const year = meetingDate.getFullYear();
        const key = `${month} ${year}`;

        if (!meetingsByMonth[key]) {
          meetingsByMonth[key] = 0;
        }
        meetingsByMonth[key]++;

        // Check for missed meetings
        const now = new Date();
        if (meetingDate < now && meetingData.status !== "completed") {
          if (!missedMeetingsByMonth[key]) {
            missedMeetingsByMonth[key] = 0;
          }
          missedMeetingsByMonth[key]++;
        }
      });

      
      return {meetingsByMonth, missedMeetingsByMonth};
    }
  } catch (error) {
    console.error("Error fetching meeting data:", error);
  }
}

//fetch assgined users chart
export const fetchAssignedUsersChart = async (id) => {
  try {
    const userDocRef = doc(db, "users", id);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const users = userDocSnapshot.data();

      const companyId = users.companyId;

      const scheduleMeetingsRef = collection(db, "scheduleMeeting");
      const q = query(scheduleMeetingsRef, where("companyID", "==", companyId));
      const querySnapshot = await getDocs(q);

      const assignedUsersCount = {};
      querySnapshot.forEach((doc) => {
        const meetingData = doc.data();
        const assigned = meetingData.assigned;

        if (!assignedUsersCount[assigned]) {
          assignedUsersCount[assigned] = 0;
        }

        assignedUsersCount[assigned]++;
      });

      const userPromises = Object.keys(assignedUsersCount).map(
        async (userId) => {
          let assignedName = "";
          let assignedRole = "";

          try {
            const companyDocRef = doc(db, "companies", userId);
            const companyDocSnapshot = await getDoc(companyDocRef);

            if (companyDocSnapshot.exists()) {
              assignedName = companyDocSnapshot.data().name;
              assignedRole = companyDocSnapshot.data().role;
            } else {
              const userDocRef = doc(db, "users", userId);
              const userDocSnapshot = await getDoc(userDocRef);

              if (userDocSnapshot.exists()) {
                const userData = userDocSnapshot.data();
                assignedName = `${userData.firstName} ${userData.lastName}`;
                assignedRole = userData.role;
              } else {
                console.error(`No document found with ID ${userId}`);
              }
            }
          } catch (error) {
            console.error("Error fetching assigned data:", error);
          }

          return {
            userId,
            assignedName,
            assignedRole,
            count: assignedUsersCount[userId],
          };
        }
      );

      return Promise.all(userPromises);
    }
  } catch (error) {
    console.error("Error fetching assigned users data:", error);
  }
}

//update password
export const updateMrPassword = async (user, oldPassword, newPassword) => {
  try {
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      return true;
  } catch (error) {
      console.error("Error updating password:", error);
      return false;
  }
};

//check if meeting exist for doctor
export const checkIfMeetingExists = async (doctorId, companyId, id) => {
  const meetingsCollection = collection(db, "scheduleMeeting");
  const now = new Date();
  const q = query(meetingsCollection, where("companyID", "==", companyId), where("doctorID", "==", doctorId), where("assigned", '==', id));
  const querySnapshot = await getDocs(q);

  const meetings = querySnapshot.docs
    .map((doc) => ({ id: doc.id, ...doc.data() }))
    .filter((meeting) => {
      const meetingDateTime = new Date(`${meeting.date} ${meeting.time}`);
      return meetingDateTime > now && meeting.status !== "completed";
    });

  return meetings.length > 0 ? meetings : false;
}

export const updateMeetingStatus = async (
  selectedMeetingId,
  status,
  mrFeedback
) => {
  const meetingDocRef = doc(db, "scheduleMeeting", selectedMeetingId);
  const updateData = {
    status: status,
  };

  if (status === "completed") {
    updateData.mrFeedback = mrFeedback;
  }
  await updateDoc(meetingDocRef, updateData);
};