import React, { useState, useEffect } from "react";
import AdminSide from "./AdminSide";
import { Navigate, useNavigate } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import { FaUserDoctor } from "react-icons/fa6";
import { FaCompactDisc, FaHospital } from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md";
import { AiFillMessage } from "react-icons/ai";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { useAuth } from "../AuthContext";
import { fetchCompanies, fetchManager, fetchHospitals, fetchDoctors, fetchMeetings, fetchMessages, fetchRecentDoctors, fetchRecentCompanies } from "./AdminFirebase";

const AdminDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [totalDoctors, setTotalDoctors] = useState(0);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [totalHospitals, setTotalHospitals] = useState(0);
  const [totalMangers, setTotalManagers] = useState(0);
  const [upcomingMeetings, setUpcomingMeetings] = useState(0);
  const [missedMeetings, setMissedMeetings] = useState(0);
  const [completedMeetings, setCompletedMeetings] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [recentDoctors, setRecentDoctors] = useState([]);
  const [recentCompanies, setRecentCompaniess] = useState([]);
  const { isAdminLoggedIn } = useAuth();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      //recent Doctors
      const recentDoctorsData = await fetchRecentDoctors();
      setRecentDoctors(recentDoctorsData);

      //recent doctors 
      const recentCompaniessData = await fetchRecentCompanies();
      setRecentCompaniess(recentCompaniessData);

      //total doctors
      const doctorsData = await fetchDoctors();
      setTotalDoctors(doctorsData.length);

      //total companies
      const companiesData = await fetchCompanies();
      setTotalCompanies(companiesData.length);

      const hospitalsData = await fetchHospitals();
      setTotalHospitals(hospitalsData.length);

      //total growth managers
      const managersData = await fetchManager();
      setTotalManagers(managersData.length);

      //meetings count
      const upcomingMeetings = await fetchMeetings('', 'upcoming');
      const missedMeetings = await fetchMeetings('', 'missed');
      const completedMeetings = await fetchMeetings('', 'completed');

      setUpcomingMeetings(upcomingMeetings.length);
      setMissedMeetings(missedMeetings.length);
      setCompletedMeetings(completedMeetings.length);

      //total messages
      const sortedMessages = await fetchMessages();
      setTotalMessages(sortedMessages.length);
    }

    fetchData();
  }, []);

  const doctors = () => {
    navigate('/admin/doctors');
  }

  const companies = () => {
    navigate('/admin/companies');
  };

  const hospitals = () => {
    navigate('/admin/hospital');
  }

  const managers = () => {
    navigate('/admin/manager');
  };

  const upcoming = () => {
    navigate('/admin/Schedule');
  };

  const missed = () => {
    navigate('/admin/missedMeeting');
  };

  const completed = () => {
    navigate('/admin/completedMeeting');
  };

  const messages = () => {
    navigate('/admin/messages');
  };


  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container px-4 mx-auto my-4">
            <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">

              <div className="bg-gray-100 border shadow-sm rounded p-5" onClick={doctors}>
                <div className="flex space-x-4 items-center">
                  <div>
                    <div className="bg-white rounded-full w-12 h-12 text-rose-300 flex justify-center items-center">
                      <FaUserDoctor className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <div className="">Total Doctors</div>
                    <div className="text-2xl font-bold text-gray-900">
                      {totalDoctors}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-100 border shadow-sm rounded p-5" onClick={hospitals}>
                <div className="flex space-x-4 items-center">
                  <div>
                    <div className="bg-white rounded-full w-12 h-12 text-rose-300 flex justify-center items-center">
                      <FaHospital className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <div className="">Total Hospitals</div>
                    <div className="text-2xl font-bold text-gray-900">
                      {totalHospitals}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-100 border shadow-sm rounded p-5" onClick={companies}>
                <div className="flex space-x-4 items-center">
                  <div>
                    <div className="bg-white rounded-full w-12 h-12 text-rose-300 flex justify-center items-center">
                      <FaCompactDisc className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <div className="">Total Companies</div>
                    <div className="text-2xl font-bold text-gray-900">
                      {totalCompanies}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-100 border shadow-sm rounded p-5" onClick={managers}>
                <div className="flex space-x-4 items-center">
                  <div>
                    <div className="bg-white rounded-full w-12 h-12 text-rose-300 flex justify-center items-center">
                      <MdManageAccounts className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <div className="">Growth Managers</div>
                    <div className="text-2xl font-bold text-gray-900">
                      {totalMangers}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-100 border shadow-sm rounded p-5" onClick={upcoming}>
                <div className="flex space-x-4 items-center">
                  <div>
                    <div className="bg-white rounded-full w-12 h-12 text-rose-300 flex justify-center items-center">
                      <RiCalendarScheduleLine className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <div className="">Upcoming Meetings</div>
                    <div className="text-2xl font-bold text-gray-900">
                      {upcomingMeetings}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-100 border shadow-sm rounded p-5" onClick={missed}>
                <div className="flex space-x-4 items-center">
                  <div>
                    <div className="bg-white rounded-full w-12 h-12 text-rose-300 flex justify-center items-center">
                      <RiCalendarScheduleLine className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <div className="">Missed Meetings</div>
                    <div className="text-2xl font-bold text-gray-900">
                      {missedMeetings}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-100 border shadow-sm rounded p-5" onClick={completed}>
                <div className="flex space-x-4 items-center">
                  <div>
                    <div className="bg-white rounded-full w-12 h-12 text-rose-300 flex justify-center items-center">
                      <RiCalendarScheduleLine className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <div className="">Completed Meetings</div>
                    <div className="text-2xl font-bold text-gray-900">
                      {completedMeetings}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-100 border shadow-sm rounded p-5" onClick={messages}>
                <div className="flex space-x-4 items-center">
                  <div>
                    <div className="bg-white rounded-full w-12 h-12 text-rose-300 flex justify-center items-center">
                      <AiFillMessage className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <div className="">Messages</div>
                    <div className="text-2xl font-bold text-gray-900">
                      {totalMessages}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
