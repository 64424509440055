import React, { useState, useEffect } from "react";
import DoctorNavbar from "./DoctorNavbar";
import DoctorSide from "./DoctorSide";
import { AiFillMessage } from "react-icons/ai";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { FaChartLine } from "react-icons/fa6";
import { PiChartPieSliceFill } from "react-icons/pi";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { fetchMessages, fetchMeetingsData, fetchMeetingsChart } from "./DoctorFirebase";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const DoctorDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const [scheduleMeetings, setScheduleMeetings] = useState([]);
  const [chartData, setChartData] = useState({});
  const [projectedEarningsData, setProjectedEarningsData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const loadMessages = async () => {
      try {
        const { sortedMessages } = await fetchMessages(id);
        const sorted = sortedMessages.slice(0, 4);
        setMessages(sorted);
      } catch (error) {
        console.error("Error loading messages:", error);
      }
    };

    const fetchScheduleMeetings = async () => {
      try {
        const { upcomingMeetings } = await fetchMeetingsData(id);
        const resolvedData = upcomingMeetings.slice(0, 4);
        setScheduleMeetings(resolvedData);
      } catch (error) {
        console.error("Error fetching schedule meetings:", error);
      }
    };

    const fetchMeetings = async () => {
      try {
        const { meetingsByMonth, missedMeetingsByMonth, completedMeetingsByMonth } = await fetchMeetingsChart(id);
        const currentDate = new Date();
        const months = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        // Calculate the range: previous month, current month, and next 4 months
        const desiredMonths = [];
        for (let i = -1; i < 5; i++) {
          const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
          const month = date.toLocaleString("default", { month: "short" });
          const year = date.getFullYear();
          desiredMonths.push(`${month} ${year}`);
        }

        const meetingCounts = desiredMonths.map(
          (label) => meetingsByMonth[label] || 0
        );

        const missedMeetingCounts = desiredMonths.map(
          (label) => missedMeetingsByMonth[label] || 0
        );
        const completedMeetingCounts = desiredMonths.map(
          (label) => completedMeetingsByMonth[label]
        );

        const updatedChartData = {
          labels: desiredMonths,
          datasets: [
            {
              label: "Total Meetings",
              data: meetingCounts,
              fill: false,
              borderColor: "rgba(119,150,230,1)",
              borderWidth: 2,
              tension: 0.1,
              pointStyle: "rectRounded",
              pointBorderWidth: 2,
            },
            {
              label: "Missed Meetings",
              data: missedMeetingCounts,
              fill: false,
              borderColor: "rgba(255,99,132,1)",
              borderWidth: 2,
              tension: 0.1,
              pointStyle: "rectRounded",
              pointBorderWidth: 2,
            },
          ],
        };

        // New chart data for Projected Earning vs. Final Earning
        const projectedEarnings = desiredMonths.map((label) => {
          const meetingsCount = meetingsByMonth[label];
          return 150 * (meetingsCount || 0);
        });
        const finalEarnings = desiredMonths.map((label) => {
          const completedCount = completedMeetingsByMonth[label];
          return 150 * (completedCount || 0);
        });

        const updatedProjectedEarningsData = {
          labels: desiredMonths,
          datasets: [
            {
              label: "Projected Earning",
              data: projectedEarnings,
              borderColor: "#0D47A1",
              backgroundColor: "rgba(134, 151, 196, 0.2)",
              borderWidth: 2,
              fill: true,
            },
            {
              label: "Final Earning",
              data: finalEarnings,
              borderColor: "#4CAF50",
              backgroundColor: "rgba(119,150,230,0.2)",
              borderWidth: 2,
              fill: true,
            },
          ],
        };
        setChartData(updatedChartData);
        setProjectedEarningsData(updatedProjectedEarningsData);
      } catch (error) {
        console.error("Error fetching schedule meetings:", error);
      }
    };

    loadMessages();
    fetchScheduleMeetings();
    fetchMeetings();
  }, [id]);

  const handleMessageClick = async (companyId) => {
    navigate(`/doctor/message/${id}`, { state: { companyId } });
  };

  const handlemeetings = () => {
    navigate(`/doctor/meetingsinfo/${id}`);
  }

  return (
    <div className="flex flex-col h-screen">
      <DoctorNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <DoctorSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container px-4 mx-auto my-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="bg-white p-2 rounded shadow-md">
              <div className="bg-[#0D47A1] text-white p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <AiFillMessage className="mr-2" /> Recent Messages
                </h2>
              </div>
              {messages.length === 0 ? (
                <div
                  className="px-4 border-b my-2 shadow-lg cursor-pointer hover:bg-gray-100"
                  style={{ background: "white" }}
                >
                  No messages found !
                </div>
              ) : (
                messages.map((message, index) => (
                  <div
                    key={index}
                    onClick={() => handleMessageClick(message.companyID)}
                  >
                    <div
                      className="px-4 border-b my-2 shadow-lg cursor-pointer hover:bg-gray-100"
                      style={{ background: "white" }}
                    >
                      <div className="flex justify-between items-center">
                        <div>
                          <div className="font-bold text-gray-700">
                            {message.representativeName}
                          </div>
                          <div className="text-sm font-bold text-gray-600 mt-1">
                            {message.recentMessage.text}
                          </div>
                        </div>
                        <div className="text-xs text-gray-400 text-right">
                          <div>{message.recentMessage.date}</div>
                          <div>{message.recentMessage.time}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="bg-white p-2 rounded shadow-md">
              <div className="bg-[#0D47A1] text-white p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <RiCalendarScheduleLine className="mr-2" /> Upcoming Schedule
                </h2>
              </div>
              <div className="overflow-auto shadow-md sm:rounded-lg mt-3 table-container">
                <table className="divide-y border divide-gray-300 w-full text-left rtl:text-right">
                  <thead className="text-sm text-gray-700 uppercase ">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-2 tracking-wider bg-gray-50"
                      >
                        S.N.
                      </th>
                      <th scope="col" className="px-3 py-2 tracking-wider">
                        Company Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 tracking-wider bg-gray-50"
                      >
                        Representative Name
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scheduleMeetings.length === 0 ? (
                      <tr className="bg-white border-b dark:border-gray-200">
                        <td colSpan="3" className="text-center py-2">
                          <p className="text-lg">No Schedule meetings.</p>
                        </td>
                      </tr>
                    ) : (
                      scheduleMeetings.map((meeting, index) => (
                        <tr
                          key={index}
                          onClick={handlemeetings}
                          className="bg-white border-b dark:border-gray-200 cursor-pointer"
                        >
                          <td
                            scope="row"
                            className="px-2 py-2 bg-gray-50 text-center"
                          >
                            {index + 1}.
                          </td>
                          <td className="px-2 py-2 font-medium">
                            {meeting.companyName}
                          </td>
                          <td className="px-2 py-2 bg-gray-50">
                            {meeting.assignedName}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="bg-white p-2 rounded shadow-md">
              <div className="bg-[#0D47A1] text-white p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <FaChartLine className="mr-2" /> Meetings
                </h2>
              </div>
              {Object.keys(chartData).length > 0 &&
                <Line
                  data={chartData}
                  options={{
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: 'Month',
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: 'Meetings',
                        },
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              }
            </div>

            <div className="bg-white p-2 rounded shadow-md">
              <div className="bg-[#0D47A1] text-white p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <PiChartPieSliceFill className="mr-2" /> Earning
                </h2>
              </div>
              {/* Update the chart here */}
              {Object.keys(projectedEarningsData).length > 0 && (
                <Line
                  data={projectedEarningsData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: true,
                        position: 'top',
                      },
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            return `${context.dataset.label}: ₹${context.raw}`;
                          },
                        },
                      },
                    },
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: 'Month',
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: 'Earnings (₹)',
                        },
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              )}

            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorDashboard;
