import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { HiUsers } from "react-icons/hi2";
import { auth } from "../firebase";
import HospitalNavbar from "./HospitalNavbar";
import HospitalSide from "./HospitalSide";
import { FaEdit, FaCheck } from "react-icons/fa";
import { SiGooglemeet } from "react-icons/si";
import { MdAutoDelete } from "react-icons/md";
import { fetchHospitalMeeting } from "./HospitalFirebase";
import { format } from "date-fns";

const HospitalDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [missedMeetings, setMissedMeetings] = useState([]);
  const [completedMeetings, setCompletedMeetings] = useState([]);
  const [tab, setTab] = useState("upcoming");
  const { id } = useParams();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        const { upcomingMeetings, missedMeetings, completedMeetings } =
          await fetchHospitalMeeting(id);
        setUpcomingMeetings(upcomingMeetings);
        setMissedMeetings(missedMeetings);
        setCompletedMeetings(completedMeetings);
      } catch (error) {
        console.error("Error fetching meetings:", error);
      }
    };

    fetchMeetings();
  }, [id]);

  return (
    <div className="flex flex-col h-screen">
      <HospitalNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <HospitalSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          <div className="container px-4 mx-auto my-4">
            <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
              <div className="bg-[#3D52A1] text-white border shadow-sm rounded p-5">
                <div className="flex space-x-4 items-center my-2">
                  <div>
                    <div className="bg-[#314281] rounded-full w-12 h-12 flex justify-center items-center">
                      <HiUsers className="h-4 w-4 cursor-pointer" />
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <div className="font-bold">Online Appointment</div>
                    <div className="">450</div>
                  </div>
                </div>
              </div>

              <div className="bg-[#3D52A1] text-white border shadow-sm rounded p-5">
                <div className="flex space-x-4 items-center my-2">
                  <div>
                    <div className="bg-[#314281] rounded-full w-12 h-12 flex justify-center items-center">
                      <FaUser className="h-4 w-4 cursor-pointer" />
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <div className="font-bold">Offline Appointment</div>
                    <div className="">450</div>
                  </div>
                </div>
              </div>

              <div className="bg-[#3D52A1] text-white border shadow-sm rounded p-5">
                <div className="flex space-x-4 items-center my-2">
                  <div>
                    <div className="bg-[#314281] rounded-full w-12 h-12 flex justify-center items-center">
                      <FaUser className="h-4 w-4 cursor-pointer" />
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <div className="font-bold">New Requests</div>
                    <div className="">450</div>
                  </div>
                </div>
              </div>

              <div className="bg-[#3D52A1] text-white border shadow-sm rounded p-5">
                <div className="flex space-x-4 items-center my-2">
                  <div>
                    <div className="bg-[#314281] rounded-full w-12 h-12 flex justify-center items-center">
                      <FaUser className="h-4 w-4 cursor-pointer" />
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <div className="font-bold">Hospital Earnings</div>
                    <div className="">14,678,000</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-auto mt-5">
              <div className="flex justify-between items-center my-3">
                <div>
                  <button
                    onClick={() => setTab("upcoming")}
                    className={`px-4 py-2 font-semibold ${
                      tab === "upcoming"
                        ? "bg-[#0D47A1] text-white"
                        : "bg-gray-100"
                    } border-r`}
                  >
                    Upcoming
                  </button>
                  <button
                    onClick={() => setTab("missed")}
                    className={`px-4 py-2 font-semibold ${
                      tab === "missed"
                        ? "bg-[#0D47A1] text-white"
                        : "bg-gray-100"
                    } border-r`}
                  >
                    Missed
                  </button>
                  <button
                    onClick={() => setTab("completed")}
                    className={`px-4 py-2 font-semibold ${
                      tab === "completed"
                        ? "bg-[#0D47A1] text-white"
                        : "bg-gray-100"
                    }`}
                  >
                    Completed
                  </button>
                </div>
              </div>

              <div className="mt-5">
                {tab === "upcoming" && (
                  <div className="overflow-auto shadow-md sm:rounded-lg mt-3 table-container">
                    <table className="divide-y border divide-gray-300 w-full text-left rtl:text-right">
                      <thead className="text-sm text-gray-700 uppercase">
                        <tr>
                          <th
                            scope="col"
                            className="px-2 py-3 tracking-wider bg-gray-50"
                          >
                            S.N.
                          </th>
                          <th scope="col" className="px-6 py-3 tracking-wider">
                            Doctor Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 tracking-wider bg-gray-50"
                          >
                            Company Name
                          </th>
                          <th scope="col" className="px-6 py-3 tracking-wider">
                            Assigned
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 tracking-wider bg-gray-50"
                          >
                            Date
                          </th>
                          <th scope="col" className="px-6 py-3 tracking-wider">
                            Time
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 tracking-wider bg-gray-50"
                          >
                            Location
                          </th>
                          <th scope="col" className="px-6 py-3 tracking-wider">
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 tracking-wider bg-gray-50"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {upcomingMeetings.length === 0 ? (
                          <tr className="bg-white border-b">
                            <td colSpan="9" className="text-center py-4">
                              <p className="text-lg">No Upcoming meetings.</p>
                            </td>
                          </tr>
                        ) : (
                          upcomingMeetings.map((meeting, index) => (
                            <tr key={meeting.id} className="bg-white border-b">
                              <td
                                scope="row"
                                className="px-2 py-3 bg-gray-50 text-center font-medium"
                              >
                                {index + 1}.
                              </td>
                              <td className="px-2 py-3 font-medium">
                                {meeting.doctorName}
                              </td>
                              <td className="px-2 py-3 bg-gray-50">
                                {meeting.companyName}
                              </td>
                              <td className="px-2 py-3 font-medium">
                                {meeting.assignedName}
                              </td>
                              <td className="px-2 py-3 bg-gray-50">
                                {format(new Date(meeting.date), "dd/MM/yyyy")}
                              </td>
                              <td className="px-2 py-3 font-medium">
                                {meeting.time}
                              </td>
                              <td className="px-2 py-3 bg-gray-50">
                                {meeting.location}
                              </td>
                              <td className="px-2 py-3 font-medium capitalize">
                                {meeting.status}
                                {meeting.meetingType === "online" ? (
                                  <p>(Virtual)</p>
                                ) : (
                                  <p>({meeting.meetingType})</p>
                                )}
                              </td>
                              <td className="px-2 py-3 bg-gray-50">
                                <div className="flex space-x-2">
                                  <button
                                    // onClick={() => toggleCalendar(meeting.id)}
                                    className="text-white bg-[#0D47A1] rounded-lg px-2 py-2 text-center"
                                  >
                                    <FaEdit />
                                  </button>
                                  {meeting.status !== "accepted" &&
                                  meeting.status !== "Rescheduled" ? (
                                    <button
                                      // onClick={() => handleAccept(meeting.id)}
                                      type="button"
                                      className="text-white bg-[#0D47A1] rounded-lg px-2 py-2 text-center"
                                    >
                                      <FaCheck />
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="text-white bg-gray-400 rounded-lg px-2 py-2 text-center cursor-not-allowed"
                                      disabled
                                    >
                                      <FaCheck />
                                    </button>
                                  )}
                                  {meeting.meetingType !== "offline" ? (
                                    <button className="text-white bg-[#0D47A1] rounded-lg px-2 py-2 text-center">
                                      <Link
                                        to={meeting.meetingLink}
                                        target="_blank"
                                      >
                                        <SiGooglemeet />
                                      </Link>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="text-white bg-gray-400 rounded-lg px-2 py-2 text-center cursor-not-allowed"
                                      disabled
                                    >
                                      <SiGooglemeet />
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                )}

                {tab === "missed" && (
                  <div className="overflow-auto shadow-md sm:rounded-lg mt-3 table-container">
                    <table className="divide-y border divide-gray-300 w-full text-left rtl:text-right">
                      <thead className="text-sm text-gray-700 uppercase ">
                        <tr>
                          <th
                            scope="col"
                            className="px-2 py-3 tracking-wider bg-gray-50"
                          >
                            S.N.
                          </th>
                          <th scope="col" className="px-6 py-3 tracking-wider">
                            Doctor Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 tracking-wider bg-gray-50"
                          >
                            Company Name
                          </th>
                          <th scope="col" className="px-6 py-3 tracking-wider">
                            Assigned
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 tracking-wider bg-gray-50"
                          >
                            Role
                          </th>
                          <th scope="col" className="px-6 py-3 tracking-wider">
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 tracking-wider bg-gray-50"
                          >
                            Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {missedMeetings.length === 0 ? (
                          <tr className="bg-white border-b">
                            <td colSpan="7" className="text-center py-4">
                              <p className="text-lg">No Missed meetings.</p>
                            </td>
                          </tr>
                        ) : (
                          missedMeetings.map((meeting, index) => (
                            <tr key={meeting.id} className="bg-white border-b">
                              <td
                                scope="row"
                                className="px-2 py-3 bg-gray-50 text-center font-medium"
                              >
                                {index + 1}.
                              </td>
                              <td className="px-4 py-3 font-medium">
                                {meeting.doctorName}
                              </td>
                              <td className="px-4 py-3 bg-gray-50">
                                {meeting.companyName}
                              </td>
                              <td className="px-4 py-3 font-medium">
                                {meeting.assignedName}
                              </td>
                              <td className="px-4 py-3 bg-gray-50">
                                {meeting.assignedRole}
                              </td>
                              <td className="px-4 py-3 font-medium">
                                {format(new Date(meeting.date), "dd/MM/yyyy")}
                              </td>
                              <td className="px-4 py-3 bg-gray-50">
                                {meeting.time}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                )}

                {tab === "completed" && (
                  <div className="overflow-auto shadow-md sm:rounded-lg mt-3 table-container">
                    <table className="divide-y border divide-gray-300 w-full text-left rtl:text-right">
                      <thead className="text-sm text-gray-700 uppercase">
                        <tr>
                          <th
                            scope="col"
                            className="px-2 py-3 tracking-wider bg-gray-50"
                          >
                            S.N.
                          </th>
                          <th scope="col" className="px-6 py-3 tracking-wider">
                            Doctor Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 tracking-wider bg-gray-50"
                          >
                            Company Name
                          </th>
                          <th scope="col" className="px-6 py-3 tracking-wider">
                            Assigned
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 tracking-wider bg-gray-50"
                          >
                            Role
                          </th>
                          <th scope="col" className="px-6 py-3 tracking-wider">
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 tracking-wider bg-gray-50"
                          >
                            Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {completedMeetings.length === 0 ? (
                          <tr className="bg-white border-b">
                            <td colSpan="7" className="text-center py-4">
                              <p className="text-lg">No completed meetings.</p>
                            </td>
                          </tr>
                        ) : (
                          completedMeetings.map((meeting, index) => (
                            <tr key={meeting.id} className="bg-white border-b">
                              <td
                                scope="row"
                                className="px-2 py-3 bg-gray-50 text-center font-medium"
                              >
                                {index + 1}.
                              </td>
                              <td className="px-4 py-3 font-medium">
                                {meeting.doctorName}
                              </td>
                              <td className="px-4 py-3 bg-gray-50">
                                {meeting.companyName}
                              </td>
                              <td className="px-4 py-3 font-medium">
                                {meeting.assignedName}
                              </td>
                              <td className="px-4 py-3 bg-gray-50">
                                {meeting.assignedRole}
                              </td>
                              <td className="px-4 py-3 font-medium">
                                {format(new Date(meeting.date), "dd/MM/yyyy")}
                              </td>
                              <td className="px-4 py-3 bg-gray-50">
                                {meeting.time}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalDashboard;
