import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  setDoc,
  orderBy,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth, db, storage } from "../firebase";
import { format } from "date-fns";


//function to fetch managers
export const fetchManager = async () => {
  try {
    const managersCollection = collection(db, "managers");
    const snapshot = await getDocs(managersCollection);
    const managersData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return managersData;
  } catch (error) {
    throw new Error("Error fetching managers: " + error.message);
  }
};

// Function to delete a managers
export const deleteManager = async (managerId) => {
  try {
    await deleteDoc(doc(db, "managers", managerId));
  } catch (error) {
    throw new Error("Error deleting managers profile: " + error.message);
  }
};

//function to fetch doctors
export const fetchHospitals = async () => {
  try {
    const hospitalsCollection = collection(db, "hospitals");
    const snapshot = await getDocs(hospitalsCollection);
    const hospitalsData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return hospitalsData;
  } catch (error) {
    throw new Error("Error fetching hospitals: " + error.message);
  }
};

// Function to delete hospital
export const deleteHospital = async (hospitalId) => {
  try {
    await deleteDoc(doc(db, "hospitals", hospitalId));
  } catch (error) {
    throw new Error("Error deleting hospital profile: " + error.message);
  }
};

//function to fetch doctors
export const fetchDoctors = async () => {
  // Fetch doctors
  const doctorsCollection = collection(db, "doctors");
  const doctorsSnapshot = await getDocs(doctorsCollection);
  const doctorsData = doctorsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

  // Fetch hospitals
  const hospitalsCollection = collection(db, "hospitals");
  const hospitalsSnapshot = await getDocs(hospitalsCollection);
  const hospitalsMap = hospitalsSnapshot.docs.reduce((acc, doc) => {
    const data = doc.data();
    acc[doc.id] = data.hospitalName;
    return acc;
  }, {});

  // Add hospital names to doctors data
  return doctorsData.map(doctor => ({
    ...doctor,
    hospitalName: hospitalsMap[doctor.hospitalId] || "N/A"
  }));
};

// Function to delete a doctor
export const deleteDoctor = async (doctorId) => {
  try {
    await deleteDoc(doc(db, "doctors", doctorId));
  } catch (error) {
    throw new Error("Error deleting doctor profile: " + error.message);
  }
};

//function to fetch companies
export const fetchCompanies = async () => {
  try {
    const companiesCollection = collection(db, "companies");
    const snapshot = await getDocs(companiesCollection);
    const companiesData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return companiesData;
  } catch (error) {
    throw new Error("Error fetching companies: " + error.message);
  }
};

// Function to delete a companies
export const deleteCompanies = async (companyId) => {
  try {
    await deleteDoc(doc(db, "companies", companyId));
  } catch (error) {
    throw new Error("Error deleting companies profile: " + error.message);
  }
};

//add hospital
export const addHospital = async (hospitalData) => {
  const customId = `${hospitalData.name}_${hospitalData.hospitalName}`;
  const customDocRef = doc(db, "hospitals", customId);

  try {
    await setDoc(customDocRef, {
      ...hospitalData,
      role: "Hospital",
      profileComplete: true,
    });
    return true;
  } catch (error) {
    console.error("Error adding document :", error);
    throw error;
  }
};

//add growth manager
export const addManager = async (email, password, managerData) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    await sendEmailVerification(user);

    const customId = `${managerData.name}`;
    const customDocRef = doc(db, "managers", customId);
    await setDoc(customDocRef, { ...managerData });
    return true;
  } catch (error) {
    console.error("Error adding document :", error);
    throw error;
  }
};

//add Company
export const addCompany = async (companyData) => {
  const customId = `${companyData.name}_${companyData.companyName}`;
  const customDocRef = doc(db, "companies", customId);

  try {
    await setDoc(customDocRef, {
      ...companyData,
      role: "Company",
      profileComplete: true,
    });
    return true;
  } catch (error) {
    console.error("Error adding document :", error);
    throw error;
  }
};

//upload company Image
export const uploadImage = async (file) => {
  const storageRef = ref(storage, `images/companies/${file.name}`);
  try {
    const uploadTaskSnapshot = await uploadBytes(storageRef, file);

    const url = await getDownloadURL(uploadTaskSnapshot.ref);

    return url;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

//add Doctor
export const addDoctor = async (doctorData) => {
  const customId = `${doctorData.name}_${doctorData.location}`;
  const customDocRef = doc(db, "doctors", customId);

  try {
    await setDoc(customDocRef, {
      ...doctorData,
      role: "Doctor",
      profileComplete: true,
    });
    return true;
  } catch (error) {
    console.error("Error adding document :", error);
    throw error;
  }
};

//upload company Image
export const uploadDoctorImage = async (file) => {
  const storageRef = ref(storage, `images/doctors/${file.name}`);
  try {
    const uploadTaskSnapshot = await uploadBytes(storageRef, file);

    const url = await getDownloadURL(uploadTaskSnapshot.ref);

    return url;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

// Fetch manager data by ID
export const fetchManagerByID = async (id) => {
  try {
    const managerRef = doc(db, "managers", id);
    const docSnap = await getDoc(managerRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return { id: docSnap.id, ...data };
    } else {
      throw new Error("No such document!");
    }
  } catch (error) {
    console.error("Error fetching Manager:", error);
    throw error;
  }
};

// Fetch doctor data by ID
export const fetchDoctorDataByID = async (id) => {
  try {
    const doctorRef = doc(db, "doctors", id);
    const docSnap = await getDoc(doctorRef);
    if (docSnap.exists()) {
      const doctorData = { id: docSnap.id, ...docSnap.data() };
      if (doctorData.image) {
        doctorData.imageUrl = await getDownloadURL(
          ref(storage, doctorData.image)
        );
      }
      return doctorData;
    } else {
      throw new Error("No such document!");
    }
  } catch (error) {
    console.error("Error fetching doctor:", error);
    throw error;
  }
};

// Upload image to Firebase Storage
export const uploadImageToFirestore = async (file) => {
  try {
    const storageRef = ref(storage, `images/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return { downloadURL, path: `images/${file.name}` };
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

// Remove image from Firebase Storage
export const removeImage = async (imagePath) => {
  try {
    await deleteObject(ref(storage, imagePath));
  } catch (error) {
    console.error("Error removing image:", error);
    throw error;
  }
};

// Update doctor data in Firestore
export const updateDoctorData = async (id, data) => {
  try {
    const doctorRef = doc(db, "doctors", id);
    await updateDoc(doctorRef, data);
  } catch (error) {
    console.error("Error updating document:", error);
    throw error;
  }
};

// Fetch company data by ID
export const fetchCompanyDataByID = async (id) => {
  try {
    const companyRef = doc(db, "companies", id);
    const docSnap = await getDoc(companyRef);
    if (docSnap.exists()) {
      const companyData = { id: docSnap.id, ...docSnap.data() };
      if (companyData.image) {
        const imageRef = ref(storage, companyData.image);
        try {
          const downloadURL = await getDownloadURL(imageRef);
          companyData.imageUrl = downloadURL;
        } catch (error) {
          console.error("Error fetching image URL:", error);
        }
      }
      return companyData;
    } else {
      throw new Error("No such document!");
    }
  } catch (error) {
    console.error("Error fetching companyData:", error);
    throw error;
  }
};

// Update company data in Firestore
export const updateCompanyData = async (
  id,
  companyData,
  imageFile,
  imageMarkedForRemoval
) => {
  try {
    const companyRef = doc(db, "companies", id);
    const newData = { ...companyData };

    if (imageFile) {
      const imageRef = ref(storage, `images/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);
      const downloadURL = await getDownloadURL(imageRef);
      newData.image = downloadURL;
    } else if (imageMarkedForRemoval) {
      if (companyData.image) {
        await deleteObject(ref(storage, companyData.image));
      }
      newData.image = "";
    }

    await updateDoc(companyRef, newData);
    return newData;
  } catch (error) {
    console.error("Error updating document:", error);
    throw error;
  }
};

//Meetings
// Fetch meetings with optional date filtering
export const fetchMeetings = async (searchDate, status) => {
  let meetingCollection = collection(db, "scheduleMeeting");

  if (searchDate !== "") {
    meetingCollection = query(
      meetingCollection,
      where("date", "==", searchDate)
    );
  }

  const snapshot = await getDocs(meetingCollection);

  const fetchCompanyData = async (companyId) => {
    const companyDocRef = doc(db, "companies", companyId);
    const companyDocSnapshot = await getDoc(companyDocRef);
    return companyDocSnapshot.exists() ? companyDocSnapshot.data() : null;
  };

  const fetchDoctorData = async (doctorId) => {
    const doctorDocRef = doc(db, "doctors", doctorId);
    const doctorDocSnapshot = await getDoc(doctorDocRef);
    return doctorDocSnapshot.exists() ? doctorDocSnapshot.data() : null;
  };

  const fetchAssignedData = async (assigned) => {
    let assignedName;
    let assignedRole;

    try {
      const companyDocRef = doc(db, "companies", assigned);
      const companyDocSnapshot = await getDoc(companyDocRef);

      if (companyDocSnapshot.exists()) {
        assignedName = companyDocSnapshot.data().name;
        assignedRole = companyDocSnapshot.data().role;
      } else {
        const userDocRef = doc(db, "users", assigned);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          assignedName = `${userData.firstName} ${userData.lastName}`;
          assignedRole = userData.role;
        } else {
          console.error(`No document found with ID ${assigned}`);
        }
      }
    } catch (error) {
      console.error("Error fetching assigned data:", error);
    }

    return { assignedName, assignedRole };
  };

  const now = new Date().toISOString().split("T")[0];

  const promises = snapshot.docs.map(async (doc) => {
    const meetingData = doc.data();
    const companyData = await fetchCompanyData(meetingData.companyID);
    const companyName = companyData
      ? companyData.companyName
      : "Unknown Company";
    const { assignedName, assignedRole } = await fetchAssignedData(
      meetingData.assigned
    );
    const doctorData = await fetchDoctorData(meetingData.doctorID);
    const doctorName = doctorData ? doctorData.name : "Unknown Doctor";

    return {
      id: doc.id,
      companyName,
      assignedName,
      assignedRole,
      doctorName,
      ...meetingData,
    };
  });

  const resolvedData = await Promise.all(promises);

  const upcomingMeetings = resolvedData.filter(
    (meeting) => meeting.date >= now && meeting.status !== "completed"
  );
  const completedMeetings = resolvedData.filter(
    (meeting) => meeting.status === "completed"
  );
  const missedMeetings = resolvedData.filter(
    (meeting) => meeting.date < now && meeting.status !== "completed"
  );

  // Return based on status
  if (status === "upcoming") {
    return upcomingMeetings;
  } else if (status === "completed") {
    return completedMeetings;
  } else if (status === "missed") {
    return missedMeetings;
  } else {
    // If no specific status is provided, return all meetings
    return { upcomingMeetings, completedMeetings, missedMeetings };
  }
};

// Modify meeting
export const modifyMeeting = async (
  selectedMeetingId,
  selectedDate,
  selectedTime,
  meetingType
) => {
  const meetingDocRef = doc(db, "scheduleMeeting", selectedMeetingId);

  const meetingDocSnapshot = await getDoc(meetingDocRef);
  if (!meetingDocSnapshot.exists()) {
    throw new Error(`Document with ID ${selectedMeetingId} does not exist`);
  }

  const adjustedDate = new Date(selectedDate);
  const ISTOffset = 330;
  adjustedDate.setMinutes(adjustedDate.getMinutes() + ISTOffset);
  const formattedDate = adjustedDate.toISOString().split("T")[0];

  await updateDoc(meetingDocRef, {
    date: formattedDate,
    time: selectedTime,
    status: "Rescheduled",
    meetingType: meetingType,
  });
};

// Accept meeting
export const acceptMeeting = async (meetingId) => {
  const meetingDocRef = doc(db, "scheduleMeeting", meetingId);

  await updateDoc(meetingDocRef, {
    status: "Accepted",
  });
};

// Delete meeting
export const deleteMeeting = async (meetingId) => {
  await deleteDoc(doc(db, "scheduleMeeting", meetingId));
};

//Messages
//Fetch messages with optional filtering
export const fetchMessages = async () => {
  try {
    const messageRef = collection(db, "messages");

    const querySnapshot = await getDocs(messageRef);

    const fetchCompanyData = async (companyID) => {
      const companyDocRef = doc(db, "companies", companyID);
      const companyDocSnapshot = await getDoc(companyDocRef);
      return companyDocSnapshot.exists() ? companyDocSnapshot.data() : null;
    };

    const fetchDoctorData = async (doctorID) => {
      const doctorDocRef = doc(db, "doctors", doctorID);
      const doctorDocSnapshot = await getDoc(doctorDocRef);
      return doctorDocSnapshot.exists() ? doctorDocSnapshot.data() : null;
    };

    const fetchAssignedData = async (messageId) => {
      let assignedName;

      try {
        const companyDocRef = doc(db, "companies", messageId);
        const companyDocSnapshot = await getDoc(companyDocRef);

        if (companyDocSnapshot.exists()) {
          assignedName = companyDocSnapshot.data().name;
        } else {
          const userDocRef = doc(db, "users", messageId);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            assignedName = `${userData.firstName} ${userData.lastName}`;
          } else {
            console.error(`No document found with ID ${messageId}`);
          }
        }
      } catch (error) {
        console.error("Error fetching assigned data:", error);
      }

      return assignedName;
    };

    const groupedMessages = {};
    const promises = querySnapshot.docs.map(async (doc) => {
      const messageData = doc.data();
      const companyData = await fetchCompanyData(messageData.companyID);
      const companyName = companyData
        ? companyData.companyName
        : "Unknown Company Name";
      const representativeName = companyData
        ? companyData.name
        : "Unknown Representative Name";

      const doctorData = await fetchDoctorData(messageData.doctorID);
      const doctorName = doctorData ? doctorData.name : "Unknown Doctor Name";

      const assignedName = await fetchAssignedData(messageData.messageId);

      const key = `${messageData.doctorID}_${messageData.companyID}`;
      if (!groupedMessages[key]) {
        groupedMessages[key] = {
          doctorName,
          companyName,
          representativeName,
          assignedName,
          doctorID: messageData.doctorID,
          companyID: messageData.companyID,
          messages: [],
          recentMessage: {
            text: "",
            isDoctor: false,
            date: "",
            time: "",
            timestamp: null,
          },
        };
      }

      const timestamp = messageData.timestamp?.toDate();
      const displayTime = timestamp ? format(timestamp, "hh:mm a") : "N/A";
      const fullTime = timestamp ? format(timestamp, "hh:mm:ss a") : "N/A";
      const date = timestamp ? format(timestamp, "dd/MM/yyyy") : "N/A";

      groupedMessages[key].messages.push({
        messageId: messageData.messageId,
        id: doc.id,
        message: messageData.messages,
        sentBy: messageData.sentBy,
        sentId: messageData.sentId,
        date,
        time: displayTime,
        fullTime,
        timestamp,
      });

      if (
        !groupedMessages[key].recentMessage.timestamp ||
        timestamp > groupedMessages[key].recentMessage.timestamp
      ) {
        groupedMessages[key].recentMessage = {
          text: messageData.messages,
          isDoctor: messageData.sentBy === "doctor",
          date,
          time: displayTime,
          fullTime,
          timestamp,
        };
      }
    });

    await Promise.all(promises);

    // Convert groupedMessages object to array
    const messagesArray = Object.keys(groupedMessages).map(
      (key) => groupedMessages[key]
    );
    const sortedMessages = sortMessagesByTimestamp(messagesArray);
    return sortedMessages;
  } catch (error) {
    console.error("Error fetching messages:", error);
  }
};

export const sortMessagesByTimestamp = (messagesArray) => {
  return messagesArray.sort((a, b) => {
    const timestampA = a.recentMessage.timestamp;
    const timestampB = b.recentMessage.timestamp;
    if (timestampA && timestampB) {
      return timestampB - timestampA;
    }
    return 0;
  });
};

//sendReply function
export const sendReply = async (currentConversation, replyMessage) => {
  if (
    !currentConversation ||
    !currentConversation.companyID ||
    !currentConversation.doctorID
  ) {
    throw new Error("Invalid conversation or missing IDs.");
  }
  const replyData = {
    companyID: currentConversation.companyID,
    doctorID: currentConversation.doctorID,
    messageId: currentConversation.messages[0].messageId,
    messages: replyMessage,
    sentBy: "admin",
    timestamp: new Date(),
  };

  const customId = `${currentConversation.doctorID}_${
    currentConversation.companyID
  }_${Date.now()}`;
  const customDocRef = doc(db, "messages", customId);
  await setDoc(customDocRef, replyData);

  const timestamp = replyData.timestamp;
  const date = format(timestamp, "dd/MM/yyyy");
  const time = format(timestamp, "hh:mm:ss a");

  return {
    messageId: currentConversation.messages[0].messageId,
    id: customId,
    message: replyData.messages,
    sentBy: replyData.sentBy,
    date,
    time,
    timestamp,
  };
};

//for separate company users like MR and sales head
export const fetchUserByCompanyId = async (id) => {
  try {
    const usersCollection = collection(db, "users");
    const q = query(usersCollection, where("companyId", "==", id));
    const querySnapshot = await getDocs(q);
    const usersData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return usersData;
  } catch (error) {
    console.log("Error fetching users: " + error.message);
    return [];
  }
};

//fetch userdata by userId
export const fetchUserById = async(userId) => {
  try {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    }
  } catch (error) {
    console.error("Error fetching hospital data:", error);
    return null;
  }
}

//update Users data
export const updateUserData = async (id, data) => {
  try {
    const userRef = doc(db, "users", id);
    await updateDoc(userRef, data);
  } catch (error) {
    console.error("Error updating document:", error);
    throw error;
  }
};

//delete function for user
export const deleteUser = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    await deleteDoc(userRef);
    console.log("User deleted successfully!");
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

//for separate company meetings
export const fetchMeetingsByCompanyId = async (id) => {
  try {
    const meetingCollection = collection(db, "scheduleMeeting");
    const q = query(meetingCollection, where("companyID", "==", id));
    const snapshot = await getDocs(q);

    const now = new Date().toISOString().split("T")[0];

    const fetchCompanyData = async (companyId) => {
      const companyDocRef = doc(db, "companies", companyId);
      const companyDocSnapshot = await getDoc(companyDocRef);
      return companyDocSnapshot.exists() ? companyDocSnapshot.data() : null;
    };

    const fetchDoctorData = async (doctorId) => {
      const doctorDocRef = doc(db, "doctors", doctorId);
      const doctorDocSnapshot = await getDoc(doctorDocRef);
      return doctorDocSnapshot.exists() ? doctorDocSnapshot.data() : null;
    };

    const fetchAssignedData = async (assigned) => {
      try {
        const companyDocRef = doc(db, "companies", assigned);
        const companyDocSnapshot = await getDoc(companyDocRef);

        if (companyDocSnapshot.exists()) {
          return {
            assignedName: companyDocSnapshot.data().name,
            assignedRole: companyDocSnapshot.data().role,
          };
        } else {
          const userDocRef = doc(db, "users", assigned);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            return {
              assignedName: `${userData.firstName} ${userData.lastName}`,
              assignedRole: userData.role,
            };
          }
        }
      } catch (error) {
        console.error("Error fetching assigned data:", error);
      }
      return { assignedName: "Unknown", assignedRole: "Unknown" };
    };

    const promises = snapshot.docs.map(async (doc) => {
      const meetingData = doc.data();

      const companyData = await fetchCompanyData(meetingData.companyID);
      const companyName = companyData
        ? companyData.companyName
        : "Unknown Company";
      const { assignedName, assignedRole } = await fetchAssignedData(
        meetingData.assigned
      );
      const doctorData = await fetchDoctorData(meetingData.doctorID);
      const doctorName = doctorData ? doctorData.name : "Unknown Doctor";
      const meetingDate = new Date(meetingData.date);

      return {
        id: doc.id,
        companyName,
        assignedName,
        assignedRole,
        doctorName,
        ...meetingData,
      };
    });

    const resolvedData = await Promise.all(promises);

    const upcoming = resolvedData.filter(
      (meeting) => meeting.date >= now && meeting.status !== "completed"
    );
    const completed = resolvedData.filter(
      (meeting) => meeting.status === "completed"
    );
    const missed = resolvedData.filter(
      (meeting) => meeting.date < now && meeting.status !== "completed"
    );

    const sortMeetings = (meetings) =>
      meetings.sort(
        (a, b) =>
          new Date(`${a.date} ${a.time}`) - new Date(`${b.date} ${b.time}`)
      );
    return {
      AllMeetings: sortMeetings(resolvedData),
      upcomingMeetings: sortMeetings(upcoming),
      missedMeetings: sortMeetings(missed),
      completedMeetings: sortMeetings(completed),
    };
  } catch (error) {
    console.error("Error fetching meetings: " + error.message);
    return;
  }
};

//update or add company Credits
export const updateCompanyCredits = async (id, additionalCredits) => {
  try {
    const companyDocRef = doc(db, "companies", id);
    const companyDocSnapshot = await getDoc(companyDocRef);

    if (companyDocSnapshot.exists()) {
      const currentCredits = companyDocSnapshot.data().credits || 0;
      const totalNewCredits = currentCredits + additionalCredits;
      await updateDoc(companyDocRef, { credits: totalNewCredits });
    }
  } catch (error) {
    console.error("Error updating credits:", error);
    throw error;
  }
};

//fetch recent 5 doctors
export const fetchRecentDoctors = async () => {
  try {
    const doctorsCollection = collection(db, "doctors");
    const q = query(doctorsCollection, orderBy("createdAt", "desc"));
    const snapshot = await getDocs(q);
    const recentDoctorsData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Fetch hospitals
  const hospitalsCollection = collection(db, "hospitals");
  const hospitalsSnapshot = await getDocs(hospitalsCollection);
  const hospitalsMap = hospitalsSnapshot.docs.reduce((acc, doc) => {
    const data = doc.data();
    acc[doc.id] = data.hospitalName;
    return acc;
  }, {});

  // Add hospital names to doctors data
  return recentDoctorsData.map(doctor => ({
    ...doctor,
    hospitalName: hospitalsMap[doctor.hospitalId] || "N/A"
  }));
  } catch (error) {
    throw new Error("Error fetching doctors: " + error.message);
  }
};

//fetch recent 5 companies
export const fetchRecentCompanies = async () => {
  try {
    const companiesCollection = collection(db, "companies");
    const q = query(companiesCollection, orderBy("createdAt", "desc"));
    const snapshot = await getDocs(q);
    const recentCompaniesData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return recentCompaniesData;
  } catch (error) {
    throw new Error("Error fetching company: " + error.message);
  }
};

//fetchHospital dat aby id
export const fetchHospitalDataByID = async (id) => {
  try {
    const docRef = doc(db, "hospitals", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching hospital data:", error);
    return null;
  }
};

//update hospital data
export const updateHospitalData = async (id, data) => {
  try {
    const hospitalRef = doc(db, "hospitals", id);
    await updateDoc(hospitalRef, data);
  } catch (error) {
    console.error("Error updating document:", error);
    throw error;
  }
};

//for separate hospital fetch doctor
export const fetchDoctorByHospitalId = async (id) => {
  try {
    const doctorsCollection = collection(db, "doctors");
    const q = query(doctorsCollection, where("hospitalId", "==", id));
    const querySnapshot = await getDocs(q);
    const doctorsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return doctorsData;
  } catch (error) {
    console.log("Error fetching users: " + error.message);
    return [];
  }
};

//fetch meetings according to hospitalId
export const fetchMeetingByHospitalId = async (id) => {
  try {
    const now = new Date();

    const doctorsCollection = collection(db, "doctors");
    const doctorsQuery = query(
      doctorsCollection,
      where("hospitalId", "==", id)
    );
    const doctorsSnapshot = await getDocs(doctorsQuery);
    const doctorIds = doctorsSnapshot.docs.map((doc) => doc.id);

    const meetingCollection = collection(db, "scheduleMeeting");
    const meetingsQuery = query(
      meetingCollection,
      where("doctorID", "in", doctorIds)
    );
    const meetingsSnapshot = await getDocs(meetingsQuery);

    const fetchCompanyData = async (companyId) => {
      const companyDocRef = doc(db, "companies", companyId);
      const companyDocSnapshot = await getDoc(companyDocRef);
      return companyDocSnapshot.exists() ? companyDocSnapshot.data() : null;
    };

    const fetchDoctorData = async (doctorId) => {
      const doctorDocRef = doc(db, "doctors", doctorId);
      const doctorDocSnapshot = await getDoc(doctorDocRef);
      return doctorDocSnapshot.exists() ? doctorDocSnapshot.data() : null;
    };

    const fetchAssignedData = async (assigned) => {
      let assignedName;
      let assignedRole;
      try {
        const companyDocRef = doc(db, "companies", assigned);
        const companyDocSnapshot = await getDoc(companyDocRef);
        if (companyDocSnapshot.exists()) {
          assignedName = companyDocSnapshot.data().name;
          assignedRole = companyDocSnapshot.data().role;
        } else {
          const userDocRef = doc(db, "users", assigned);
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            assignedName = `${userData.firstName} ${userData.lastName}`;
            assignedRole = userData.role;
          } else {
            console.error(`No document found with ID ${assigned}`);
          }
        }
      } catch (error) {
        console.error("Error fetching assigned data:", error);
      }
      return { assignedName, assignedRole };
    };

    const promises = meetingsSnapshot.docs.map(async (doc) => {
      const meetingData = doc.data();

      const companyData = await fetchCompanyData(meetingData.companyID);
      const companyName = companyData
        ? companyData.companyName
        : "Unknown Company";
      const { assignedName, assignedRole } = await fetchAssignedData(
        meetingData.assigned
      );
      const doctorData = await fetchDoctorData(meetingData.doctorID);
      const doctorName = doctorData ? doctorData.name : "Unknown Doctor";
      const location = doctorData ? doctorData.location : "Unknown Location";
      const meetingDate = new Date(meetingData.date);

      return {
        id: doc.id,
        companyName,
        assignedName,
        assignedRole,
        doctorName,
        location,
        ...meetingData,
      };
    });

    const resolvedData = await Promise.all(promises);

    const upcoming = resolvedData.filter(
      (meeting) => new Date(meeting.date) >= now && meeting.status !== "completed"
    );
    const completed = resolvedData.filter(
      (meeting) => meeting.status === "completed"
    );
    const missed = resolvedData.filter(
      (meeting) => new Date(meeting.date) < now && meeting.status !== "completed"
    );

    const sortMeetings = (meetings) =>
      meetings.sort(
        (a, b) =>
          new Date(`${a.date} ${a.time}`) - new Date(`${b.date} ${b.time}`)
      );
    return {
      AllMeetings: sortMeetings(resolvedData),
      upcomingMeetings: sortMeetings(upcoming),
      missedMeetings: sortMeetings(missed),
      completedMeetings: sortMeetings(completed),
    };

  } catch (error) {
    console.error("Error fetching meetings:", error);
    throw error;
  }
};

//for separate doctor meetings
export const fetchMeetingsByDoctorId = async (id) => {
  try {
    const meetingCollection = collection(db, "scheduleMeeting");
    const q = query(meetingCollection, where("doctorID", "==", id));
    const snapshot = await getDocs(q);

    const now = new Date().toISOString().split("T")[0];

    const fetchCompanyData = async (companyId) => {
      const companyDocRef = doc(db, "companies", companyId);
      const companyDocSnapshot = await getDoc(companyDocRef);
      return companyDocSnapshot.exists() ? companyDocSnapshot.data() : null;
    };

    const fetchDoctorData = async (doctorId) => {
      const doctorDocRef = doc(db, "doctors", doctorId);
      const doctorDocSnapshot = await getDoc(doctorDocRef);
      return doctorDocSnapshot.exists() ? doctorDocSnapshot.data() : null;
    };

    const fetchAssignedData = async (assigned) => {
      try {
        const companyDocRef = doc(db, "companies", assigned);
        const companyDocSnapshot = await getDoc(companyDocRef);

        if (companyDocSnapshot.exists()) {
          return {
            assignedName: companyDocSnapshot.data().name,
            assignedRole: companyDocSnapshot.data().role,
          };
        } else {
          const userDocRef = doc(db, "users", assigned);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            return {
              assignedName: `${userData.firstName} ${userData.lastName}`,
              assignedRole: userData.role,
            };
          }
        }
      } catch (error) {
        console.error("Error fetching assigned data:", error);
      }
      return { assignedName: "Unknown", assignedRole: "Unknown" };
    };

    const promises = snapshot.docs.map(async (doc) => {
      const meetingData = doc.data();

      const companyData = await fetchCompanyData(meetingData.companyID);
      const companyName = companyData
        ? companyData.companyName
        : "Unknown Company";
      const { assignedName, assignedRole } = await fetchAssignedData(
        meetingData.assigned
      );
      const doctorData = await fetchDoctorData(meetingData.doctorID);
      const doctorName = doctorData ? doctorData.name : "Unknown Doctor";
      const meetingDate = new Date(meetingData.date);

      return {
        id: doc.id,
        companyName,
        assignedName,
        assignedRole,
        doctorName,
        ...meetingData,
      };
    });

    const resolvedData = await Promise.all(promises);

    const upcoming = resolvedData.filter(
      (meeting) => meeting.date >= now && meeting.status !== "completed"
    );
    const completed = resolvedData.filter(
      (meeting) => meeting.status === "completed"
    );
    const missed = resolvedData.filter(
      (meeting) => meeting.date < now && meeting.status !== "completed"
    );

    const sortMeetings = (meetings) =>
      meetings.sort(
        (a, b) =>
          new Date(`${a.date} ${a.time}`) - new Date(`${b.date} ${b.time}`)
      );
    return {
      AllMeetings: sortMeetings(resolvedData),
      upcomingMeetings: sortMeetings(upcoming),
      missedMeetings: sortMeetings(missed),
      completedMeetings: sortMeetings(completed),
    };
  } catch (error) {
    console.error("Error fetching meetings: " + error.message);
    return;
  }
};
