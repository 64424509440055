import React, { useState, useEffect } from "react";
import DoctorNavbar from "./DoctorNavbar";
import DoctorSide from "./DoctorSide";
import { FaChartLine } from "react-icons/fa6";
import { PiChartPieSliceFill } from "react-icons/pi";
import { useParams } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { fetchMeetingsChart } from "./DoctorFirebase";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const DoctorAnalytics = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [chartData, setChartData] = useState({});
  const [projectedEarningsData, setProjectedEarningsData] = useState({});
  const { id } = useParams();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {

    const fetchMeetings = async () => {
      try {
        const { meetingsByMonth, missedMeetingsByMonth, completedMeetingsByMonth } = await fetchMeetingsChart(id);
        const currentDate = new Date();
        const months = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        // Calculate the range: previous month, current month, and next 4 months
        const desiredMonths = [];
        for (let i = -1; i < 5; i++) {
          const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
          const month = date.toLocaleString("default", { month: "short" });
          const year = date.getFullYear();
          desiredMonths.push(`${month} ${year}`);
        }

        const meetingCounts = desiredMonths.map(
          (label) => meetingsByMonth[label] || 0
        );

        const missedMeetingCounts = desiredMonths.map(
          (label) => missedMeetingsByMonth[label] || 0
        );
        const completedMeetingCounts = desiredMonths.map(
          (label) => completedMeetingsByMonth[label]
        );

        const updatedChartData = {
          labels: desiredMonths,
          datasets: [
            {
              label: "Total Meetings",
              data: meetingCounts,
              fill: false,
              borderColor: "rgba(119,150,230,1)",
              borderWidth: 2,
              tension: 0.1,
              pointStyle: "rectRounded",
              pointBorderWidth: 2,
            },
            {
              label: "Missed Meetings",
              data: missedMeetingCounts,
              fill: false,
              borderColor: "rgba(255,99,132,1)",
              borderWidth: 2,
              tension: 0.1,
              pointStyle: "rectRounded",
              pointBorderWidth: 2,
            },
          ],
        };

        // New chart data for Projected Earning vs. Final Earning
        const projectedEarnings = desiredMonths.map((label) => {
          const meetingsCount = meetingsByMonth[label];
          return 150 * (meetingsCount || 0);
        });
        const finalEarnings = desiredMonths.map((label) => {
          const completedCount = completedMeetingsByMonth[label];
          return 150 * (completedCount || 0);
        });

        const updatedProjectedEarningsData = {
          labels: desiredMonths,
          datasets: [
            {
              label: "Projected Earning",
              data: projectedEarnings,
              borderColor: "#0D47A1",
              backgroundColor: "rgba(134, 151, 196, 0.2)",
              borderWidth: 2,
              fill: true,
            },
            {
              label: "Final Earning",
              data: finalEarnings,
              borderColor: "#4CAF50",
              backgroundColor: "rgba(119,150,230,0.2)",
              borderWidth: 2,
              fill: true,
            },
          ],
        };
        setChartData(updatedChartData);
        setProjectedEarningsData(updatedProjectedEarningsData);
      } catch (error) {
        console.error("Error fetching schedule meetings:", error);
      }
    };

    fetchMeetings();
  }, [id]);

  return (
    <div className="flex flex-col h-screen">
      <DoctorNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <DoctorSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container px-4 mx-auto my-4 grid grid-cols-1 lg:grid-cols-2 gap-4">

            <div className="bg-white p-2 rounded shadow-md">
              <div className="bg-[#0D47A1] text-white p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <FaChartLine className="mr-2" /> Meetings
                </h2>
              </div>
              {Object.keys(chartData).length > 0 &&
                <Line
                  data={chartData}
                  options={{
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: 'Month',
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: 'Meetings',
                        },
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              }
            </div>

            <div className="bg-white p-2 rounded shadow-md">
              <div className="bg-[#0D47A1] text-white p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <PiChartPieSliceFill className="mr-2" /> Earning
                </h2>
              </div>
              {/* Update the chart here */}
              {Object.keys(projectedEarningsData).length > 0 && (
                <Line
                  data={projectedEarningsData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: true,
                        position: 'top',
                      },
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            return `${context.dataset.label}: ₹${context.raw}`;
                          },
                        },
                      },
                    },
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: 'Month',
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: 'Earnings (₹)',
                        },
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorAnalytics;
