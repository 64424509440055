import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DoctorNavbar from "./DoctorNavbar";
import DoctorSide from "./DoctorSide";
import { IoIosArrowRoundForward } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css";
import { auth, storage } from "../firebase";
import { fetchDoctor, fetchBankDetails, handleImageUpload, handleImageRemove, updateDoctorDetails, updateBankDetails, handlePasswordUpdate } from "./DoctorFirebase";

const DocdashProfile = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { id } = useParams();
  const [doctor, setDoctor] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [doctorDetails, setDoctorDetails] = useState(false);
  const [doctorAbout, setDoctorAbout] = useState(false);
  const [doctorExperience, setDoctorExperience] = useState(false);
  const [doctorEducation, setDoctorEducation] = useState(false);
  const [doctorPassword, setDoctorPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [doctorBankDetails, setDoctorBankDetails] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [bank, setBank] = useState(null);
  const navigate = useNavigate();

  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIFSCCode] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [bankDocId, setBankDocId] = useState("");

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchDoctorData = async () => {
      try {
        const { doctorData, imageUrl } = await fetchDoctor(id, storage);
        setDoctor(doctorData);
        setImageUrl(imageUrl);
      } catch (error) {
        console.log("Error fetching doctor :", error);
      }
    };
    const fetchBankDetailsData = async () => {
      try {
        const { bankData, isEditMode } = await fetchBankDetails(id);

        if (bankData) {
          setBank(bankData);
          setBankName(bankData.bankName);
          setAccountNumber(bankData.accountNumber);
          setIFSCCode(bankData.ifscCode);
          setAccountHolderName(bankData.accountHolderName);
          setBankDocId(bankData.id);
        }
        setIsEditMode(isEditMode);
      } catch (error) {
        console.log("Error fetching bank details:", error);
      }
    };

    fetchDoctorData();
    fetchBankDetailsData();
  }, [id]);

  const handleImageUploading = async (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setImageFile(file);
      const previewUrl = await handleImageUpload(file);
      setImageUrl(previewUrl);
    } else {
      console.error("No file selected or invalid file type");
    }
  };

  // const handleImageRemoved = async () => {
  //   const removed = await handleImageRemove(doctor.image);
  //   if (removed) {
  //     setImageUrl(null);
  //     alert("Removing your image...");
  //   }
  // };
  const handleImageRemoved = async () => {
    if (imageUrl) {
      // If the image is in preview but not uploaded
      setImageUrl(null);
      setImageFile(null);
      alert("Removing your image...");
    } else if (doctor?.image) {
      const removed = await handleImageRemove(doctor.image);
      if (removed) {
        setImageUrl(null);
        setDoctor({ ...doctor, image: "" });
        alert("Removing your image...");
      }
    }
  };



  const handleDoctorDetails = async (e) => {
    e.preventDefault();
    const newData = { ...doctor };

    if (imageFile) {
      const downloadURL = await handleImageUpload(imageFile);
      newData.image = downloadURL;
    } else if (!imageUrl) {
      newData.image = "";
    }
    const success = await updateDoctorDetails(id, newData);

    if (success) {
      toast.success("Data successfully updated!", { autoclose: 1000, onClose: () => setDoctorDetails(false), });
      // setTimeout(() => window.location.reload(), 1000);
    }
  };

  const handleDoctorAbout = async (e) => {
    e.preventDefault();
    const success = await updateDoctorDetails(id, doctor);
    if (success) {
      toast.success("Data successfully updated!", { autoclose: 1000, onClose: () => setDoctorAbout(false), });
      // setTimeout(() => window.location.reload(), 1000);
    }
  };

  const handleDoctorExperience = async (e) => {
    e.preventDefault();
    const success = await updateDoctorDetails(id, doctor);
    if (success) {
      toast.success("Data successfully updated!", { autoclose: 1000, onClose: () => setDoctorExperience(false), });
      // setTimeout(() => window.location.reload(), 1000);
    }
  };

  const handleDoctorEducation = async (e) => {
    e.preventDefault();
    const success = await updateDoctorDetails(id, doctor);
    if (success) {
      toast.success("Data successfully updated!", { autoclose: 1000, onClose: () => setDoctorEducation(false), });
      // setTimeout(() => window.location.reload(), 1000);
    }
  };

  const handleDoctorPassword = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (newPassword !== confirmPassword) {
      setError("New passwords do not match");
      return;
    }

    const user = auth.currentUser;

    if (user) {
      const success = await handlePasswordUpdate(user, oldPassword, newPassword);
      if (success) {
        setSuccess("Password reset successfully!");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        toast.success("Data successfully updated!", { autoclose: 1000, onClose: () => setDoctorPassword(false), });
        // setTimeout(() => window.location.reload(), 1000);
      } else {
        setError("Error updating password.");
      }
    } else {
      setError("No user is signed in.");
    }

  };

  const handleSaveBankDetails = async () => {
    const bankData = {
      bankName,
      accountNumber,
      ifscCode,
      accountHolderName,
      id,
    };

    const success = await updateBankDetails(bankDocId, bankData, isEditMode);
    if (success) {
      toast.success(isEditMode ? "Bank details updated successfully!" : "Bank details added successfully!", { autoclose: 1000, onClose: () => setDoctorBankDetails(false), });
      setIsEditMode(true);
      setBankDocId(id);
      // setTimeout(() => window.location.reload(), 1000);
    } else {
      setError("Error saving bank details.");
    }
  };

  const handleEditBankDetails = () => {
    setIsEditMode(false);
  };

  const toggleDoctorDetails = () => {
    setDoctorDetails(!doctorDetails);
  };

  const toggleDoctorAbout = () => {
    setDoctorAbout(!doctorAbout);
  };

  const toggleDoctorExperience = () => {
    setDoctorExperience(!doctorExperience);
  };

  const toggleDoctorEducation = () => {
    setDoctorEducation(!doctorEducation);
  };

  const toggleDoctorPassword = () => {
    setDoctorPassword(!doctorPassword);
  };

  const toggleBankDetails = () => {
    setDoctorBankDetails(!doctorBankDetails);
  };

  const profile = () => {
    navigate(`/doctor/profileDoctor/${id}`);
  };

  return (
    <div className="flex flex-col h-screen">
      <DoctorNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <DoctorSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-all duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          {doctor && (
            <div className="container px-4 mx-auto my-10">
              <div className="overflow-hidden px-10 md:px-5" id="companyedits">
                <div className="border shadow rounded-lg mb-3">
                  <div id="companydetails" className="md:h-auto">
                    <div className="flex items-center justify-between my-3">
                      <h1 className="text-xl font-bold uppercase px-6 md:px-2 ">
                        Get Started
                      </h1>
                      <button
                        onClick={profile}
                        className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                      >
                        Preview
                      </button>
                    </div>
                  </div>
                </div>

                <div className="border shadow rounded-lg">
                  <div id="companydetails" className="md:h-auto">
                    <div className="flex items-center justify-between my-5">
                      <h1 className="text-xl font-bold uppercase px-6 md:px-2 ">
                        Profile Information
                      </h1>
                    </div>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2"
                      onClick={toggleDoctorDetails}
                    >
                      <p className="cursor-pointer">
                        Name, Current-Position, Profile Image , Specialist
                      </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 p-0 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={toggleDoctorAbout}
                    >
                      <p className="cursor-pointer">About, Location</p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={toggleDoctorEducation}
                    >
                      <p className="cursor-pointer">Education</p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={toggleDoctorExperience}
                    >
                      <p className="cursor-pointer">Experience </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={toggleDoctorPassword}
                    >
                      <p className="cursor-pointer">Change Password </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={toggleBankDetails}
                    >
                      <p className="cursor-pointer">Bank Details </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {doctorDetails && (
            <div className="overlay">
              <div className="overlay-content" style={{ overflow: "auto" }}>
                <div className="rounded-md">
                  <p className="text-xl text-center font-bold mb-2">
                    Edit Doctor Details
                  </p>
                  <div>
                    <div class="mb-3">
                      <label
                        htmlFor="name"
                        class="block px-2 text-md font-semibold text-gray-900 mb-1"
                      >
                        Name :
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="text-md text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2"
                        value={doctor ? doctor.name : ""}
                        onChange={(e) =>
                          setDoctor({
                            ...doctor,
                            name: e.target.value,
                          })
                        }
                        placeholder=" "
                      />
                    </div>
                    <div class="mb-3">
                      <label
                        htmlFor="currentPosition"
                        class="block px-2 text-md font-semibold text-gray-900 mb-1"
                      >
                        Current-Position :
                      </label>
                      <input
                        type="text"
                        name="currentPosition"
                        id="currentPosition"
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2"
                        value={doctor ? doctor.currentPosition : ""}
                        onChange={(e) =>
                          setDoctor({
                            ...doctor,
                            currentPosition: e.target.value,
                          })
                        }
                        placeholder=" "
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="specialist"
                        className="block px-2 text-md font-semibold text-gray-900 mb-1 "
                      >
                        Specialist :
                      </label>
                      <select
                        name="specialist"
                        value={doctor ? doctor.specialist : ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, specialist: e.target.value })
                        }
                        required
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                      >
                        <option value="">Select Specialist</option>
                        <option value="Orthopaedic">Orthopaedic</option>
                        <option value="Cardiologist">Cardiologist</option>
                        <option value="Gynaecologist">Gynaecologist</option>
                        <option value="Radiologist">Radiologist</option>
                        <option value="Dermatologist">Dermatologist</option>
                        <option value="Oncology">Oncology</option>
                        <option value="Neurology">Neurology</option>
                        <option value="Urology">Urology</option>
                        <option value="Ophthalmology">Ophthalmology</option>
                        <option value="Paediatric">Paediatric</option>
                      </select>
                    </div>
                    <div class="mb-3">
                      <label
                        htmlFor="image"
                        class="block px-2 text-md font-semibold text-gray-900 mb-1"
                      >
                        Image :
                      </label>
                      <input
                        type="file"
                        name="image"
                        id="image"
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2"
                        onChange={handleImageUploading}
                        accept="image/*"
                      />
                    </div>
                    {imageUrl && (
                      <div className="mb-6 text-center">
                        <img
                          src={imageUrl}
                          alt="Preview"
                          style={{ maxWidth: "100%", maxHeight: "100px", margin: 'auto' }}
                        />
                        <button
                          onClick={handleImageRemoved}
                          className="text-white bg-[#8697C4] hover:bg-[#586892] focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm py-2 px-4 mt-2"
                        >
                          Remove Image
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end mt-3">
                    <button
                      onClick={handleDoctorDetails}
                      className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setDoctorDetails(false)}
                      className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {doctorAbout && (
            <div className="overlay">
              <div className="overlay-content" style={{ overflow: "auto" }}>
                <div className="rounded-md">
                  <p className="text-xl text-center font-bold mb-2">
                    Edit Doctor About
                  </p>
                  <div>
                    <div class="mb-3">
                      <label
                        htmlFor="about"
                        class="block px-2 text-md font-semibold text-gray-900 mb-1"
                      >
                        About :
                      </label>
                      <textarea
                        name="about"
                        id="about"
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2"
                        value={doctor ? doctor.about : ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, about: e.target.value })
                        }
                        placeholder=" "
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="location"
                        className="block px-2 text-md font-semibold text-gray-900 mb-1 "
                      >
                        Location :
                      </label>
                      <select
                        name="location"
                        value={doctor ? doctor.location : ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, location: e.target.value })
                        }
                        required
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                      >
                        <option value="">Select Location</option>
                        <option value="Bangalore">Bangalore</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Mumbai">Mumbai</option>
                        <option value="Kolkata">Kolkata</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Chennai">Chennai</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex justify-end mt-3">
                    <button
                      onClick={handleDoctorAbout}
                      className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setDoctorAbout(false)}
                      className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {doctorExperience && (
            <div className="overlay">
              <div className="overlay-content" style={{ overflow: "auto" }}>
                <div className="rounded-md">
                  <p className="text-xl text-center font-bold mb-2">
                    Edit Experience
                  </p>
                  <div>
                    <div class="mb-3">
                      <label
                        htmlFor="experience_1"
                        class="block px-2 text-md font-semibold text-gray-900 mb-1"
                      >
                        Experience 1 :
                      </label>
                      <textarea
                        name="experience_1"
                        id="experience_1"
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2"
                        value={doctor ? doctor.experience_1 : ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, experience_1: e.target.value })
                        }
                        placeholder=" "
                      ></textarea>
                    </div>
                    <div class="mb-3">
                      <label
                        htmlFor="experience_2"
                        class="block px-2 text-md font-semibold text-gray-900 mb-1"
                      >
                        Experience 2 :
                      </label>
                      <textarea
                        name="experience_2"
                        id="experience_2"
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2"
                        value={doctor ? doctor.experience_2 : ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, experience_2: e.target.value })
                        }
                        placeholder=" "
                      ></textarea>
                    </div>
                    <div class="mb-3">
                      <label
                        htmlFor="experience_3"
                        class="block px-2 text-md font-semibold text-gray-900 mb-1"
                      >
                        Experience 3 :
                      </label>
                      <textarea
                        name="experience_3"
                        id="experience_3"
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2"
                        value={doctor ? doctor.experience_3 : ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, experience_3: e.target.value })
                        }
                        placeholder=" "
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex justify-end mt-3">
                    <button
                      onClick={handleDoctorExperience}
                      className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setDoctorExperience(false)}
                      className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {doctorEducation && (
            <div className="overlay">
              <div className="overlay-content" style={{ overflow: "auto" }}>
                <div className="rounded-md">
                  <p className="text-xl text-center font-bold mb-2">
                    Edit Education
                  </p>
                  <div>
                    <div class="mb-3">
                      <label
                        htmlFor="education_1"
                        class="block px-2 text-md font-semibold text-gray-900 mb-1"
                      >
                        Education 1 :
                      </label>
                      <textarea
                        name="education_1"
                        id="education_1"
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2"
                        value={doctor ? doctor.education_1 : ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, education_1: e.target.value })
                        }
                        placeholder=" "
                      ></textarea>
                    </div>
                    <div class="mb-3 ">
                      <label
                        htmlFor="education_2"
                        class="block px-2 text-md font-semibold text-gray-900 mb-1"
                      >
                        Education 2 :
                      </label>
                      <textarea
                        name="education_2"
                        id="education_2"
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2"
                        value={doctor ? doctor.education_2 : ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, education_2: e.target.value })
                        }
                        placeholder=" "
                      ></textarea>
                    </div>
                    <div class="mb-3 ">
                      <label
                        htmlFor="education_3"
                        class="block px-2 text-md font-semibold text-gray-900 mb-1"
                      >
                        Education 3 :
                      </label>
                      <textarea
                        name="education_3"
                        id="education_3"
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2"
                        value={doctor ? doctor.education_3 : ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, education_3: e.target.value })
                        }
                        placeholder=" "
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex justify-end mt-3">
                    <button
                      onClick={handleDoctorEducation}
                      className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setDoctorEducation(false)}
                      className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {doctorPassword && (
            <div className="overlay">
              <div className="overlay-content" style={{ overflow: "auto" }}>
                <div className="rounded-md">
                  <p className="text-xl text-center font-bold mb-2">
                    Change Password
                  </p>
                  <div>
                    <div className="mb-3">
                      <label
                        className="block text-gray-700 text-md font-bold mb-1"
                        htmlFor="oldPassword"
                      >
                        Old Password
                      </label>
                      <input
                        type="password"
                        id="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        className="block text-gray-700 text-md font-bold mb-1"
                        htmlFor="newPassword"
                      >
                        New Password
                      </label>
                      <input
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        className="block text-gray-700 text-md font-bold mb-1"
                        htmlFor="confirmPassword"
                      >
                        Confirm New Password
                      </label>
                      <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    {error && (
                      <p className="text-red-500 text-xs italic mb-3">
                        {error}
                      </p>
                    )}
                    {success && (
                      <p className="text-green-500 text-xs italic mb-3">
                        {success}
                      </p>
                    )}
                  </div>
                  <div className="flex justify-end mt-3">
                    <button
                      onClick={handleDoctorPassword}
                      className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setDoctorPassword(false)}
                      className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {doctorBankDetails && (
            <div className="overlay">
              <div className="overlay-content" style={{ overflow: "auto" }}>
                {isEditMode ? (
                  <div className="rounded-md">
                    <p className="text-xl text-center font-bold mb-2">
                      Bank Details
                    </p>
                    <div>
                      <div className="flex items-center mb-3">
                        <div className="font-semibold flex-1">Bank Name :</div>
                        <div className="flex-1">
                          <p className="">{bankName}</p>
                        </div>
                      </div>
                      <div className="flex items-center mb-3">
                        <div className="flex-1 font-semibold ">Account Holder Name :</div>
                        <div className="flex-1 ">
                          <p className="">{accountHolderName}</p>
                        </div>
                      </div>
                      <div className="flex items-center mb-3">
                        <div className="flex-1 font-semibold ">Account Number :</div>
                        <div className="flex-1">
                          <p className="">{accountNumber}</p>
                        </div>
                      </div>
                      <div className="flex items-center mb-3">
                        <div className="flex-1 font-semibold ">IfSC Code :</div>
                        <div className="flex-1">
                          <p className="">{ifscCode}</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end mt-3">
                      <button
                        onClick={handleEditBankDetails}
                        className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                      >
                        Change
                      </button>
                      <button
                        onClick={() => setDoctorBankDetails(false)}
                        className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="rounded-md">
                    <h1 className="text-xl text-center font-bold mb-2">
                      Bank Details
                    </h1>
                    <form className="space-y-4">
                      <div>
                        <label className="block text-gray-700 text-md font-bold mb-1">
                          Bank Name
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={bankName}
                          onChange={(e) => setBankName(e.target.value)}
                          type="text"
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700 text-md font-bold mb-1">
                          Account Holder Name
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={accountHolderName}
                          onChange={(e) => setAccountHolderName(e.target.value)}
                          type="text"
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700 text-md font-bold mb-1">
                          Account Number
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                          type="text"
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700 text-md font-bold mb-1">
                          IFSC Code
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={ifscCode}
                          onChange={(e) => setIFSCCode(e.target.value)}
                          type="text"
                        />
                      </div>
                    </form>
                    <div className="flex justify-end mt-3">
                      <button
                        onClick={handleSaveBankDetails}
                        className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setDoctorBankDetails(false)}
                        className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default DocdashProfile;
