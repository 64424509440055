import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { NavLink } from "react-router-dom";
import MedivirtLogo from "../../assets/img/Medivirt.png";
import Image from "../../assets/img/defaultAvatar.png";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import "../style/Company.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CompanyNavbar = () => {
  const [company, setCompany] = useState(null);
  const { id } = useParams();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { from } = location.state || {};

  useEffect(() => {
    const fetchDoctor = async () => {
      try {
        const db = getFirestore();
        const docRef = doc(db, "companies", id);
        const companySnapshot = await getDoc(docRef);
        if (companySnapshot.exists()) {
          setCompany({ id: companySnapshot.id, ...companySnapshot.data() });
        } else {
          console.log("No such Company");
        }
      } catch (error) {
        console.log("Error fetching company :", error);
      }
    };
    fetchDoctor();

    if (from) {
      sessionStorage.setItem("returnFrom", from);
    }
  }, [id, from]);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("User logged out successfully.");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  const handleReturnToDashboard = () => {
    const storedFrom = sessionStorage.getItem("returnFrom");
  
    if (storedFrom === "admin") {
      toast.success("Returning to Admin Dashboard!", {
        autoClose: 2000, // Duration for Toast
      });
  
      setTimeout(() => {
        navigate(`/admin/dash`);
      }, 2000); // Delay to match Toast duration
    }
  
    sessionStorage.removeItem("returnFrom");
  };

  return (
    <nav className="bg-[#1E2955] border-b w-full fixed top-0 left-0">
      <div className="flex items-center justify-between py-3 px-6">
        <NavLink to="/" className="flex-shrink-0">
          <img id="logo" className="h-8" alt="Medivirt" src={MedivirtLogo} />
        </NavLink>
        {company && (
          <div className="flex items-center space-x-4">
            <button
              id="companyimage"
              className="w-10 h-10 outline-none rounded-full"
            >
              {company.image ? (
                <img
                  src={company.image}
                  alt={`Profile of ${company.name}`}
                  className="w-full h-full rounded-full"
                />
              ) : (
                <div className="w-10 h-10 outline-none rounded-full">
                  {/* Placeholder image */}
                  <img
                    src={Image}
                    className="w-full h-full rounded-full"
                    alt="User Profile"
                  />
                </div>
              )}
            </button>
            <div className="text-white">
              <p className="font-bold">{company.name}</p>
              <p className="text-sm">{company.companyName}</p>
            </div>
            <button
              className="w-7 h-7 text-white border rounded-full"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <MdOutlineKeyboardArrowDown size={24} />
            </button>
            {dropdownOpen && (
              <div className="absolute top-full right-0 mt-2 w-40 bg-white rounded shadow z-10">
                <button
                  className="block w-full text-left p-2 hover:bg-gray-200"
                  onClick={handleLogout}
                >
                  Logout
                </button>
                {(from || sessionStorage.getItem("returnFrom")) && (
                  <button
                    onClick={handleReturnToDashboard}
                    className="block w-full text-left py-2 hover:bg-gray-200"
                  >
                    Return to Dashboard
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <ToastContainer />
    </nav>
  );
};

export default CompanyNavbar;
