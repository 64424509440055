import React, { useState, useEffect } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { format } from "date-fns";
import { fetchCompanies, fetchRecentCompanies } from "./AdminFirebase";

const Company = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [recentCompaniesFilter, setRecentCompaniesFilter] = useState("All");

  const navigate = useNavigate();
  const { isAdminLoggedIn } = useAuth();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchCompaniesData = async () => {
      try {
        let companiesData = [];

        if (recentCompaniesFilter == "recentCompany") {
          companiesData = await fetchRecentCompanies();
        } else {
          companiesData = await fetchCompanies();
        }
        setCompanies(companiesData);
      } catch (error) {
        setError("Error fetching companies: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCompaniesData();
  }, [recentCompaniesFilter]);

  const handleEditProfile = (companyId) => {
    navigate(`/admin/edit-company/${companyId}`);
  };

  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  const filteredCompanies = companies.filter(
    (company) =>
      company.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      company.companyName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderCompany = filteredCompanies.map((company, index) => (
    <tr className="border-b border-gray-200" key={company.id}>
      <td scope="row" className="px-6 py-4">
        {index + 1 + (currentPage - 1) * perPage}
      </td>
      <td
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50"
      >
        {company.companyName}
      </td>
      <td className="px-6 py-4">{company.name}</td>
      <td className="px-6 py-4 bg-gray-50">{company.location}</td>
      {recentCompaniesFilter === "recentCompany" && (
        <td className="px-6 py-4">{company.createdAt ? format(new Date(company.createdAt), 'dd/MM/yyyy') : ""}</td>
      )}
      <td className={`px-6 py-4 ${recentCompaniesFilter === "recentCompany" ? "bg-gray-50" : ""}`}>
        <button
          type="button"
          className="text-white bg-gradient-to-r from-[#8697C4] via-[#8595C4] to-[#8697C4] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2"
        >
          <FaEye />
        </button>
        <button
          type="button"
          className="text-white bg-gradient-to-r from-[#8697C4] via-[#8595C4] to-[#8697C4] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2"
          onClick={() => handleEditProfile(company.id)}
        >
          <FaRegEdit />
        </button>
        <button
          type="button"
          className="text-white bg-gradient-to-r from-[#8697C4] via-[#8595C4] to-[#8697C4] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2"
          onClick={() => handleViewCompany(company.id)}
        >
          <FaArrowRight />
        </button>
      </td>
    </tr>
  ));

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredCompanies.length / perPage);

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pageNumbers = [];
    for (
      let i = Math.max(1, currentPage - 1);
      i <= Math.min(totalPages, currentPage + 1);
      i++
    ) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex mt-4">
        {currentPage > 1 && (
          <button
            onClick={() => paginate(currentPage - 1)}
            className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-[#8697C4] focus:ring-opacity-50 mx-1"
          >
            &lt;
          </button>
        )}
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-4 py-2 ${currentPage === number
              ? "bg-gradient-to-br from-[#8697C4] to-[#8595C3] text-white"
              : "bg-white border text-neutral-800"
              } rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-[#8697C4] focus:ring-opacity-50 mx-1`}
          >
            {number}
          </button>
        ))}
        {currentPage < totalPages && (
          <button
            onClick={() => paginate(currentPage + 1)}
            className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-[#8697C4] focus:ring-opacity-50 mx-1"
          >
            &gt;
          </button>
        )}
      </div>
    );
  };

  const handleViewCompany = (companyID) => {
    navigate(`/admin/viewcompany/${companyID}`);
  }


  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container mx-auto px-5 md:px-3 h-full">
            <div className="border mt-4 p-2">
              <div className="flex justify-between items-center mb-5">
                <h2 className="text-center text-3xl font-bold">Company List</h2>
                <Link
                  to="/admin/add-company"
                  className="bg-[#8697C4] hover:bg-[#8595C3] text-white font-bold py-2 px-4 rounded"
                >
                  Add Company
                </Link>
              </div>

              <div className="flex justify-end items-center py-2.5 pr-2.5 pl-5 mt-6 bg-white rounded max-md:flex-wrap max-md:max-w-full">
                <div className="flex flex-col justify-end sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full">
                  <input
                    type="text"
                    placeholder="Search by Company or Name"
                    className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <select
                    className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                    value={recentCompaniesFilter}
                    onChange={(e) => setRecentCompaniesFilter(e.target.value)}
                  >
                    <option value="All">All Companies</option>
                    <option value="recentCompany">Recent Companies</option>
                  </select>
                </div>
              </div>
              <div className="overflow-auto mt-3">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="text-xs text-gray-700 font-bold border-t border-gray-200 text-left uppercase">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm tracking-wider"
                      >
                        S.N.
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-50 text-sm uppercase tracking-wider"
                      >
                        Company Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-50 text-sm uppercase tracking-wider"
                      >
                        Location
                      </th>
                      {recentCompaniesFilter === "recentCompany" && (
                        <th scope="col" className="px-6 py-3 text-sm uppercase tracking-wider">
                          Date of Creation
                        </th>
                      )}
                      <th
                        scope="col"
                        className={`px-6 py-3 text-sm uppercase tracking-wider ${recentCompaniesFilter === "recentCompany" ? "bg-gray-50" : ""}`}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {renderCompany}
                  </tbody>
                </table>
              </div>
              {/* <div>{renderPageNumbers}</div> */}
              <div>{renderPagination()}</div>
              <div className="text-gray-600 text-sm mb-4 text-end">
                {`Showing ${filteredCompanies.length} out of ${companies.length} matches`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
