import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import '../components/style/TermsAndCondition.css'

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="relative">
        <Header />
        <div id="privacy" className="container m-auto px-20">
          <div className="mx-auto p-6 bg-white shadow-md rounded-md">
            <h1 className="text-3xl font-sans font-bold mb-4 text-center" id="privacytitle">
              Medivirt Privacy Policy
            </h1>

            <div>
              <h2 className="text-lg font-sans font-bold my-2">
                Effective Date: August 10, 2024.
              </h2>
              <p className="mb-4 mt-2 font-sans">
                Medivirt ("we," "our," or "us") values the privacy of our users,
                including doctors, pharmaceutical companies, and other
                stakeholders. This Privacy Policy outlines how we collect, use,
                store, and protect your personal information when you use our
                platform
              </p>
            </div>

            <div>
              <h2 className="text-xl font-sans font-semibold mb-2">
                1. Information We Collect
              </h2>
              <p className="mb-4 font-sans">
                <li className="text-black font-sans">
                  <span className="font-medium">Personal Information:</span>
                  When you create an account on Medivirt, we collect personal
                  information such as your name, email address, professional
                  credentials, and other details necessary for profile setup.
                </li>
                <li className="text-black font-sans">
                  <span className="font-medium">Profile Information:</span> You
                  are responsible for maintaining the confidentiality of your
                  account and password and for all activities that occur under
                  your account.
                </li>
                <li className="text-black font-sans">
                  <span className="font-medium">
                    Meeting and Communication Data:
                  </span>
                  We collect information about your scheduled meetings,
                  communication activities (including instant messaging and
                  video calls), and participation in surveys or advisory roles.
                </li>
                <li className="text-black font-sans">
                  <span className="font-medium">Usage Data:</span>
                  We collect data on how you interact with our platform,
                  including the pages you visit, features you use, and the time
                  spent on the platform. This includes performance metrics
                  related to your interactions with pharmaceutical companies.
                </li>
                <li className="text-black font-sans">
                  <span className="font-medium">
                    Device and Log Information:
                  </span>
                  We collect information about the device you use to access our
                  platform, including your IP address, browser type, and
                  operating system.
                </li>
              </p>
            </div>

            <div>
              <h2 className="text-xl font-sans font-semibold mb-2">
                2. How We Use Your Information
              </h2>
              <p className="mb-4 font-sans">
                Facilitate Platform Services: We use your personal and profile
                information to provide and manage the services on the Medivirt
                platform, including scheduling meetings, enabling communication
                with pharmaceutical companies, and providing access to
                educational resources.
                <li className="text-black font-sans">
                  <span className="font-medium">Improve User Experience:</span>
                  We use usage data to analyze and improve our platform's
                  functionality, ensuring that we provide the most efficient and
                  userfriendly experience.
                </li>
                <li className="text-black font-sans">
                  <span className="font-medium">Compensation Management:</span>{" "}
                  We use your participation data to manage compensation for your
                  time and contributions, as specified by the pharmaceutical
                  companies.
                </li>
                <li className="text-black font-sans">
                  <span className="font-medium">
                    Communication and Notifications:
                  </span>
                  We use your information to send you notifications and alerts
                  about upcoming meetings, new information, and platform
                  updates.
                </li>
                <li className="text-black font-sans">
                  <span className="font-medium">Security and Compliance:</span>
                  We use your information to maintain the security and integrity
                  of our platform, comply with legal obligations, and enforce
                  our terms of service.
                </li>
              </p>
            </div>

            <div>
              <h2 className="text-xl font-sans font-semibold mb-2">
                3. Data Sharing and Disclosure
              </h2>
              <p className="mb-4 font-sans">
                <li className="text-black font-sans">
                  <span className="font-medium">Pharmaceutical Companies:</span>
                  We share relevant profile information and communication data
                  with pharmaceutical companies you engage with on the platform.
                  This includes meeting schedules, participation records, and
                  communication logs.
                </li>
                <li className="text-black font-sans">
                  <span className="font-medium">Service Providers:</span> We may
                  share your information with thirdparty service providers who
                  assist us in operating the platform, such as hosting
                  providers, payment processors, and security services. These
                  providers are obligated to maintain the confidentiality and
                  security of your information.
                </li>
                <li className="text-black font-sans">
                  <span className="font-medium">Legal Compliance:</span>
                  We may disclose your information if required by law,
                  regulation, or legal process, or if necessary to protect our
                  rights, property, or users.
                </li>
              </p>
            </div>

            <div>
              <h2 className="text-xl font-sans font-semibold mb-2">
                4. Data Security
              </h2>
              <p className="mb-4 font-sans">
                <li className="text-black font-sans">
                  We implement advanced security measures to protect your
                  personal information from unauthorized access, use, or
                  disclosure. This includes encryption, secure server
                  technologies, and regular security audits.
                </li>
              </p>
            </div>

            <div>
              <h2 className="text-xl font-sans font-semibold mb-2">
                5. Your Rights and Choices
              </h2>
              <p className="mb-4 font-sans">
                <li className="text-black font-sans">
                  <span className="font-medium">Access and Update:</span>
                  You can access and update your personal and profile
                  information through your account settings at any time.
                </li>
                <li className="text-black font-sans">
                  <span className="font-medium">Data Deletion:</span> You may
                  request the deletion of your account and personal information
                  by contacting us at <a href="mailto:meet@medivirt.com" target="_blank" className="text-blue-800 underline">meet@medivirt.com.</a> Please note that some
                  information may be retained for legal or security purposes.
                </li>
                <li className="text-black font-sans">
                  <span className="font-medium">
                    Communication Preferences:
                  </span>
                  You can manage your communication preferences, including
                  opting out of nonessential notifications, through your account
                  settings.
                </li>
              </p>
            </div>

            <div>
              <h2 className="text-xl font-sans font-semibold mb-2">
                6. Changes to This Privacy Policy
              </h2>
              <p className="mb-4 font-sans">
                <li className="text-black font-sans">
                  We may update this Privacy Policy from time to time. We will
                  notify you of any significant changes by posting the updated
                  policy on our platform and updating the effective date.
                </li>
              </p>
            </div>

            <div>
              <h2 className="text-xl font-sans font-semibold mb-2">
                7. Contact Us
              </h2>
              <p className="mb-4 font-sans">
                <li className="text-black font-sans">
                  If you have any questions or concerns about this Privacy
                  Policy or our data practices, please contact us at <a href="mailto:meet@medivirt.com" target="_blank" className="text-blue-800 underline">
                  meet@medivirt.com.</a>
                </li>
              </p>
            </div>

            <p className="mb-4 mt-2">
              By using Medivirt, you agree to the terms of this Privacy Policy.
              We are committed to protecting your privacy and ensuring the
              security of your information as you engage with our platform.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
