import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import '../components/style/TermsAndCondition.css'

const TermsAndConditions = () => {
  return (
    <div className="relative">
      <Header />
      <div id="term" className="container m-auto px-20">
        <div className="mx-auto p-6 bg-white shadow-md rounded-md">
          <h1 className="text-3xl font-sans font-bold mb-4 text-center">
            Terms and Conditions
          </h1>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              1. Acceptance of Terms
            </h2>
            <p className="mb-4 font-sans">
              By accessing and using Medivirt’s services, you agree to comply
              with and be bound by these terms and conditions. If you do not
              agree with any part of these terms, you should not use our
              services.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              2. Description of Services
            </h2>
            <p className="mb-4 font-sans">
              Medivirt provides a platform that connects pharma companies and
              doctors. The services include scheduling meetings, chatting, and
              performance analytics.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              3. Eligibility
            </h2>
            <p className="mb-4 font-sans">
              To use Medivirt, you must be at least 18 years old and have the
              authority to enter into legally binding agreements.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              4. User Accounts
            </h2>
            <p className="mb-4 font-sans">
              <li className="text-black font-sans">
                <span className="font-medium">Registration:</span> Users must create an account to access
                Medivirt’s services. You agree to provide accurate and complete
                information during registration.
              </li>
              <li className="text-black font-sans">
                <span className="font-medium">Account Security:</span> You are responsible for maintaining the
                confidentiality of your account and password and for all
                activities that occur under your account.
              </li>
              <li className="text-black font-sans">
                <span className="font-medium">Account Termination:</span> Medivirt reserves the right to
                terminate or suspend your account if any information provided
                during registration or thereafter proves to be inaccurate, not
                current, or incomplete, or for any other breach of these terms.
              </li>
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              5. Use of Services
            </h2>
            <p className="mb-4 font-sans">
              <li className="text-black font-sans">
                <span className="font-medium">Permitted Use:</span> You agree to use Medivirt only for lawful
                purposes and in accordance with these terms.
              </li>
              <li className="text-black font-sans">
                <span className="font-medium">Prohibited Use:</span> You agree not to use Medivirt for any
                unlawful or prohibited activities, including but not limited to
                spamming, harassment, or infringement of intellectual property
                rights.
              </li>
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">6. Content</h2>
            <p className="mb-4 font-sans">
              <li className="text-black font-sans">
                <span className="font-medium">User Content:</span> Users may post content on Medivirt, such as
                reviews and messages. You retain ownership of your content but
                grant Medivirt a license to use, modify, and distribute your
                content.
              </li>
              <li className="text-black font-sans">
                <span className="font-medium">Prohibited Content:</span> You agree not to post content that is
                illegal, harmful, or violates the rights of others.
              </li>
              <li className="text-black font-sans">
                <span className="font-medium">Content Removal:</span> Medivirt reserves the right to remove
                any content that violates these terms or is deemed inappropriate
                at its sole discretion.
              </li>
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              7. Privacy Policy
            </h2>
            <p className="mb-4">
              Medivirt’s collection and use of personal information are governed
              by our Privacy Policy, which is incorporated into these terms by
              reference.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              8. Intellectual Property
            </h2>
            <p className="mb-4 font-sans">
              All content on Medivirt, including but not limited to text,
              graphics, logos, and software, is the property of Medivirt or its
              licensors and is protected by intellectual property laws.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              9. Disclaimers and Limitation of Liability
            </h2>
            <p className="mb-4 font-sans">
              <li className="text-black">
                <span className="font-medium">Disclaimers:</span> Medivirt provides its services on an “as-is”
                and “as-available” basis without warranties of any kind, either
                express or implied, including, but not limited to, implied
                warranties of merchantability, fitness for a particular purpose,
                or non-infringement.
              </li>
              <li className="text-black">
                <span className="font-medium">Limitation of Liability:</span> Medivirt will not be liable for
                any indirect, incidental, special, consequential, or punitive
                damages, including but not limited to, loss of profits, data,
                use, goodwill, or other intangible losses, resulting from (i)
                your access to or use of or inability to access or use the
                services; (ii) any conduct or content of any third party on the
                services; (iii) any content obtained from the services; and (iv)
                unauthorized access, use, or alteration of your transmissions or
                content, whether based on warranty, contract, tort (including
                negligence), or any other legal theory, whether or not Medivirt
                has been informed of the possibility of such damage.
              </li>
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              10. Indemnification
            </h2>
            <p className="mb-4 font-sans">
              You agree to indemnify and hold Medivirt harmless from any claims,
              losses, or damages, including reasonable attorney’s fees, arising
              out of your use of the services, your violation of these terms, or
              your violation of any rights of another.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              11. Termination
            </h2>
            <p className="mb-4 font-sans ">
              Medivirt reserves the right to terminate or suspend your account
              and access to the services at its sole discretion, without notice,
              for conduct that it believes violates these terms or is harmful to
              other users.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              12. Governing Law
            </h2>
            <p className="mb-4 font-sans">
              These terms and conditions are governed by and constructed in
              accordance with the laws of Bangalore, India, without regard to
              its conflict of law principles.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              13. Changes to Terms
            </h2>
            <p className="mb-4 font-sans">
              Medivirt reserves the right to modify these terms at any time.
              Your continued use of the services after any such changes
              constitutes your acceptance of the new terms.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              14. Dispute Resolution
            </h2>
            <p className="mb-4 font-sans">
              <li className="text-black font-sans">
                <span className="font-medium">Informal Resolution:</span> Before filing a claim against
                Medivirt, you agree to try to resolve the dispute informally by
                contacting us at <a href="mailto:meet@medivirt.com" target="_blank" rel="noreferrer" className="text-blue-800">
                meet@medivirt.com</a> We will attempt to resolve
                the dispute informally by contacting you via email. If a dispute
                is not resolved within 30 days of submission, you or Medivirt
                may bring a formal proceeding.
              </li>
              <li className="text-black font-sans">
                <span className="font-medium">Arbitration:</span> Any dispute arising out of or relating to
                these terms or the services will be finally settled by
                arbitration in accordance with the rules of [Arbitration Body]
                and shall be conducted in Bangalore, India. The arbitration
                shall be conducted in English. The decision of the arbitrator
                shall be binding and enforceable in any court of competent
                jurisdiction.
              </li>
              <li className="text-black font-sans">
                <span className="font-medium">Class Action Waiver:</span> You agree that any dispute will be
                resolved on an individual basis and you may not bring a claim as
                a plaintiff or a class member in a class, consolidated, or
                representative action.
              </li>
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              15. Miscellaneous
            </h2>
            <p className="mb-4 font-sans">
              <li className="text-black font-sans">
                <span className="font-medium">Entire Agreement:</span> These terms constitute the entire
                agreement between you and Medivirt regarding the use of the
                services.
              </li>
              <li className="text-black font-sans">
                <span className="font-medium">Severability:</span> If any provision of these terms is found to
                be invalid or unenforceable, the remaining provisions will
                remain in full force and effect.
              </li>
              <li className="text-black font-sans">
                <span className="font-medium">Waiver:</span> No waiver of any term shall be deemed a further
                or continuing waiver of such term or any other term, and
                Medivirt’s failure to assert any right or provision under these
                terms shall not constitute a waiver of such right or provision.
              </li>
            </p>
          </div>

          <div>
            <h2 className="text-xl font-sans font-semibold mb-2">
              16. Contact Information
            </h2>
            <p className="mb-4 font-sans">
              For any questions or concerns about these terms and conditions,
              please contact us at <a href="mailto:meet@medivirt.com" target="_blank" rel="noreferrer" className="text-blue-800">
                meet@medivirt.com
              </a>
            </p>
          </div>

          <h1 className="text-xl font-sans font-bold mb-2">Acknowledgment</h1>
          <p className="font-sans">
            By using Medivirt, you acknowledge that you have read and understand
            these terms and conditions and agree to be bound by them.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
