import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import MedivirtLogo from '../../assets/img/Medivirt.png';
import {useAuth} from '../AuthContext';
import { IoIosNotifications } from "react-icons/io";


const AdminNavbar = () => {
    const [logoutState, setLogoutState] = useState(false);
    const navigate = useNavigate();
    const {logout} = useAuth();

    const handleLogout = () => {
        logout();
        // signOut(auth)
        //     .then(() => {
        //         console.log('User logged out successfully.');
        //         navigate('/');
        //     })
        //     .catch(error => {
        //         console.error('Error logging out:', error);
        //     });
    };

    return (
        <nav style={styles.navbar}>
            <div style={styles.navContent}>
                <NavLink to="/" style={styles.logoLink}>
                    <img id="logo" style={styles.logo} alt="Medivirt" src={MedivirtLogo} />
                </NavLink>
                <div style={styles.userActions}>
                <IoIosNotifications style={styles.notification} className='mr-5' />
                    <FaUser
                        style={styles.userIcon}
                        onClick={() => setLogoutState(!logoutState)}
                    />
                    {logoutState && (
                        <div style={styles.dropdown}>
                            <button
                                style={styles.dropdownButton}
                                onClick={handleLogout}
                            >
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

const styles = {
    navbar: {
        backgroundColor: '#3D52A1',
        borderBottom: '1px solid #ddd',
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 40,
    },
    navContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0.75rem 1.5rem',
    },
    logoLink: {
        flexShrink: 0,
    },
    logo: {
        height: '2rem',
    },
    userActions: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    userIcon: {
        color: 'white',
        fontSize: '1.5rem',
        cursor: 'pointer',
    },
    notification: {
        color: 'white',
        fontSize: '1.8rem',
        cursor: 'pointer',
    },
    dropdown: {
        position: 'absolute',
        top: '100%',
        right: 0,
        marginTop: '0.5rem',
        width: '10rem',
        backgroundColor: 'white',
        borderRadius: '0.375rem',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)',
        zIndex: 10,
    },
    dropdownButton: {
        display: 'block',
        width: '100%',
        textAlign: 'left',
        padding: '0.5rem',
        color: '#4B5563',
        backgroundColor: 'white',
        border: 'none',
        cursor: 'pointer',
    },
    dropdownButtonHover: {
        backgroundColor: '#E5E7EB',
    }
};

export default AdminNavbar;