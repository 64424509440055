import React, { useState, useCallback, useEffect } from "react";
import HospitalSide from "./HospitalSide";
import HospitalNavbar from "./HospitalNavbar";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "firebase/storage";
import { useDropzone } from "react-dropzone";
import { IoMdArrowBack } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchHospitalData, fetchHospitalDoctor, editDoctorImageUpload, removeImageInEdits, updateDoctorData } from "./HospitalFirebase";


const HospitalEditDoctor = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [doctor, setDoctor] = useState({
    name: "",
    gender: "",
    email: "",
    specialist: "",
    currentPosition: "",
    location: "",
    about: "",
    experience_1: "",
    experience_2: "",
    experience_3: "",
    education_1: "",
    education_2: "",
    education_3: "",
    comment: "",
    other: "",
  });
  const [hospitalName, setHospitalName] = useState("");

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const doctorId = location.state?.doctorId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const hospitalData = await fetchHospitalData(id);
        setHospitalName(hospitalData.name);

        const {doctorData, imageUrl} = await fetchHospitalDoctor(doctorId);
        setDoctor(doctorData);
        setImageUrl(imageUrl);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    }
    fetchData();
  }, [doctorId, id]);

  const handleImageUpload = async (acceptedFile) => {
    const file = acceptedFile;
    setImageFile(file);
    try {
      const downloadURL = await editDoctorImageUpload(file);
      setImageUrl(downloadURL);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleImageRemove = async () => {
    try {
      if (doctor.image) {
        await removeImageInEdits(doctor.image);
        toast.error("Image removed successfully!", {
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error removing image:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDoctorData(doctorId, doctor, imageFile);
      toast.success("Data updated successfully!", {
        autoClose: 2000,
      });

      navigate(`/hospital/doctors/${id}`);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      handleImageUpload(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  return (
    <>
      <div className="flex flex-col h-screen">
        <HospitalNavbar />
        <div className="flex flex-1 mt-[4.2rem]">
          <HospitalSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
          <div
            className={`overflow-y-auto flex-1 transition-all duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
              }`}
          >
            <div className="container px-4 mx-auto my-10">
              <div className="w-full">
                <form
                  onSubmit={handleSubmit}
                  className="bg-white shadow rounded px-8 pt-6 pb-8 mb-4"
                >
                  <div className="flex items-center justify-between mb-4">
                    <button
                      type="button"
                      onClick={() => navigate(-1)}
                      className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
                    >
                      <IoMdArrowBack className="h-6 w-6 font-bold text-[#0D47A1]" />
                    </button>
                    <h2 className="flex-grow text-2xl mb-5 font-bold text-center uppercase">
                      Edit Doctor
                    </h2>
                  </div>

                  <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                        value={doctor.name || ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, name: e.target.value })
                        }
                        placeholder=" "
                        required
                      />
                      <label
                        htmlFor="name"
                        className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Name
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <select
                        name="gender"
                        id="gender"
                        value={doctor.gender || ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, gender: e.target.value })
                        }
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                      <label
                        htmlFor="gender"
                        className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Gender
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                        value={doctor.email || ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, email: e.target.value })
                        }
                        placeholder=" "
                      />
                      <label
                        htmlFor="email"
                        className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Email
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <select
                        name="specialist"
                        id="specialist"
                        value={doctor.specialist || ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, specialist: e.target.value })
                        }
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                      >
                        <option value="">Select Specialist</option>
                        <option value="Orthopaedic">Orthopaedic</option>
                        <option value="Cardiologist">Cardiologist</option>
                        <option value="Gynaecologist">Gynaecologist</option>
                        <option value="Radiologist">Radiologist</option>
                        <option value="Dermatologist">Dermatologist</option>
                        <option value="Oncology">Oncology</option>
                        <option value="Neurology">Neurology</option>
                        <option value="Urology">Urology</option>
                        <option value="Ophthalmology">Ophthalmology</option>
                        <option value="Paediatric">Paediatric</option>
                      </select>
                      <label
                        htmlFor="name"
                        className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Specialist
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="currentPosition"
                        id="currentPosition"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                        value={doctor.currentPosition || ""}
                        onChange={(e) =>
                          setDoctor({
                            ...doctor,
                            currentPosition: e.target.value,
                          })
                        }
                        placeholder=" "
                      />
                      <label
                        htmlFor="currentPosition"
                        className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Current Position
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="hospitalName"
                        id="hospitalName"
                        className="block py-2.5 px-0 w-full text-sm text-gray-400 font-medium bg-gray-50 border-b-2 appearance-none rounded-md focus:outline-none focus:ring-0 peer cursor-not-allowed"
                        value={hospitalName}
                        readOnly
                        placeholder=" "
                      />
                      <label
                        htmlFor="hospitalName"
                        className="absolute text-base font-bold text-gray-900 duration-300 transform -translate-y-6 scale-75 top-2 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:left-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Hospital Name
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <select
                        name="location"
                        id="location"
                        value={doctor.location || ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, location: e.target.value })
                        }
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                      >
                        <option value="">Select Location</option>
                        <option value="Bangalore">Bangalore</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Mumbai">Mumbai</option>
                        <option value="Kolkata">Kolkata</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Chennai">Chennai</option>
                      </select>
                      <label
                        htmlFor="location"
                        className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Location
                      </label>
                    </div>
                  </div>

                  <div className="mb-4">
                    <label htmlFor="image" className="text-sm font-bold">
                      Image Upload
                    </label>
                    <div
                      {...getRootProps()}
                      className="dropzone border-dashed border-2 border-gray-300 rounded-lg p-4 mb-6 text-center cursor-pointer relative"
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the files here...</p>
                      ) : (
                        <p>
                          Drag 'n' drop a file here, or click to select files
                        </p>
                      )}
                      {imageUrl && (
                        <div className="relative z-0 w-full mt-4">
                          <img
                            src={imageUrl}
                            alt="Uploaded"
                            style={{
                              display: "block",
                              maxHeight: "200px",
                              margin: "10px auto",
                              maxWidth: "100%",
                            }}
                          />
                          <button
                            type="button"
                            onClick={handleImageRemove}
                            className="text-white bg-red-500 hover:bg-red-600 font-medium rounded-lg text-sm py-2 px-4 mt-2"
                          >
                            Remove Image
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <textarea
                      name="about"
                      id="about"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                      value={doctor.about || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, about: e.target.value })
                      }
                      placeholder=" "
                    ></textarea>
                    <label
                      htmlFor="about"
                      className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      About
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <textarea
                      name="experience_1"
                      id="experience_1"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                      value={doctor.experience_1 || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, experience_1: e.target.value })
                      }
                      placeholder=" "
                    ></textarea>
                    <label
                      htmlFor="experience_1"
                      className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Experience 1
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <textarea
                      name="experience_2"
                      id="experience_2"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                      value={doctor.experience_2 || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, experience_2: e.target.value })
                      }
                      placeholder=" "
                    ></textarea>
                    <label
                      htmlFor="experience_2"
                      className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Experience 2
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <textarea
                      name="experience_3"
                      id="experience_3"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                      value={doctor.experience_3 || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, experience_3: e.target.value })
                      }
                      placeholder=" "
                    ></textarea>
                    <label
                      htmlFor="experience_3"
                      className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Experience 3
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <textarea
                      name="education_1"
                      id="education_1"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                      value={doctor.education_1 || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, education_1: e.target.value })
                      }
                      placeholder=" "
                    ></textarea>
                    <label
                      htmlFor="education_1"
                      className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Education 1
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <textarea
                      name="education_2"
                      id="education_2"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                      value={doctor.education_2 || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, education_2: e.target.value })
                      }
                      placeholder=" "
                    ></textarea>
                    <label
                      htmlFor="education_2"
                      className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Education 2
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <textarea
                      name="education_3"
                      id="education_3"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                      value={doctor.education_3 || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, education_3: e.target.value })
                      }
                      placeholder=" "
                    ></textarea>
                    <label
                      htmlFor="education_3"
                      className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Education 3
                    </label>
                  </div>
                  <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="comment"
                        id="comment"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                        value={doctor.comment || ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, comment: e.target.value })
                        }
                        placeholder=" "
                      />
                      <label
                        htmlFor="comment"
                        className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Comment If Any
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="other"
                        id="other"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                        value={doctor.other || ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, other: e.target.value })
                        }
                        placeholder=" "
                      />
                      <label
                        htmlFor="other"
                        className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Other
                      </label>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="text-white bg-[#0D47A1] focus:ring-4 focus:outline-none focus:ring-[#0D47A1] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                  >
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};

export default HospitalEditDoctor;
