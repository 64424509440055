import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import SalesSide from "./SalesSide";
import SalesNavbar from "./SalesNavbar";
import { IoMdArrowBack } from "react-icons/io";
import { AiOutlineSchedule } from "react-icons/ai";
import { useCollapse } from 'react-collapsed';
import '../style/Timeline.css';
import { fetchTimelineData } from "./SalesFirestore";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const SalesTimeline = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [meetings, setMeetings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const doctorId = location.state?.doctorId;

    useEffect(() => {
        const fetchMeetings = async () => {
            try {
                const meetingData = await fetchTimelineData(doctorId, id);
                setMeetings(meetingData || []);
                console.log(meetingData);
            } catch (error) {
                setError("Error fetching meetings: " + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchMeetings();
    }, [doctorId, id]);

    const getMeetingClass = (meeting) => {
        if (meeting.status === 'completed') {
            return 'completed';
        } else if (meeting.status === 'missed') {
            return 'missed';
        } else {
            return 'upcoming';
        }
    };

    return (
        <div className="flex flex-col h-screen">
            <SalesNavbar />
            <div className="flex flex-1 mt-[4.2rem]">
                <SalesSide
                    open={sidebarOpen}
                    toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
                />
                <div
                    className={`overflow-y-auto flex-1 transition-all duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
                        }`}
                >
                    <div className="container px-4 mx-auto my-10 overflow-auto">
                        <button
                            type="button"
                            onClick={() => navigate(-1)}
                            className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center mb-4"
                        >
                            <IoMdArrowBack className="h-6 w-6 font-bold text-[#0D47A1]" />
                        </button>
                        <div className="flex items-center justify-between mb-6">
                            <h2 className="text-2xl font-bold">Growth Timeline</h2>
                            <div className="flex items-center space-x-4">
                                <div className="flex items-center">
                                    <span className="dot completed-dot"></span>
                                    <span className="text-sm ml-1">Completed</span>
                                </div>
                                <div className="flex items-center">
                                    <span className="dot missed-dot"></span>
                                    <span className="text-sm ml-1">Missed</span>
                                </div>
                                <div className="flex items-center">
                                    <span className="dot upcoming-dot"></span>
                                    <span className="text-sm ml-1">Upcoming</span>
                                </div>
                            </div>
                        </div>

                        {loading && <div>Loading...</div>}
                        {error && <div>Error: {error}</div>}

                        {meetings.length === 0 ? (
                            <div
                                className="flex-1 flex flex-col items-center justify-center text-gray-500 bg-white rounded-lg shadow-lg p-10 m-4"
                            >
                                <AiOutlineSchedule className="text-gray-400 text-6xl mb-4" />
                                <p className="text-lg font-semibold">No Meeting Found</p>
                            </div>
                        ) : (
                            <div className="timeline-box">
                                <ul className="timeline">
                                {meetings.map((meeting, index) => (
                                    <TimelineItem
                                        key={index}
                                        meeting={meeting}
                                        meetingClass={getMeetingClass(meeting)}
                                        itemNumber={index + 1} // Update item number
                                    />
                                ))}
                                </ul>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};

const TimelineItem = ({ meeting, meetingClass, itemNumber }) => {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    const isDisabled = meetingClass === 'missed' || meetingClass === 'upcoming';

    let roleDisplay;
    if (meeting.assignedRole === 'Sales Head') {
        roleDisplay = 'SH';
    } else if (meeting.assignedRole === 'Medical Representative') {
        roleDisplay = 'MR';
    } else {
        roleDisplay = meeting.assignedRole;
    }

    return (
        <li className={`timeline-item ${meetingClass}`} style={{ counterReset: `item ${itemNumber - 1}` }}>
            <div className="timeline-content">
                <div className="timeline-description">
                    <div className="timeline-name">
                        <h3>{meeting.assignedName} ({roleDisplay})</h3>
                    </div>
                    <p>{meeting.formattedDate} - {meeting.formattedTime}</p>
                    <Tippy
                        content="View Feedback"
                      >
                    <button
                        {...getToggleProps()}
                        className={`expand-button ml-3 mr-3 ${isDisabled ? 'disabled' : ''}`}
                        disabled={isDisabled}
                    >
                        {isExpanded ? <AiOutlineUp /> : <AiOutlineDown />}
                    </button>
                    </Tippy>
                </div>
                <div {...getCollapseProps()}>
                    <div className="timeline-details">
                        <p>{meeting.doctorName} : {meeting.doctorFeedback}</p>
                        <p>{meeting.assignedName} : {meeting.mrFeedback}</p>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default SalesTimeline;
