import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase'; // Ensure firebase is correctly initialized

// Create AuthContext
const AuthContext = createContext();

const INACTIVITY_THRESHOLD = 15 * 60 * 1000; // 15 minutes

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const [isManagerLoggedIn, setIsManagerLoggedIn] = useState(false);

  const login = (role) => {
    if (role === 'Admin') {
      setIsAdminLoggedIn(true);
      localStorage.setItem('isAdminLoggedIn', JSON.stringify(true));
    } else if (role === 'Growth Manager') {
      setIsManagerLoggedIn(true);
      localStorage.setItem('isManagerLoggedIn', JSON.stringify(true));
    }
  };

  const logout = async () => {
    try {
      setIsAdminLoggedIn(false);
      setIsManagerLoggedIn(false);
      setCurrentUser(null);
      localStorage.removeItem('currentUser');
      localStorage.removeItem('isAdminLoggedIn');
      localStorage.removeItem('isManagerLoggedIn');
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Error during sign out:", error.message);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        localStorage.setItem('currentUser', JSON.stringify({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
        }));
        console.log('User is authenticated:', user);
      } else {
        setCurrentUser(null);
        localStorage.removeItem('currentUser');
        console.log('No user is authenticated');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const AdminloggedIn = JSON.parse(localStorage.getItem('isAdminLoggedIn'));
    const ManagerloggedIn = JSON.parse(localStorage.getItem('isManagerLoggedIn'));
    setIsAdminLoggedIn(AdminloggedIn || false);
    setIsManagerLoggedIn(ManagerloggedIn || false);
  }, []);

  useEffect(() => {
    let inactivityTimer;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(logout, INACTIVITY_THRESHOLD);
    };

    const handleUserActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);

    resetInactivityTimer();

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
    };
  }, []);

  const contextValue = {
    currentUser,
    setCurrentUser,
    isAdminLoggedIn,
    isManagerLoggedIn,
    login,
    logout
  };

  return <AuthContext.Provider value={contextValue}>{!loading && children}</AuthContext.Provider>;
};

// Custom hook to use AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
