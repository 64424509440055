import React, { useState } from 'react';
import { AiOutlineMenu, AiOutlineUser } from 'react-icons/ai';
import { NavLink, useLocation, Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../components/firebase';
import { useAuth } from './AuthContext';
import MedivirtLogo from '../assets/img/Medivirt.png';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import '../../src/components/style/tooltipStyles.css';


const Header = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const { currentUser } = useAuth();

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('User logged out successfully.');
        window.location.href = '/';
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
  };

  const renderIndicator = path => {
    const isActive = location.pathname === path;
    return isActive ? <div className="absolute bottom-0 top-9 left-1/2 transform -translate-x-1/2 h-[10px] w-16 bg-white rounded-t-lg" /> : null;
  };

  return (
    <div className="relative w-full h-16 bg-[#1E2955] flex justify-between items-center px-6 md:px-10 lg:px-18">
        <NavLink to="/" className="flex-shrink-0">
          <img className="h-8" alt="Medivirt" src={MedivirtLogo} />
        </NavLink>

      <div className="hidden md:flex justify-center space-x-6 flex-1">
        <Tippy content="Home" className="custom-tooltip">
          <NavLink to="/" className="font-semibold text-white relative" onClick={closeMenu}>
            HOME
            {renderIndicator('/')}
          </NavLink>
        </Tippy>
        <Tippy content="View All Doctors" className="custom-tooltip">
          <NavLink to="/doctorlist" className="font-semibold text-white relative" onClick={closeMenu}>
            DOCTORS
            {renderIndicator('/doctorlist')}
          </NavLink>
        </Tippy>
        <Tippy content="View All Companies" className="custom-tooltip">
          <NavLink to="/companylist" className="font-semibold text-white relative" onClick={closeMenu}>
            COMPANIES
            {renderIndicator('/companylist')}
          </NavLink>
        </Tippy>
        <Tippy content="Pricing" className="custom-tooltip">
          <NavLink to="/" className="font-semibold text-white relative cursor-not-allowed" onClick={closeMenu}>
            PRICING
            {renderIndicator('/price')}
          </NavLink>
        </Tippy>
        <Tippy content="Join Now" className="custom-tooltip">
          <NavLink to="/signup" className="font-semibold text-white relative" onClick={closeMenu}>
            JOIN NOW
            {renderIndicator('/signup')}
          </NavLink>
        </Tippy>
      </div>

      <div className="flex items-center space-x-4">
        <div className="hidden md:block relative">
          {currentUser ? (
            <>
              <div className="flex items-center">
                <Tippy content="User Menu" className="custom-tooltip">
                  <AiOutlineUser className="w-6 h-6 text-white cursor-pointer" onClick={() => setMenuOpen(!menuOpen)} />
                </Tippy>
              </div>
              {menuOpen && (
                <div className="absolute right-0 mt-2 w-40 bg-white rounded shadow z-10">
                  <button className="block w-full text-left p-2 hover:bg-gray-200" onClick={handleLogout}>Logout</button>
                </div>
              )}
            </>
          ) : (
            <>
              <Tippy content="User Menu" className="custom-tooltip">
                <AiOutlineUser className="w-6 h-6 text-white cursor-pointer" onClick={() => setMenuOpen(!menuOpen)} />
              </Tippy>
              {menuOpen && (
                <div className="absolute right-0 mt-2 w-40 bg-white rounded shadow z-10">
                  <NavLink to="/login" className="block w-full text-left p-2 hover:bg-gray-200" onClick={closeMenu}>Login</NavLink>
                  <NavLink to="/signup" className="block w-full text-left p-2 hover:bg-gray-200" onClick={closeMenu}>Sign Up</NavLink>
                </div>
              )}
            </>
          )}
        </div>
        <Tippy content="Menu">
          <AiOutlineMenu className="w-6 h-6 text-white cursor-pointer block md:hidden" onClick={() => setMenuOpen(!menuOpen)} />
        </Tippy>
      </div>

      {menuOpen && (
        <div className="absolute top-16 left-0 right-0 bg-[#3D52A1] z-20 md:hidden">
          <div className="flex flex-col items-center py-4 space-y-4">
            <Tippy content="Home">
              <NavLink to="/" className="text-white" onClick={closeMenu}>HOME</NavLink>
            </Tippy>
            <Tippy content="Doctors">
              <NavLink to="/doctorlist" className="text-white" onClick={closeMenu}>DOCTORS</NavLink>
            </Tippy>
            <Tippy content="Companies">
              <NavLink to="/companylist" className="text-white" onClick={closeMenu}>COMPANIES</NavLink>
            </Tippy>
            <Tippy content="Pricing">
              <NavLink to="/price" className="text-white" onClick={closeMenu}>PRICING</NavLink>
            </Tippy>
            <Tippy content="Join Now">
              <NavLink to="/signup" className="text-white" onClick={closeMenu}>JOIN NOW</NavLink>
            </Tippy>
            {currentUser ? (
              <Tippy content="Logout">
                <button className="text-white" onClick={handleLogout}>Logout</button>
              </Tippy>
            ) : (
              <Tippy content="Login">
                <NavLink to="/login" className="text-white" onClick={closeMenu}>Login</NavLink>
              </Tippy>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
