import React, { useState, useEffect } from "react";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import { useNavigate, Navigate } from "react-router-dom";
import "firebase/storage";
import { IoMdArrowBack } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addHospital } from "./AdminFirebase";
import { useAuth } from "../AuthContext";
import { State, City } from "country-state-city";

const AddHospital = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [hospitalName, setHospitalName] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [address, setAddress] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const { isAdminLoggedIn } = useAuth();

    const navigate = useNavigate();

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        // Get states for India
        const fetchedStates = State.getStatesOfCountry("IN"); // India country code
        setState(fetchedStates);
    }, []);

    useEffect(() => {
        if (selectedState) {
            // Get cities for selected state in India
            const fetchedCities = City.getCitiesOfState("IN", selectedState); // India country code
            setCity(fetchedCities);
        } else {
            setCity([]);
        }
    }, [selectedState]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const stateName = state.find(state => state.isoCode === selectedState)?.name || "";
        // const cityName = city.find(city => city.geonameId === selectedCity)?.name || "";

        const hospitalData = {
            hospitalName,
            name,
            email,
            contactNo,
            address,
            state: stateName,
            city: selectedCity,
            createdAt: new Date().toISOString(),
        };

        try {
            const successMessage = await addHospital(hospitalData);

            if (successMessage === true) {
                toast.success("Hospital Added Successfully!", {
                    autoClose: 2000,
                });
            }

            // alert(successMessage);
            navigate("/admin/hospital");
        } catch (error) {
            console.error("Error adding document:", error);
        }
    };

    if (!isAdminLoggedIn) {
        return <Navigate to="/admin" />;
    }

    return (
        <>
            <div className="flex flex-col h-screen">
                <AdminNavbar />
                <div className="flex flex-1 mt-[4.2rem]">
                    <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
                    <div
                        className={`flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
                            }`}
                    >
                        <div className="container max-w-full text-black mx-auto my-5 px-10">
                            <div className="w-full">
                                <form
                                    onSubmit={handleSubmit}
                                    className="bg-white shadow rounded px-8 pt-6 pb-8 mb-4"
                                >
                                    <div className="flex items-center justify-between mb-4">
                                        <button
                                            type="button"
                                            onClick={() => navigate(-1)}
                                            className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
                                        >
                                            <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
                                        </button>
                                        <h2 className="flex-grow text-2xl mb-5 font-bold text-center uppercase">
                                            Add Hospital
                                        </h2>
                                    </div>

                                    <div className="grid md:grid-cols-2 md:gap-6">
                                        <div className="relative z-0 w-full mb-6 group">
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder=" "
                                                required
                                            />
                                            <label
                                                htmlFor="name"
                                                className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Name
                                            </label>
                                        </div>
                                        <div className="relative z-0 w-full mb-6 group">
                                            <input
                                                type="text"
                                                name="hospitalName"
                                                id="hospitalName"
                                                className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                                value={hospitalName}
                                                onChange={(e) => setHospitalName(e.target.value)}
                                                placeholder=" "
                                                required
                                            />
                                            <label
                                                htmlFor="hospitalName"
                                                className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Hospital Name
                                            </label>
                                        </div>
                                        <div className="relative z-0 w-full mb-6 group">
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder=" "
                                                requ
                                                ired
                                            />
                                            <label
                                                htmlFor="email"
                                                className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Email
                                            </label>
                                        </div>
                                        <div className="relative z-0 w-full mb-6 group">
                                            <input
                                                type="tel"
                                                name="contactNo"
                                                id="contactNo"
                                                className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                                value={contactNo}
                                                onChange={(e) => setContactNo(e.target.value)}
                                                placeholder=" "
                                                required
                                            />
                                            <label
                                                htmlFor="contactNo"
                                                className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Contact No.
                                            </label>
                                        </div>
                                    </div>
                                    <div className="relative z-0 w-full mb-6 group">
                                        <textarea
                                            name="address"
                                            id="address"
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            placeholder=" "
                                        ></textarea>
                                        <label
                                            htmlFor="address"
                                            className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            Address
                                        </label>
                                    </div>
                                    <div className="grid md:grid-cols-2 md:gap-6">
                                        <div className="relative z-0 w-full mb-6 group">
                                            <select
                                                name="state"
                                                id="state"
                                                className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                                value={selectedState}
                                                onChange={(e) => setSelectedState(e.target.value)}
                                                required
                                            >
                                                <option value="">Select State</option>
                                                {state.map(state => (
                                                    <option key={state.isoCode} value={state.isoCode}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label
                                                htmlFor="state"
                                                className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                State
                                            </label>
                                        </div>
                                        <div className="relative z-0 w-full mb-6 group">
                                            <select
                                                name="city"
                                                id="city"
                                                className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-purple-600 peer"
                                                value={selectedCity}
                                                onChange={(e) => setSelectedCity(e.target.value)}
                                                required
                                            >
                                                <option value="">Select City</option>
                                                {city.map((city) => (
                                                    <option key={city.geonameId} value={city.geonameId}>
                                                        {city.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label
                                                htmlFor="city"
                                                className="peer-focus:font-medium absolute text-sm text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-purple-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                City
                                            </label>
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="text-white bg-[#8697C4] hover:bg-[#8999C4] focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                                    >
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right" />
        </>
    );
};

export default AddHospital;
