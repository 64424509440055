import React, { useState, useEffect } from "react";
import { useNavigate, Link, Navigate, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import { IoMdArrowBack } from "react-icons/io";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import { fetchCompanyDataByID, deleteCompanies } from "./AdminFirebase";

const ViewCompanyDetails = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [companies, setCompanies] = useState({});
  const [companyName, setCompanyName] = useState([]);
  const navigate = useNavigate();
  const [companySetting, setCompanySetting] = useState(false);
  const { id } = useParams();
  const { isAdminLoggedIn } = useAuth();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleCompanyUsers = () => {
    navigate(`/admin/companies/${id}/users`);
  };

  const toggleCompanyMeetings = () => {
    navigate(`/admin/companies/${id}/meetings`);
  };

  const toggleCompanyCredits = () => {
    navigate(`/admin/companies/${id}/credits`);
  };

  const toggleCompanySettings = () => {
    setCompanySetting(!companySetting);
  };

  useEffect(() => {
    const fetchData = async () => {
      const companyData = await fetchCompanyDataByID(id);
      setCompanies(companyData);
      setCompanyName(companyData.companyName);
    };

    fetchData();
  }, []);

  const handleDeleteProfile = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this profile?"
    );

    if (confirmed) {
      try {
        await deleteCompanies(id);
        navigate(`/admin/companies`);
      } catch (error) {
        console.error("Error deleting company profile:", error);
      }
    }
  };

  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          <div className="container mx-auto px-5 md:px-3 h-full">
            <div className="overflow-hidden px-10 md:px-5" id="companyedits">
              <div className="flex items-center justify-between mb-4">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
                >
                  <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
                </button>
              </div>

              <div className="border shadow rounded-lg">
                <div id="companydetails" className="md:h-auto">
                  <div className="flex items-center justify-between my-5">
                    <h1
                      className="text-xl font-bold uppercase px-6 md:px-2"
                      id="companyNames"
                    >
                      {companyName} Company Details
                    </h1>
                  </div>
                  <div
                    className="flex items-center justify-between mb-2 font-medium px-6 md:px-2"
                    onClick={toggleCompanyUsers}
                  >
                    <p className="cursor-pointer">Users </p>
                    <button type="button">
                      <IoIosArrowRoundForward size={30} />
                    </button>
                  </div>
                  <hr className="mb-3 p-0 border-gray-300"></hr>
                  <div
                    className="flex items-center justify-between mb-2 font-medium px-6 md:px-2"
                    onClick={toggleCompanyMeetings}
                  >
                    <p className="cursor-pointer"> Meetings </p>
                    <button type="button">
                      <IoIosArrowRoundForward size={30} />
                    </button>
                  </div>
                  <hr className="mb-3 p-0 border-gray-300"></hr>
                  <div
                    className="flex items-center justify-between mb-2 font-medium px-6 md:px-2"
                    onClick={toggleCompanyCredits}
                  >
                    <p className="cursor-pointer"> Credits </p>
                    <button type="button">
                      <IoIosArrowRoundForward size={30} />
                    </button>
                  </div>
                  <hr className="mb-3 p-0 border-gray-300"></hr>
                  <div
                    className="flex items-center justify-between mb-2 font-medium px-6 md:px-2"
                    onClick={toggleCompanySettings}
                  >
                    <p className="cursor-pointer"> Setting </p>
                    <button type="button">
                      <IoMdArrowDropdown size={30} />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {companySetting && (
              <div className="px-10 md:px-5 mt-2">
                <div className="shadow border-2 p-2 rounded-lg">
                  <div className="flex justify-between">
                    <p className="font-medium flex items-center">Delete Company</p>
                    <button
                      onClick={handleDeleteProfile}
                      className="px-2 py-1 bg-[#fa3131] text-white rounded-md hover:bg-[#cf1e1e] focus:outline-none focus:bg-gray-400"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCompanyDetails;
