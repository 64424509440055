import { getFirestore, collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import {  reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import {  db, storage } from "../firebase";
import { format } from "date-fns";

//messages
// Send Reply Function
export const sendReply = async (replyMessage, currentConversation, id) => {
    try {
        if (!currentConversation || !currentConversation.companyID) {
            throw new Error("Conversation or company ID not found.");
        }

        const replyData = {
            companyID: currentConversation.companyID,
            doctorID: id,
            messageId: currentConversation.messages[0].messageId,
            messages: replyMessage,
            sentBy: "doctor",
            timestamp: new Date(),
        };

        const customId = `${id}_${currentConversation.companyID}_${Date.now()}`;
        const customDocRef = doc(db, "messages", customId);
        await setDoc(customDocRef, replyData);

        const timestamp = replyData.timestamp;
        const date = format(timestamp, "dd/MM/yyyy");
        const time = format(timestamp, "hh:mm:ss a");

        const newMessage = {
            messageId: replyData.messageId,
            id: customId,
            message: replyData.messages,
            sentBy: replyData.sentBy,
            date,
            time,
        };
        return newMessage;
    } catch (error) {
        console.error("Error sending reply:", error);
        throw error;
    }
}

// Function to fetch messages
export const fetchMessages = async (id, doctorIdFromState) => {
    if (!id) {
        console.error("Invalid doctorId provided.");
        return { sortedMessages: [], currentConversation: null };
    }
    try {
        const messageRef = collection(db, "messages");
        let q = query(messageRef, where("doctorID", "==", id));
        const querySnapshot = await getDocs(q);
        const fetchCompanyData = async (companyID) => {
            const companyDocRef = doc(db, "companies", companyID);
            const companyDocSnapshot = await getDoc(companyDocRef);
            if (companyDocSnapshot.exists()) {
                return companyDocSnapshot.data();
            } else {
                console.error(`company with ID ${companyID} not found`);
                return null;
            }
        };

        const fetchAssignedData = async (messageId) => {
            let assignedName;

            try {
                const companyDocRef = doc(db, "companies", messageId);
                const companyDocSnapshot = await getDoc(companyDocRef);

                if (companyDocSnapshot.exists()) {
                    assignedName = companyDocSnapshot.data().name;
                } else {
                    const userDocRef = doc(db, "users", messageId);
                    const userDocSnapshot = await getDoc(userDocRef);

                    if (userDocSnapshot.exists()) {
                        const userData = userDocSnapshot.data();
                        assignedName = `${userData.firstName} ${userData.lastName}`;
                    } else {
                        console.error(`No document found with ID ${messageId}`);
                    }
                }
            } catch (error) {
                console.error("Error fetching assigned data:", error);
            }

            return assignedName;
        };

        const groupedMessages = {};
        const promises = querySnapshot.docs.map(async (doc) => {
            const messageData = doc.data();
            const companyData = await fetchCompanyData(messageData.companyID);
            const assignedName = await fetchAssignedData(messageData.messageId);
            const companyName = companyData
                ? companyData.companyName
                : "Unknown Company Name";
            const representativeName = companyData
                ? companyData.name
                : "Unknown Name";

            const key = `${messageData.doctorID}_${messageData.companyID}`;
            if (!groupedMessages[key]) {
                groupedMessages[key] = {
                    companyName,
                    representativeName,
                    assignedName,
                    doctorID: messageData.doctorID,
                    companyID: messageData.companyID,
                    messages: [],
                    recentMessage: {
                        text: "",
                        isCompany: false,
                        date: "",
                        time: "",
                    },
                };
            }
            const timestamp = messageData.timestamp?.toDate();
            const date = timestamp ? format(timestamp, "dd/MM/yyyy") : "N/A";
            const time = timestamp
                ? format(timestamp, "hh:mm:ss a")
                : "N/A";

            groupedMessages[key].messages.push({
                messageId: messageData.messageId,
                id: doc.id,
                message: messageData.messages,
                sentBy: messageData.sentBy,
                sentId: messageData.sentId,
                date,
                time,
            });

            if (
                !groupedMessages[key].recentMessage.timestamp ||
                timestamp > groupedMessages[key].recentMessage.timestamp
            ) {
                groupedMessages[key].recentMessage = {
                    text: messageData.messages,
                    isDoctor: messageData.sentBy === "doctor",
                    date,
                    time,
                    timestamp,
                };
            }
        });

        await Promise.all(promises);

        // Convert groupedMessages object to array
        const messagesArray = Object.keys(groupedMessages).map(
            (key) => groupedMessages[key]
        );
        const sortedMessages = sortMessagesByTimestamp(messagesArray);

        if (doctorIdFromState) {
            const conversation = sortedMessages.find((conv) => conv.companyID === doctorIdFromState);
            if (conversation) {
                return { sortedMessages, currentConversation: conversation };
            }
        }
        return { sortedMessages };
    } catch (error) {
        console.error("Error fetching schedule messages:", error);
        throw error;
    }
}

// Sort Messages By Timestamp
const sortMessagesByTimestamp = (messagesArray) => {
    return messagesArray.sort((a, b) => {
        const timestampA = a.recentMessage.timestamp;
        const timestampB = b.recentMessage.timestamp;
        if (timestampA && timestampB) {
            return timestampB - timestampA;
        }
        return 0;
    });
};

//meetings
//Function to fetch meetings
export const fetchMeetingsData = async (id, searchDate) => {
    const meetingsRef = collection(db, "scheduleMeeting");
    const q = query(meetingsRef, where("doctorID", "==", id));
    const querySnapshot = await getDocs(q);

    const fetchCompanyData = async (companyId) => {
        const companyDocRef = doc(db, "companies", companyId);
        const companyDocSnapshot = await getDoc(companyDocRef);
        return companyDocSnapshot.exists() ? companyDocSnapshot.data() : null;
    };

    const fetchAssignedData = async (assigned) => {
        let assignedName;
        let assignedRole;

        try {
            const companyDocRef = doc(db, "companies", assigned);
            const companyDocSnapshot = await getDoc(companyDocRef);

            if (companyDocSnapshot.exists()) {
                assignedName = companyDocSnapshot.data().name;
                assignedRole = companyDocSnapshot.data().role;
            } else {
                const userDocRef = doc(db, "users", assigned);
                const userDocSnapshot = await getDoc(userDocRef);

                if (userDocSnapshot.exists()) {
                    const userData = userDocSnapshot.data();
                    assignedName = `${userData.firstName} ${userData.lastName}`;
                    assignedRole = userData.role;
                } else {
                    console.error(`No document found with ID ${assigned}`);
                }
            }
        } catch (error) {
            console.error("Error fetching assigned data:", error);
        }

        return { assignedName, assignedRole };
    };

    const meetingsData = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
            const meetingData = docSnapshot.data();
            const companyData = await fetchCompanyData(meetingData.companyID);
            const { assignedName, assignedRole } = await fetchAssignedData(
                meetingData.assigned
            );

            // Update meeting status if necessary
            // const meetingDateTime = new Date(`${meetingData.date} ${meetingData.time}`);
            // const timeDiff = now - meetingDateTime; // Difference in milliseconds
            // const hoursDiff = timeDiff / (1000 * 60 * 60); // Convert milliseconds to hours

            // if (hoursDiff > 24 && meetingData.status !== "completed") {
            //   meetingData.status = "missed";
            //   await updateDoc(doc(db, "scheduleMeeting", docSnapshot.id), { status: "missed" });
            // }

            return {
                id: docSnapshot.id,
                ...meetingData,
                companyName: companyData ? companyData.companyName : "Unknown Company",
                assignedName,
                assignedRole,
            };
        })
    );

    const filteredMeetings = meetingsData.filter((meeting) => {
        const matchesDate = !searchDate || format(new Date(meeting.date), "yyyy-MM-dd") === searchDate;
        return matchesDate;
    });

    const filteredUpcomingMeetings = filteredMeetings.filter((meeting) => {
        return meeting.status !== "completed" && meeting.status !== "missed" || 
               (meeting.status === "completed" && !meeting.doctorFeedback);
    });

    const filteredMissedMeetings = filteredMeetings.filter((meeting) => {
        return meeting.status === "missed";
    });

    const filteredCompletedMeetings = filteredMeetings.filter((meeting) => {
        return meeting.status === "completed" && meeting.doctorFeedback;
    });

    const sortMeetings = (meetings) =>
        meetings.sort(
            (a, b) =>
                new Date(`${a.date} ${a.time}`) - new Date(`${b.date} ${b.time}`)
        );

    return {
        upcomingMeetings: sortMeetings(filteredUpcomingMeetings),
        missedMeetings: sortMeetings(filteredMissedMeetings),
        completedMeetings: sortMeetings(filteredCompletedMeetings),
    }
}

// Update meeting
export const updateMeeting = async (selectedMeetingId, selectedDate, selectedTime, meetingType) => {
    if (!selectedMeetingId) return;

    try {
        const meetingDocRef = doc(db, "scheduleMeeting", selectedMeetingId);

        const meetingDocSnapshot = await getDoc(meetingDocRef);
        if (!meetingDocSnapshot.exists()) {
            throw new Error(`Document with ID ${selectedMeetingId} does not exist`);
        }

        const adjustedDate = new Date(selectedDate);
        const ISTOffset = 330;
        adjustedDate.setMinutes(adjustedDate.getMinutes() + ISTOffset);
        const formattedDate = adjustedDate.toISOString().split("T")[0];

        await updateDoc(meetingDocRef, {
            date: formattedDate,
            time: selectedTime,
            status: "Rescheduled",
            meetingType: meetingType,
        });
    } catch (error) {
        console.error("Error updating schedule meeting:", error);
        throw error;
    }
}

// Accept meeting
export const acceptMeeting = async (meetingId) => {
    try {
        const meetingDocRef = doc(db, "scheduleMeeting", meetingId);
        await updateDoc(meetingDocRef, { status: "Accepted" });
    } catch (error) {
        console.error("Error accepting meeting:", error);
        throw error;
    }
}

// Fetch meetings and calculate chart data function
export const fetchMeetingsChart = async (id) => {
    const scheduleMeetingsRef = collection(db, "scheduleMeeting");
    const q = query(scheduleMeetingsRef, where("doctorID", "==", id));
    const querySnapshot = await getDocs(q);

    const meetingsByMonth = {};
    const completedMeetingsByMonth = {};
    const missedMeetingsByMonth = {};

    querySnapshot.forEach((doc) => {
        const meetingData = doc.data();
        const meetingDate = new Date(meetingData.date);
        const month = meetingDate.toLocaleString("default", { month: "short" });
        const year = meetingDate.getFullYear();
        const key = `${month} ${year}`;

        if (!meetingsByMonth[key]) {
            meetingsByMonth[key] = 0;
            completedMeetingsByMonth[key] = 0;
        }
        meetingsByMonth[key]++;

        if (meetingData.status === "completed") {
            completedMeetingsByMonth[key]++;
        }

        // Check for missed meetings
        const now = new Date();
        if (meetingDate < now && meetingData.status !== "completed") {
            if (!missedMeetingsByMonth[key]) {
                missedMeetingsByMonth[key] = 0;
            }
            missedMeetingsByMonth[key]++;
        }

    });

    return { meetingsByMonth, missedMeetingsByMonth, completedMeetingsByMonth };
};

//settings
// Fetch doctor
export const fetchDoctor = async (id, storage) => {
    try {
        const docRef = doc(db, "doctors", id);
        const doctorSnapshot = await getDoc(docRef);
        if (doctorSnapshot.exists()) {
            const doctorData = { id: doctorSnapshot.id, ...doctorSnapshot.data() };
            let imageUrl = null;
            if (doctorData.image) {
                imageUrl = await getDownloadURL(ref(storage, doctorData.image));
            }
            return { doctorData, imageUrl };
        } else {
            console.log("No such doctor");
            return { doctorData: null, imageUrl: null };
        }
    } catch (error) {
        console.log("Error fetching doctor:", error);
        return { doctorData: null, imageUrl: null };
    }
};

// Fetch bank details
export const fetchBankDetails = async (id) => {
    try {
        const bankRef = collection(db, "bankDetails");
        const q = query(bankRef, where("id", "==", id));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const bankDoc = querySnapshot.docs[0];
            return { bankData: { id: bankDoc.id, ...bankDoc.data() }, isEditMode: true };
        } else {
            return { bankData: null, isEditMode: false };
        }
    } catch (error) {
        console.log("Error fetching bank details:", error);
        return { bankData: null, isEditMode: false };
    }
};

// Handle image upload
export const handleImageUpload = async (file) => {
    try {
        if (file) {
            const imageRef = ref(storage, `images/${file.name}`);
            await uploadBytes(imageRef, file);
            const downloadURL = await getDownloadURL(imageRef);
            return downloadURL;
        }
        return null;
    } catch (error) {
        console.error("Error uploading image:", error);
        return null;
    }
};

// Handle image removal
export const handleImageRemove = async (image) => {
    try {
        if (image) {
            await deleteObject(ref(storage, image));
            return true;
        }
        return false;
    } catch (error) {
        console.error("Error removing image:", error);
        return false;
    }
};

// Update doctor details
export const updateDoctorDetails = async (id, newData) => {
    try {
        const doctorRef = doc(db, "doctors", id);
        await updateDoc(doctorRef, newData);
        console.log("Document successfully updated!");
        return true;
    } catch (error) {
        console.error("Error updating document:", error);
        return false;
    }
    // try {
    //   const doctorRef = doc(db, "doctors", id);
    //   if (doctorData.imageFile) {
    //     doctorData.image = await handleImageUpload(doctorData.imageFile);
    //   } else if (!doctorData.imageUrl) {
    //     doctorData.image = "";
    //   }
    //   await updateDoc(doctorRef, doctorData);
    //   return true;
    // } catch (error) {
    //   console.error("Error updating doctor details:", error);
    //   return false;
    // }
};

// Update bank details
export const updateBankDetails = async (bankDocId, bankData, isEditMode) => {
    try {
        const bankRef = doc(db, "bankDetails", bankDocId || bankData.id);
        if (isEditMode) {
            await updateDoc(bankRef, bankData);
        } else {
            await setDoc(bankRef, bankData);
        }
        return true;
    } catch (error) {
        console.error("Error saving bank details:", error);
        return false;
    }
};

// Handle password update
export const handlePasswordUpdate = async (user, oldPassword, newPassword) => {
    try {
        const credential = EmailAuthProvider.credential(user.email, oldPassword);
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword);
        return true;
    } catch (error) {
        console.error("Error updating password:", error);
        return false;
    }
};

//company Profile
export const fetchCompany = async (companyId) => {
    try {
      const db = getFirestore();
      const docRef = doc(db, "companies", companyId);
      const companySnapshot = await getDoc(docRef);
      if (companySnapshot.exists()) {
        return { id: companySnapshot.id, ...companySnapshot.data() };
      } else {
        console.log("No such company");
        return null;
      }
    } catch (error) {
      console.error("Error fetching company: ", error);
      throw error;
    }
  };
  
  export const fetchProducts = async (companyId) => {
    try {
      const db = getFirestore();
      const productsCollection = collection(db, "products");
      const q = query(productsCollection, where("companyId", "==", companyId));
      const querySnapshot = await getDocs(q);
  
      const productsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return productsList;
    } catch (error) {
      console.error("Error fetching products: ", error);
      throw error;
    }
  };

  export const updateMeetingStatus = async(selectedMeetingId, doctorFeedback) => {
    const meetingDocRef = doc(db, "scheduleMeeting", selectedMeetingId);
    const updateData = {
        doctorFeedback: doctorFeedback,
    } 
    await updateDoc(meetingDocRef, updateData);
  }

