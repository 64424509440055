import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CompanyNavbar from "./CompanyNavbar";
import CompanySide from "./CompanySide";
import { IoIosArrowRoundForward } from "react-icons/io";
import "../style/Company.css";
import { storage } from "../firebase";
import {
  fetchCompany,
  fetchMeetings,
  fetchProducts,
  handleImageUpload,
  handleImageRemove,
  updateCompanyData,
  changePassword,
} from "./CompanyFirebase";

const CompanySetting = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("about");
  const [company, setCompany] = useState(null);
  const [products, setProducts] = useState([]);
  const [companyName, setCompanyName] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(false);
  const [companyAbout, setCompanyAbout] = useState(false);
  const [companyPassword, setCompanyPassword] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [totalCredits, setTotalCredits] = useState(0);
  const [consumedCredits, setConsumedCredits] = useState(0);
  const [availableCredits, setAvailableCredits] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();

  const toggleCompanyName = () => {
    setCompanyName(!companyName);
  };

  const toggleCompanyDetails = () => {
    setCompanyDetails(!companyDetails);
  };

  const toggleCompanyAbout = () => {
    setCompanyAbout(!companyAbout);
  };

  const toggleCompanyPassword = () => {
    setCompanyPassword(!companyPassword);
  };

  const toggleCompanyCredits = () => {
    navigate(`/company/credits/${id}`);
  };

  const productPage =() => {
    navigate(`/company/products/${id}`);
  }

  useEffect(() => {
    const loadData = async () => {
      const { companyData, imageUrl } = await fetchCompany(id, storage);
      setCompany(companyData);
      setImageUrl(imageUrl);

      const productsList = await fetchProducts(id);
      setProducts(productsList);

      const currenttotalcredits = companyData.credits || 0;

      const { completedMeetings } = await fetchMeetings(id);
      const currentconsumedCredits = completedMeetings.length;

      const currentavailablecredits = Number(
        currenttotalcredits - currentconsumedCredits
      );

      setTotalCredits(currenttotalcredits);
      setAvailableCredits(currentavailablecredits);
      setConsumedCredits(currentconsumedCredits);
    };

    loadData();
  }, [id]);

  const handleImageUploadChange = async (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setImageFile(file);
      const previewUrl = await handleImageUpload(file);
      setImageUrl(previewUrl);
    } else {
      console.error("No file selected or invalid file type");
    }
  };

  const handleImageRemoveClick = async () => {
    const removed = await handleImageRemove(company.image);
    if (removed) {
      setImageUrl(null);
      alert("Removing your image...");
    }
  };

  const handleCompanyNameSave = async (e) => {
    e.preventDefault();
    const newData = { ...company };

    if (imageFile) {
      const downloadURL = await handleImageUpload(imageFile);
      newData.image = downloadURL;
    } else if (!imageUrl) {
      newData.image = "";
    }

    const success = await updateCompanyData(id, newData);
    if (success) {
      alert("Data successfully updated!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleCompanyDetailsSave = async (e) => {
    e.preventDefault();
    const success = await updateCompanyData(id, company);
    if (success) {
      alert("Data successfully updated!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleCompanyAboutSave = async (e) => {
    e.preventDefault();
    const success = await updateCompanyData(id, company);
    if (success) {
      alert("Data successfully updated!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleCompanyPasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("New passwords do not match");
      return;
    }

    const success = await changePassword(oldPassword, newPassword);
    if (success) {
      setSuccess("Password reset successfully!");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } else {
      setError("Error changing password.");
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const profile = () => {
    navigate(`/company/companyprofile/${id}`);
  };

  return (
    <div className="flex flex-col h-screen">
      <CompanyNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <CompanySide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-all duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          {company && (
            <div className="container px-4 mx-auto my-10">
              <div className="overflow-hidden px-10 md:px-5" id="companyedits">
                <div className="border shadow rounded-lg mb-3">
                  <div id="companydetails" className="md:h-auto">
                    <div className="flex items-center justify-between my-3">
                      <h1 className="text-xl font-bold uppercase px-6 md:px-2 ">
                        Get Started
                      </h1>
                      <button
                        onClick={profile}
                        className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                      >
                        Preview
                      </button>
                    </div>
                  </div>
                </div>

                <div className="border shadow rounded-lg">
                  <div id="companydetails" className="md:h-auto">
                    <div className="flex items-center justify-between my-5">
                      <h1 className="text-xl font-bold uppercase px-6 md:px-2 ">
                        Profile Information
                      </h1>
                    </div>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2"
                      onClick={toggleCompanyName}
                    >
                      <p className="cursor-pointer">Name, Company-Name </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 p-0 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={toggleCompanyDetails}
                    >
                      <p className="cursor-pointer">
                        Email, Location, Category{" "}
                      </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={toggleCompanyAbout}
                    >
                      <p className="cursor-pointer">About Company </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={toggleCompanyPassword}
                    >
                      <p className="cursor-pointer">Change Password </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={productPage}
                    >
                      <p className="cursor-pointer">Products </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={toggleCompanyCredits}
                    >
                      <p className="cursor-pointer">Credits </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* edit content */}

          {companyName && (
            <div className="overlay">
              <div className="overlay-content" id="editablecontent">
                <div className="rounded-md">
                  <p className="text-xl text-center font-bold mb-2">
                    Edit Company Name
                  </p>
                  <div>
                    <div className="mb-3">
                      <label
                        htmlFor="companyName"
                        className="block px-2 text-md font-semibold text-gray-900 mb-1 "
                      >
                        Company Name :
                      </label>
                      <input
                        type="text"
                        name="companyName"
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                        value={company ? company.companyName : ""}
                        onChange={(e) =>
                          setCompany({
                            ...company,
                            companyName: e.target.value,
                          })
                        }
                        placeholder="Enter Company name"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="name"
                        className="block px-2 text-md font-semibold text-gray-900 mb-1 "
                      >
                        Name :
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2"
                        value={company ? company.name : ""}
                        onChange={(e) =>
                          setCompany({ ...company, name: e.target.value })
                        }
                        placeholder="Enter name"
                        required
                      />
                    </div>
                    <div className="mb-5">
                      <label
                        htmlFor="image"
                        class="block px-2 text-md font-semibold text-gray-900 mb-1"
                      >
                        Image :
                      </label>
                      <input
                        type="file"
                        name="image"
                        id="image"
                        className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                        onChange={handleImageUploadChange}
                        accept="image/*"
                      />
                    </div>
                    {imageUrl && (
                      <div className="mb-6 text-center">
                        <img
                          src={imageUrl}
                          alt="Preview"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100px",
                            margin: "auto",
                          }}
                        />
                        <button
                          type="button"
                          onClick={handleImageRemoveClick}
                          className="text-white bg-[#0D47A1] hover:bg-[#586892] focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm py-2 px-4 mt-2"
                        >
                          Remove Image
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end mt-3">
                    <button
                      onClick={handleCompanyNameSave}
                      className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setCompanyName(false)}
                      className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {companyDetails && (
            <div className="overlay">
              <div className="overlay-content" id="editablecontent">
                <div className="rounded-md">
                  <p className="text-xl text-center font-bold mb-2">
                    Edit Company Details
                  </p>
                </div>
                <div>
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className="block px-2 text-md font-semibold text-gray-900 mb-1 "
                    >
                      Email :
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                      value={company ? company.email : ""}
                      onChange={(e) =>
                        setCompany({ ...company, email: e.target.value })
                      }
                      placeholder="Enter Email"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="phone"
                      className="block px-2 text-md font-semibold text-gray-900 mb-1 "
                    >
                      Phone :
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                      value={company ? company.phone : ""}
                      onChange={(e) =>
                        setCompany({ ...company, phone: e.target.value })
                      }
                      placeholder="Enter Phone"
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="location"
                      className="block px-2 text-md font-semibold text-gray-900 mb-1 "
                    >
                      Location :
                    </label>
                    <select
                      name="location"
                      value={company ? company.location : ""}
                      onChange={(e) =>
                        setCompany({ ...company, location: e.target.value })
                      }
                      required
                      className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                    >
                      <option value="">Select Location</option>
                      <option value="Bangalore">Bangalore</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Kolkata">Kolkata</option>
                      <option value="Hyderabad">Hyderabad</option>
                      <option value="Chennai">Chennai</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="headquarter"
                      className="block px-2 text-md font-semibold text-gray-900 mb-1 "
                    >
                      Headquarter :
                    </label>
                    <input
                      type="text"
                      name="headquarter"
                      className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                      value={company ? company.headquarter : ""}
                      onChange={(e) =>
                        setCompany({ ...company, headquarter: e.target.value })
                      }
                      placeholder="Enter Company Headquarter"
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="category"
                      className="block px-2 text-md font-semibold text-gray-900 mb-1 "
                    >
                      Category :
                    </label>
                    <input
                      type="text"
                      name="category"
                      className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                      value={company ? company.category : ""}
                      onChange={(e) =>
                        setCompany({ ...company, category: e.target.value })
                      }
                      placeholder="Enter Category"
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-3">
                  <button
                    onClick={handleCompanyDetailsSave}
                    className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setCompanyDetails(false)}
                    className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {companyAbout && (
            <div className="overlay">
              <div className="overlay-content" id="editablecontent">
                <div className="rounded-md">
                  <p className="text-xl text-center font-bold mb-2">
                    Edit Company About
                  </p>
                </div>
                <div>
                  <div class="mb-3">
                    <label
                      htmlFor="about"
                      class="block px-2 text-lg font-bold text-gray-900 "
                    >
                      About :
                    </label>
                    <textarea
                      name="about"
                      id="about"
                      className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                      value={company ? company.about : ""}
                      onChange={(e) =>
                        setCompany({ ...company, about: e.target.value })
                      }
                      placeholder=" "
                    ></textarea>
                  </div>
                </div>
                <div className="flex justify-end mt-3">
                  <button
                    onClick={handleCompanyAboutSave}
                    className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setCompanyAbout(false)}
                    className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {companyPassword && (
            <div className="overlay">
              <div className="overlay-content" id="editablecontent">
                <div className="rounded-md">
                  <p className="text-xl text-center font-bold mb-2">
                    Change Password
                  </p>
                </div>
                <div>
                  <div className="mb-3">
                    <label
                      htmlFor="oldPassword"
                      className="block px-2 text-md font-semibold text-gray-900 mb-1 "
                    >
                      Old Password
                    </label>
                    <input
                      type="password"
                      id="oldPassword"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="newPassword"
                      className="block px-2 text-md font-semibold text-gray-900 mb-1 "
                    >
                      New Password
                    </label>
                    <input
                      type="password"
                      id="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="confirmPassword"
                      className="block px-2 text-md font-semibold text-gray-900 mb-1 "
                    >
                      Confirm New Password
                    </label>
                    <input
                      type="password"
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="text-md bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 "
                    />
                  </div>
                  {error && (
                    <p className="text-red-500 text-xs italic mb-3">{error}</p>
                  )}
                  {success && (
                    <p className="text-green-500 text-xs italic mb-3">
                      {success}
                    </p>
                  )}
                </div>
                <div className="flex justify-end mt-3">
                  <button
                    onClick={handleCompanyPasswordChange}
                    className="px-4 py-2 mr-2 bg-[#0D47A1] text-white rounded-md hover:bg-[#7090E6] focus:outline-none focus:bg-gray-400"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setCompanyPassword(false)}
                    className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default CompanySetting;
