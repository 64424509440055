import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import { IoMdArrowBack } from "react-icons/io";
import { useAuth } from "../AuthContext";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { RiDashboard3Fill } from "react-icons/ri";
import {
  fetchUserByCompanyId,
  fetchCompanyDataByID,
  deleteUser,
} from "./AdminFirebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewCompanyUsers = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [company, setCompany] = useState([]);
  const navigate = useNavigate();
  const { isAdminLoggedIn } = useAuth();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      const companyData = await fetchCompanyDataByID(id);
      setCompany(companyData);

      const usersData = await fetchUserByCompanyId(id);
      setUsers(usersData);
    };
    fetchData();
  }, [id]);

  const handleDeleteUser = async (userId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this profile?"
    );

    if (confirmed) {
      try {
        await deleteUser(userId);
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      } catch (error) {
        console.error("Error deleting User profile:", error);
      }
    }
  };

  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  const navigateToUserDashboard = async (userId, userRole) => {
    // Show a success toast message
    toast.success(`Going to ${userRole} Dashboard!`, {
      autoClose: 2000,
    });

    // Delay the navigation to allow the toast to appear
    setTimeout(() => {
      if (userRole === "Sales Head") {
        navigate(`/salesDashboard/${userId}`, { state: { from: "admin" } });
      } else if (userRole === "Medical Representative") {
        navigate(`/mrDashboard/${userId}`, { state: { from: "admin" } });
      }
    }, 2000); 
  };

  const navigateToCompanyDashboard = async (companyId) => {
    toast.success("Going to Companies Dashboard!", {
      autoClose: 2000,
    });
    setTimeout(() => {
      navigate(`/companyDashboard/${companyId}`, { state: { from: "admin" } });
    }, 2000); 
  };

  const handleEditProfile = async(userId, companyId) => {
    navigate(`/admin/edit-companyUser/${userId}`, { state: { companyId } });
  }

  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <ToastContainer />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          <div className="container mx-auto px-5 md:px-3 h-full">
            <div className="overflow-hidden px-10 md:px-5" id="companyedits">
              <div className="flex items-center justify-between mb-4">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
                >
                  <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
                </button>
                <h2 className="flex-grow text-xl mt-2 font-bold text-center uppercase">
                  {company.companyName} USERS
                </h2>
              </div>

              <div className="overflow-auto shadow-md sm:rounded-lg mt-3 border table-container">
                <table className="divide-y divide-gray-300 w-full text-left rtl:text-right">
                  <thead className="text-sm text-gray-700 uppercase">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 tracking-wider bg-gray-50"
                      >
                        S.N.
                      </th>
                      <th scope="col" className="px-6 py-3 tracking-wider">
                        User Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 tracking-wider bg-gray-50"
                      >
                        Role
                      </th>
                      <th scope="col" className="px-6 py-3 tracking-wider">
                        Location
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 tracking-wider bg-gray-50"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {company && (
                      <tr key={company.id} className="bg-white border-b">
                        <td
                          scope="row"
                          className="px-2 py-3 bg-gray-50 font-medium"
                        >
                          1.
                        </td>
                        <td className="px-2 py-3 font-medium">
                          {company.companyName}
                        </td>
                        <td className="px-2 py-3 bg-gray-50">
                          {company.role === "Company" ? "Admin" : company.role}
                        </td>
                        <td className="px-2 py-3 font-medium">
                          {company.location}
                        </td>
                        <td className="px-2 py-3 bg-gray-50">
                          <button
                            type="button"
                            className="text-white bg-[#8697C4] disabled:bg-gray-400 disabled:text-white disabled:cursor-not-allowed focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2 opacity-100 disabled:opacity-50"
                            disabled
                          >
                            <FaRegEdit />
                          </button>

                          <button
                            type="button"
                            className="text-white bg-[#8697C4] disabled:bg-gray-400 disabled:text-white disabled:cursor-not-allowed focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2 opacity-100 disabled:opacity-50"
                            disabled
                          >
                            <MdDelete />
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              navigateToCompanyDashboard(company.id)
                            }
                            className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2"
                          >
                            <RiDashboard3Fill />
                          </button>
                        </td>
                      </tr>
                    )}
                    {users.length === 0 ? (
                      <tr className="bg-white border-b">
                        <td colSpan="5" className="text-center py-4">
                          <p className="text-lg">No Users Found.</p>
                        </td>
                      </tr>
                    ) : (
                      users.map((user, index) => (
                        <tr key={user.id} className="bg-white border-b">
                          <td
                            scope="row"
                            className="px-2 py-3 bg-gray-50 font-medium"
                          >
                            {index + 2}.
                          </td>
                          <td className="px-2 py-3 font-medium">
                            {user.firstName} {user.lastName}
                          </td>
                          <td className="px-2 py-3 bg-gray-50">{user.role}</td>
                          <td className="px-2 py-3 font-medium">
                            {user.location}
                          </td>
                          <td className="px-2 py-3 bg-gray-50">
                            <button
                              type="button"
                              onClick={() => handleEditProfile(user.id, id)}
                              className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2"
                            >
                              <FaRegEdit />
                            </button>
                            <button
                              type="button"
                              onClick={() => handleDeleteUser(user.id)}
                              className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2"
                            >
                              <MdDelete />
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                navigateToUserDashboard(user.id, user.role)
                              }
                              className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2"
                            >
                              <RiDashboard3Fill />
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ViewCompanyUsers;
