import React, { useEffect } from "react";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { FaChartPie, FaCompactDisc, FaCog, FaUser } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { MdManageAccounts, MdMessage } from "react-icons/md";
import { RiCalendarScheduleFill } from "react-icons/ri";
import { MdMissedVideoCall } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { FaHospital } from "react-icons/fa";

const AdminSide = ({ open, toggleSidebar }) => {
  // const [open, setOpen] = useState(true);
  const { pathname } = useLocation();

  const Menus = [
    {
      title: "Dashboard",
      path: "/admin/dash",
      icon: <FaChartPie className="text-[#8697C4]" />,
    },
    {
      title: "Hospital",
      path: "/admin/hospital",
      icon: <FaHospital className="text-[#8697C4]" />,
    }, // Corrected path for Doctors
    {
      title: "Doctors",
      path: "/admin/doctors",
      icon: <FaUserDoctor className="text-[#8697C4]" />,
    }, // Corrected path for Doctors
    {
      title: "Companies",
      path: "/admin/companies",
      icon: <FaCompactDisc className="text-[#8697C4]" />,
    }, // Corrected path for Companies
    {
      title: "Growth Manager",
      path: "/admin/manager",
      icon: <MdManageAccounts className="text-[#8697C4]" />,
    }, // Corrected path for Companies
    {
      title: "Messages",
      path: "/admin/messages",
      icon: <MdMessage className="text-[#8697C4]" />,
    },
    {
      title: "Schedule",
      path: "/admin/Schedule",
      icon: <RiCalendarScheduleFill className="text-[#8697C4]" />,
    },   
    // {
    //   title: "Completed Meetings",
    //   icon: <GrCompliance className="text-[#8697C4]" />,
    // },
    {
      title: "Missed Meetings",
      path: "/admin/missedMeeting",
      icon: <MdMissedVideoCall className="text-[#8697C4]" />,
    },
    {
      title: "Completed Meetings",
      path: "/admin/completedMeeting",
      icon: <MdMissedVideoCall className="text-[#8697C4]" />,
    },
    {
      title: "Setting",
      path: "/admin/setting",
      icon: <FaCog className="text-[#8697C4]" />,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768 && open) {
        toggleSidebar();
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [open, toggleSidebar]);

  const handleLinkClick = () => {
    if (window.innerWidth < 768) {
      toggleSidebar();
    }
  };

  return (
    <div className="flex flex-col h-screen fixed top-[4.2rem] z-50">
      <div className="flex flex-1">
        <div
          className={`${
            open ? "w-60" : "w-20"
          } top-0 left-0 bg-white shadow-2xl p-5 pt-8 relative ${
            window.innerWidth < 768 ? "" : "overflow-y-auto"
          }`}
          style={{
            maxHeight: "calc(100vh - 4.2rem)",
            overflowX: open ? "auto" : "hidden",
            scrollbarWidth: "thin",
          }}
        >
          <div
            className={`absolute cursor-pointer right-0 top-0 mt-2 mr-2 w-8 h-8 border-[#7191E6] border-2 rounded-full flex items-center justify-center ${
              window.innerWidth >= 768 && open
                ? "transition-all duration-300"
                : ""
            }`}
            onClick={toggleSidebar}
          >
            <IoIosArrowDropleftCircle
              className={`h-6 w-6 text-[#7191E6] ${
                window.innerWidth >= 768 && open ? "" : "transform rotate-180"
              }`}
            />
          </div>

          {/* Title */}
          {/* <div className="flex items-center mb-6">
            <h1
              className={`text-[##8697C4] font-medium text-2xl leading-none transition-transform ${
                !open && "scale-0"
              }`}
            >
              Admin Dashboard
            </h1>
          </div> */}

          {/* Menu Items */}
          <ul>
            <li className="flex items-center mt-2 cursor-pointer">
              <div
                className={`text-md font-bold text-center text-black flex items-center ${
                  open ? "" : "mt-2"
                }`}
              >
                {/* <FaUserCheck className="mr-4 text-xl" /> */}
                {open ? "Admin Dashboard" : "AD"}
              </div>
            </li>
            {Menus.map((menu, index) => (
              <li
                key={index}
                className={`flex items-center py-3 mt-2 px-2 cursor-pointer hover:bg-gray-100 hover:shadow ${
                  open ? "" : "hover:pr-7"
                } text-[#808080] font-bold ${
                  pathname === menu.path && !open ? "pr-7" : ""
                } ${pathname === menu.path ? "bg-gray-50 shadow-xl border" : ""}`}
              >
                <Link to={menu.path} className="flex items-center">
                  <span className="mr-3 text-lg">{menu.icon}</span>
                  <span
                    className={`transition-transform ${!open && "scale-0"}`}
                  >
                    {menu.title}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSide;
