import React, { useState, useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import AdminSide from "./AdminSide";
import Swal from "sweetalert2";
import { useAuth } from "../AuthContext";
import Adminbox from "./Adminbox";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaCommentSlash } from "react-icons/fa";
import { IoSearchSharp } from "react-icons/io5";
import { sendReply, fetchMessages } from "./AdminFirebase";

const AdminMessage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [currentConversation, setCurrentConversation] = useState(null);
  const [showAdminbox, setshowAdminbox] = useState(false);
  const { id } = useParams();
  const { isAdminLoggedIn } = useAuth();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleReplyMessageChange = (e) => {
    setReplyMessage(e.target.value);
  };

  const handleSendReply = async () => {
    if (!replyMessage.trim()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Message cannot be empty!",
      });
      return;
    }

    try {
      const newMessage = await sendReply(currentConversation, replyMessage);

      setReplyMessage("");

      setCurrentConversation((prev) => ({
        ...prev,
        messages: [...prev.messages, newMessage],
        recentMessage: {
          text: newMessage.message,
          isDoctor: true,
          date: newMessage.date,
          time: newMessage.time,
          timestamp: newMessage.timestamp,
        },
      }));

      // Update the main messages array to reflect the new message in the conversation
      setMessages((prevMessages) =>
        prevMessages.map((conv) =>
          conv.doctorID === currentConversation.doctorID &&
          conv.companyID === currentConversation.companyID
            ? {
                ...conv,
                messages: [...conv.messages, newMessage],
                recentMessage: {
                  text: newMessage.message,
                  isDoctor: true,
                  date: newMessage.date,
                  time: newMessage.time,
                  timestamp: newMessage.timestamp,
                },
              }
            : conv
        )
      );
    } catch (error) {
      console.error("Error sending reply:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to send reply. Please try again.",
      });
    }
  };
  
  useEffect(() => {
    const getMessages = async () => {
      try {
        const fetchedMessages = await fetchMessages();
        setMessages(fetchedMessages);

        if (fetchedMessages.length > 0) {
          const recentConversation = fetchedMessages[0];
          setCurrentConversation(recentConversation);
          setshowAdminbox(true);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    getMessages();
  }, [id]);

  const handleReply = (conversation) => {
    setCurrentConversation(conversation);
    setshowAdminbox(true);
  };

  const handleCloseChat = () => {
    setshowAdminbox(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterMessages = (messages) => {
    if (!messages || !Array.isArray(messages)) {
      return [];
    }
    return messages.filter((conversation) =>
      conversation.doctorName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      conversation.companyName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-all duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          <div
            className="flex mt-5 px-3"
            style={{ maxHeight: "calc(100vh - 6rem)" }}
          >
            <div
              className={`conversation-list w-full md:w-1/3 ${
                showAdminbox && "hidden md:block"
              }`}
              style={{ borderRadius: "5% 0 0 0", boxShadow: "0 0 4px #aeafb2" }}
            >
              <div style={{ background: "#8697C4", borderRadius: "0 0 0% 0" }}>
                <h2 className="text-[1.5rem] font-bold font-[K2D] text-center text-white py-4">
                  Chat
                </h2>
              </div>
              <div
                className="conversation-container px-2 overflow-y-auto"
                style={{
                  maxHeight: "calc(100vh - 10rem)",
                  overflowY: "auto",
                  background: "#F9F9FB",
                  borderRadius: "5% 0 0 0",
                }}
              >
                <div className="flex flex-col relative justify-center self-stretch border mt-2">
                  <div className="flex rounded-lg">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      placeholder="Search doctor and company..."
                      className="w-full px-3 py-2 border-b focus:outline-none focus:border-[#aeafb2]"
                    />
                    <button
                      type="button"
                      className="flex-shrink-0 inline-flex px-2 items-center bg-[#8697C4] text-white"
                    >
                      <IoSearchSharp />
                    </button>
                  </div>
                </div>
                {filterMessages(messages).map((conversation, index) => (
                  <div
                    key={index}
                    className="px-4 py-2 border-b my-2 shadow-lg cursor-pointer hover:bg-gray-100"
                    onClick={() => handleReply(conversation)}
                    style={{ background: "white" }}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <div
                          id="condocname"
                          className="text-sm font-bold text-gray-600"
                        >
                          {conversation.doctorName}
                        </div>
                        <div
                          id="conrecent"
                          className="text-sm font-bold text-gray-600 mt-1"
                        >
                          {conversation.companyName}
                        </div>
                      </div>
                      <div className="text-xs text-gray-400 text-right">
                        <div>{conversation.recentMessage.date}</div>
                        <div>{conversation.recentMessage.time}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {showAdminbox && currentConversation ? (
              <div className="chatbox-container w-full md:w-2/3 flex flex-col border">
                <div className="shadow-lg p-4 flex items-center justify-between">
                  <h3 className="font-bold text-xl">
                    {currentConversation?.doctorName} and {currentConversation?.companyName}
                  </h3>
                  <button
                    className="hover:text-gray-700"
                    onClick={handleCloseChat}
                  >
                    <IoIosCloseCircleOutline size={24} />
                  </button>
                </div>
                <div className="flex-1 overflow-y-auto bg-[#fafafa] p-4 chat-content">
                  <Adminbox
                    conversation={currentConversation}
                    replyMessage={replyMessage}
                    handleReplyMessageChange={handleReplyMessageChange}
                    // currentUserId={id}
                    setCurrentConversation={setCurrentConversation}
                  />
                </div>
                <div className="px-2 py-2 border-t bg-white">
                  <textarea
                    className="w-full p-2 border rounded"
                    rows="2"
                    placeholder="Type your message..."
                    value={replyMessage}
                    onChange={handleReplyMessageChange}
                  />
                  <button
                    className="bg-[#7191E6] text-white float-end px-4 py-2 rounded"
                    onClick={handleSendReply}
                  >
                    Send
                  </button>
                </div>
              </div>
            ) : (
              <div
                id="hide-on-small-screen"
                className="flex-1 flex flex-col items-center justify-center text-gray-500 bg-white rounded-lg shadow-lg p-10 m-4"
              >
                <FaCommentSlash className="text-gray-400 text-6xl mb-4" />
                <p className="text-lg font-semibold">No conversation found</p>
                <p className="text-sm text-gray-400 mt-2">
                  Start a conversation by selecting a contact or sending a
                  message.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMessage;
