import React, { useState, useEffect } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { fetchManager, deleteManager} from './AdminFirebase'; 

const Manager = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { isAdminLoggedIn } = useAuth();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchManagersData = async () => {
      try {
        const managersData = await fetchManager();
        setManagers(managersData);
      } catch (error) {
        setError("Error fetching managers: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchManagersData();
  }, []);

  const handleEditProfile = (managerId) => {
    navigate(`/edit-manager/${managerId}`);
  };

  const handleDeleteProfile = async (managerId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this profile?"
    );

    // If the user confirms the action
    if (confirmed) {
      try {
        await deleteManager(managerId);
        setManagers((prevManagers) =>
          prevManagers.filter((manager) => manager.id !== managerId)
        );
      } catch (error) {
        console.error("Error deleting manager profile:", error);
      }
    }
  };

  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  const navigateToGrowthDashboard = async(managerId) => {
    navigate(`/manager/dash/${managerId}`)
  }

  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          <div className="container mx-auto px-5 md:px-3 h-full">
            <div className="border mt-4 p-2">
              <div className="flex justify-between items-center mb-5">
                <h2 className="text-center text-3xl font-bold">Manager List</h2>
                <Link
                  to="/add-manager"
                  className="bg-[#8697C4] hover:bg-[#8595C3] text-white font-bold py-2 px-4 rounded"
                >
                  Add Manager
                </Link>
              </div>

              <div className="overflow-auto mt-3">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="text-xs text-gray-700 font-bold border-t border-gray-200 text-left uppercase">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-50 text-sm tracking-wider"
                      >
                        S.N.
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm uppercase tracking-wider"
                      >
                        Manager Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-50 text-sm uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm uppercase tracking-wider"
                      >
                        Location
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-50text-sm uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {managers.map((manager, index) => (
                      <tr key={manager.id} className="border-b border-gray-200">
                        <td scope="row" className="px-6 py-4 bg-gray-50">
                          {index + 1}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900"
                        >
                          {manager.name}
                        </td>
                        <td className="px-6 py-4 bg-gray-50">
                          {manager.email}
                        </td>
                        <td className="px-6 py-4">{manager.location}</td>
                        <td className="px-6 py-4 bg-gray-50">
                          <button
                            type="button"
                            onClick={() => handleEditProfile(manager.id)}
                            className="text-white bg-[#8697C4] rounded-lg px-3 py-2 text-center me-2 mb-2"
                          >
                            <FaRegEdit />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleDeleteProfile(manager.id)}
                            className="text-white bg-[#8697C4] rounded-lg px-3 py-2 text-center me-2 mb-2"
                          >
                            <MdDelete />
                          </button>
                          {/* <button
                            type="button"
                            onClick={() => navigateToGrowthDashboard(manager.id)}
                            className="text-white bg-[#8697C4] rounded-lg px-3 py-2 text-center me-2 mb-2"
                          >
                            <RiDashboard3Fill />
                          </button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manager;
